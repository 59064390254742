import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout, Progress, Select } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { decrypt, encriptionKey } from '../components/utils';
import { useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

const ApplicationStudentList = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [studentList, setStudentList] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [sessionFilter, setSessionFilter] = useState('');

    const fetchApplicationStudentList = async () => {
        await axios.post(api_url + 'counsellor/application-student-list', {
            counsellor_id: user_data.id,
        }).then((res) => {
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user');
                    window.location.href = '/';
                }
            }

            const st_list = res.data.users;

            const k = st_list.map((item, index) => ({
                'sno': index + 1,
                'name': decrypt(item.name, encriptionKey),
                'center': item.center,
                'email': decrypt(item.email, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'number': decrypt(item.form_number, encriptionKey),
                'user_id': item.user_id,
                'app_country': item.app_country,
                'app_university': item.app_university,
                'session': item.session,
                'progress': <Progress percent={item.progress} status="active" strokeColor={{ from: '#108ee9', to: '#87d068' }} />,
            }));

            setStudentList(k);
            setFilteredResults(k);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchApplicationStudentList();
    }, []);

    const studentInfo = (record) => {
        navigate("/counsellor-dashboard/student-info/" + record.user_id);
    };

    const studentApplication = (record) => {
        navigate("/counsellor-dashboard/application-process/" + record.user_id);
    };

    const searchItems = (value) => {
        setSearchInput(value);
        filterResults(value, sessionFilter);
    };

    const filterResults = (searchText, session) => {
        let filteredData = studentList;

        if (searchText) {
            filteredData = filteredData.filter(item =>
                item.name.toLowerCase().includes(searchText.toLowerCase()) ||
                item.email.toLowerCase().includes(searchText.toLowerCase()) ||
                item.mobile.toLowerCase().includes(searchText.toLowerCase())
            );
        }

        if (session) {
            filteredData = filteredData.filter(item => item.session === session);
        }

        // Recalculate sno after filtering
        filteredData = filteredData.map((item, index) => {
            const updatedItem = { ...item, sno: index + 1 };
            return updatedItem;
        });

        setFilteredResults(filteredData);
    };


    const handleSessionChange = (value) => {
        setSessionFilter(value);
        filterResults(searchInput, value);
    };

    const studentStatus = (record) => {
        navigate("/counsellor-dashboard/application-status/" + record.user_id);
    };

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },
        {
            key: "3",
            title: "Form Number",
            dataIndex: "number",
            align: 'center',
        },
        {
            key: "3",
            title: "Name",
            dataIndex: "name",
            align: 'center',
        },
        {
            key: "4",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "5",
            title: "Email",
            dataIndex: "email",
            align: 'center'
        },
        {
            key: "6",
            title: "Mobile",
            dataIndex: "mobile",
            align: 'center'
        },
        // {
        //     key: "7",
        //     title: "Progress",
        //     dataIndex: "progress",
        //     align: 'center'
        // },
        {
            key: "7",
            title: "Actions",
            render: (record) => {
                return (
                    <Space>
                        <Button onClick={() => studentInfo(record)} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>View Record</Button>
                        {record.app_country === "1" && record.app_university === "1" && (
                            <>
                                <Button onClick={() => studentApplication(record)} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Application Record</Button>
                                <Button onClick={() => studentStatus(record)} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Status</Button>
                            </>
                        )}
                    </Space>
                );
            },
        },
    ];

    const columnsCountry = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },
        {
            key: "3",
            title: "Form Number",
            dataIndex: "number",
            align: 'center',
        },
        {
            key: "3",
            title: "Name",
            dataIndex: "name",
            align: 'center',
        },
        {
            key: "4",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "5",
            title: "Email",
            dataIndex: "email",
            align: 'center'
        },
        {
            key: "6",
            title: "Mobile",
            dataIndex: "mobile",
            align: 'center'
        },
        {
            key: "7",
            title: "Actions",
            render: (record) => {
                return (
                    <Space>
                        <Button onClick={() => studentInfo(record)} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>View Record</Button>
                        {(user_data.counsellor_type === "Editor Counsellor" || user_data.counsellor_type === "Editor Counsellor Head") && (
                            <Button onClick={() => studentApplication(record)} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Application Record</Button>
                        )}
                        <Button onClick={() => studentStatus(record)} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Status</Button>
                    </Space>
                );
            },
        },
    ];

    return (
        <CounsellorLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="mt-10">
                    <Row>
                        <Col md={24} xs={24}>
                            <div className="bg-[#114D89] px-5 py-2.5">
                                <h1 className="text-white text-2xl ">Student List</h1>
                            </div>
                        </Col>
                    </Row>
                    <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                        <Row>
                            <Col md={12} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>
                                <Space>
                                    <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                                    <Select placeholder="Select Session" onChange={handleSessionChange} style={{ width: 200 }}>
                                        <Option value="2023-2024">2023-2024</Option>
                                        <Option value="2024-2025">2024-2025</Option>
                                    </Select>
                                </Space>
                            </Col>
                            <Col md={24} className="responsive-table">
                                <Table
                                    className="record"
                                    bordered
                                    columns={user_data.counsellor_type === "Country Counsellor" || user_data.counsellor_type === "Editor Counsellor" || user_data.counsellor_type === "Editor Counsellor Head" ? columnsCountry : columns}
                                    dataSource={searchInput || sessionFilter ? filteredResults : studentList}
                                    loading={loading}
                                    rowKey="user_id"
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </CounsellorLayout>
    );
};

export default ApplicationStudentList;
