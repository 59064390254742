export const counsellorPath = 'https://acapadmin.allenglobalstudies.com/uploads/counsellor/';
export const studentPath = 'https://acapadmin.allenglobalstudies.com/uploads/students/';
export const facultyPath = 'https://acapadmin.allenglobalstudies.com/uploads/faculty/';
export const api_url = 'https://acapadmin.allenglobalstudies.com/api/v1/';
export const base_url = 'https://acapadmin.allenglobalstudies.com/';
export const reportPath = 'https://acapadmin.allenglobalstudies.com/uploads/reports/';
export const studyMaterial = 'https://acapadmin.allenglobalstudies.com/uploads/study_material/';
export const doubtPath = 'https://acapadmin.allenglobalstudies.com/uploads/doubts/';
export const materialPath = 'https://acapadmin.allenglobalstudies.com/uploads/batch_material/';
export const documentPath = 'https://acapadmin.allenglobalstudies.com/uploads/documents/';

// export const reportPath = 'https://staging-acapadmin.allenglobalstudies.com/uploads/reports/';
// export const counsellorPath = 'https://staging-acapadmin.allenglobalstudies.com/uploads/counsellor/';
// export const studentPath = 'https://staging-acapadmin.allenglobalstudies.com/uploads/students/';
// export const facultyPath = 'https://staging-acapadmin.allenglobalstudies.com/uploads/faculty/';
// export const api_url = 'https://staging-acapadmin.allenglobalstudies.com/api/v1/';
// export const base_url = 'https://staging-acapadmin.allenglobalstudies.com/';
// export const studyMaterial = 'https://staging-acapadmin.allenglobalstudies.com/uploads/study_material/';
// export const doubtPath = 'https://staging-acapadmin.allenglobalstudies.com/uploads/doubts/';
