
import { Result } from 'antd';
import { Link } from 'react-router-dom';
const Error = () => {

    return (
        <>

            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Link to={'/'}
                    className="text-md text-white bg-[#F0AA0C] py-1.5 px-7  justify-center items-center mr-2.5"
                    style={{ height: 'auto', borderRadius: 20 }}
                >
                    Back Home
                </Link>}
            />
        </>
    )


}

export default Error

