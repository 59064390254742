import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/dashboard.css';
import '../styles/responsive.css';
import { Button, Col, Row, Form, Input, Collapse, Upload, DatePicker, message, Modal, Layout } from 'antd';
import { LoadingOutlined, CloudUploadOutlined, DownOutlined } from '@ant-design/icons';
import AcademicRecord from '../modal/academic-record';
import CurricularRecord from '../modal/co-curricular';
import ResearchRecord from '../modal/research-project';
import InternshipsRecords from '../modal/internships';
import AwardsRecords from '../modal/awards';
import PublicationsRecords from '../modal/publications';
import CommunityRecords from '../modal/community-services';
import OtherRecords from '../modal/other-activities';
import { decrypt, encriptionKey } from '../components/utils';
import DashboardLayout from '../components/dashboard-layout';
import { api_url, studentPath } from '../components/constants';
const { Content } = Layout;
const { Panel } = Collapse

const ProfileSummary = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [profileModalVisible, setProfileModalVisible] = useState(false);
    const [academicModalVisible, setAcademicModalVisible] = useState(false);
    const [curricularModalVisible, setCurricularModalVisible] = useState(false);
    const [researchModalVisible, setResearchModalVisible] = useState(false);
    const [awardsModalVisible, setAwardsModalVisible] = useState(false);
    const [internsModalVisible, setInternsModalVisible] = useState(false);
    const [publicationModalVisible, setPublicationModalVisible] = useState(false);
    const [communityModalVisible, setCommunityModalVisible] = useState(false);
    const [otherActivityModalVisible, setOtherActivityModalVisible] = useState(false);
    const [academicData, setAcademicData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "" }]);
    const [curricularData, setCurricularData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", learning: "" }]);
    const [researchData, setResearchData] = useState([{ year: "", class: "", activity: "", published: "", role: "", reference_link: "", level: "", outcome: "", learning: "", patent: "" }]);
    const [awardsData, setawardsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);
    const [internsData, setinternsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);
    const [publicationsData, setpublicationsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);
    const [communityData, setCommunityData] = useState([{ year: "", class: "", activity: "", type: "", role: "", hours: "", outcome: "", learning: "", patent: "" }]);
    const [othersData, setOthersData] = useState([{ year: "", class: "", activity: "", type: "", role: "", hours: "", outcome: "", learning: "", patent: "" }]);
    const [studentData, setStudentData] = useState({
        name: '', form_number: '', course_name: '', profile_picture: '', batch: '', dob: '', class: '', school: '', address: '', father_name: '', mother_name: '', father_occupation: '', mother_occupation: '',
    })

    const panelStyle = {
        marginBottom: 24,
        border: 'none',
        background: '#E3E3E3',
        boxShadow: '0px 3px 3px #00000029',
        borderRadius: 5,
        color: '#fff'
    };


    const fetchUserData = async () => {

        await axios.post(api_url + 'user/get_profile_data', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const user_info = [res.data.user_info];

            const stArray = user_info.map(item => ({
                'user_id': item.user_id,
                'name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'form_number': decrypt(item.form_number, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'mother_name': decrypt(item.mother_name, encriptionKey),
                'father_name': decrypt(item.father_name, encriptionKey),
                'dob': decrypt(item.dob, encriptionKey),
                'city': decrypt(item.city, encriptionKey),
                'psychrometric_report': item.psychrometric_report,
                'batch': item.batch,
                'father_occupation': item.father_occupation,
                'mother_occupation': item.mother_occupation,
                'diagnostic_report': item.diagnostic_report,
                'admission_date': item.admission_date,
                'address': item.address,
                'class': decrypt(item.class, encriptionKey),
                'course': decrypt(item.course, encriptionKey),
                'school': item.school,
                'photo': item.photo,

            }));
            setStudentData(stArray[0]);
            setLoading(false)

        })
    }

    const fetchStudentData = async () => {

        await axios.post(api_url + 'user/get_student_records', {
            user_id: user_data.id,

        }).then((res) => {
            let academic_data = res.data.academic_data;
            let awards_data = res.data.awards_data;
            let community_data = res.data.community_data;
            let curricular_data = res.data.curricular_data;
            let interns_data = res.data.interns_data;
            let other_data = res.data.other_data;
            let publication_data = res.data.publication_data;
            let research_data = res.data.research_data;

            if (academic_data) {
                setAcademicData(JSON.parse(academic_data));
            }
            if (other_data) {
                setOthersData(JSON.parse(other_data));
            }
            if (community_data) {
                setCommunityData(JSON.parse(community_data));
            }

            if (publication_data) {
                setpublicationsData(JSON.parse(publication_data));
            }

            if (interns_data) {
                setinternsData(JSON.parse(interns_data));
            }

            if (research_data) {
                setResearchData(JSON.parse(research_data));
            }

            if (curricular_data) {
                let cur_records = JSON.parse(curricular_data);
                setCurricularData(cur_records);
            }
            if (awards_data) {
                setawardsData(JSON.parse(awards_data));
            }
            setLoading(false)

        })
    }

    useEffect(() => {
        fetchUserData()
        fetchStudentData()
    }, []) // eslint-disable-line

    return (
        <>

            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background lg:p-7 md:p-5"
                        style={{
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        <Row gutter={30}>

                            <Col md={24}>
                                <div className="form-layout form-space">
                                    <div className="flex justify-between bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-2xl uppercase text-center">Student Profile Report</h1>
                                        {/* <a className="text-right text-white text-xl" onClick={() => setProfileModalVisible(true)}>Edit</a> */}
                                        <Modal title="Student Profile Report"
                                            width={1000}
                                            open={profileModalVisible}
                                            onOk={() => setProfileModalVisible(false)}
                                            onCancel={() => setProfileModalVisible(false)}
                                            okText='Update'
                                            okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 20, padding: '5px 40px 35px', fontSize: 18 } }}
                                            cancelButtonProps={{ style: { background: '#717070', borderRadius: 20, padding: '5px 40px 35px', fontSize: 18, color: '#fff' } }}
                                        >
                                            <ProfileModal />
                                        </Modal>
                                    </div>

                                    <div className="mx-5 my-5">
                                        <div className="rounded-lg bg-white gef-profile-block" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                            <Row>
                                                <Col md={6} style={{ borderRight: '1px solid #e7e7e7', display: 'flex', alignItems: 'center' }}>
                                                    <div className="profile-pic">
                                                        {studentData.photo == null ? (
                                                            <img src='/images/avatar.png' className="flex justify-start items-center" alt="user avatar" />
                                                        ) : (
                                                            <img src={studentPath + studentData.photo} className="flex justify-start items-center" alt="user avatar" />
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col md={18}>
                                                    <Row>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Form Number : </p>
                                                                <span>{studentData.form_number}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Admission Date : </p>
                                                                <span>{studentData.admission_date}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Center Name : </p>
                                                                <span>{studentData.city}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Name : </p>
                                                                <span>{studentData.name}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Mobile : </p>
                                                                <span>{studentData.mobile}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Email : </p>
                                                                <span>{studentData.email}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>DOB : </p>
                                                                <span>{studentData.dob}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Course : </p>
                                                                <span>{studentData.course}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Class : </p>
                                                                <span>{studentData.class}</span>
                                                            </div>
                                                        </Col>

                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Father's Name : </p>
                                                                <span>{studentData.father_name}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Mother's Name : </p>
                                                                <span>{studentData.mother_name}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Father's Occupation : </p>
                                                                <span>{studentData.father_occupation}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={8} lg={12} md={12}>
                                                            <div className="gef-data">
                                                                <p>Mother's Occupation : </p>
                                                                <span>{studentData.mother_occupation}</span>
                                                            </div>
                                                        </Col>
                                                        <Col xl={16} lg={16} md={16}>
                                                            <div className="gef-data">
                                                                <p>Address : </p>
                                                                <span>{studentData.address}</span>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <Row>
                                        <Col md={24} xs={24} className='lg:px-5 px-5'>
                                            <Collapse defaultActiveKey={['1']}
                                                bordered={false}
                                                expandIconPosition='end'
                                                style={{ marginTop: "-1vh", }}
                                                expandIcon={({ isActive }) => (
                                                    <DownOutlined
                                                        style={{ color: '#A2A1A1', paddingTop: 10, }}
                                                        rotate={isActive ? 180 : 0}
                                                    />
                                                )}
                                            >

                                                <Panel header="Academic Records" key="1" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Year</th>
                                                                    <th>Class</th>
                                                                    <th>Activity</th>
                                                                    <th>Type</th>
                                                                    <th>Your Role</th>
                                                                    <th>Level</th>
                                                                    <th>Marks</th>
                                                                    <th>Rank</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {academicData.map((row, index) => (
                                                                    row.class > 0 ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Year -">{row.year}</td>
                                                                            <td data-label="Class -">{row.class}</td>
                                                                            <td data-label="Activity -">{row.activity}</td>
                                                                            <td data-label="Type -">{row.type}</td>
                                                                            <td data-label="Your Role -">{row.role}</td>
                                                                            <td data-label="Level -">{row.level}</td>
                                                                            <td data-label="Marks -">{row.marks}</td>
                                                                            <td data-label="Rank -">{row.rank}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={10} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setAcademicModalVisible(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <AcademicRecord
                                                            show={academicModalVisible}
                                                            hide={() => setAcademicModalVisible(false)} />
                                                    </div>
                                                    <div>
                                                    </div>
                                                </Panel>

                                                {/* end of academic */}

                                                {/* collapse design co-curricular */}
                                                <Panel header="Co-Curricular" key="2" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Year</th>
                                                                    <th>Class</th>
                                                                    <th>Activity</th>
                                                                    <th>Type</th>
                                                                    <th>Your Role</th>
                                                                    <th>Level</th>
                                                                    <th>Marks</th>
                                                                    <th>Learning</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {curricularData.map((row, index) => (
                                                                    row.class > 0 ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Year -">{row.year}</td>
                                                                            <td data-label="Class -">{row.class}</td>
                                                                            <td data-label="Activity -">{row.activity}</td>
                                                                            <td data-label="Type -">{row.type}</td>
                                                                            <td data-label="Your Role -">{row.role}</td>
                                                                            <td data-label="Level -">{row.level}</td>
                                                                            <td data-label="Marks -">{row.marks}</td>
                                                                            <td data-label="learning -">{row.learning}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={10} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setCurricularModalVisible(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <CurricularRecord
                                                            show={curricularModalVisible}
                                                            hide={() => setCurricularModalVisible(false)} />
                                                    </div>
                                                </Panel>

                                                {/* end co-curricular */}

                                                {/* Research project collapse start */}
                                                <Panel header="Research Projects" key="3" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Year</th>
                                                                    <th>Class</th>
                                                                    <th>Details of project work</th>
                                                                    <th>Your Role</th>
                                                                    <th>Published</th>
                                                                    <th>Level</th>
                                                                    <th>Reference Link</th>
                                                                    <th>Your Learning</th>
                                                                    <th>Implementation Outcome</th>
                                                                    <th>Patent</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {researchData.map((row, index) => (
                                                                    row.class > 0 ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Year -">{row.year}</td>
                                                                            <td data-label="Class -">{row.class}</td>
                                                                            <td data-label="Details of project work -">{row.activity}</td>
                                                                            <td data-label="Your Role -">{row.role}</td>
                                                                            <td data-label="Published -">{row.published}</td>
                                                                            <td data-label="Level -">{row.level}</td>
                                                                            <td data-label="Reference Link -">{row.reference_link}</td>
                                                                            <td data-label="Your Learning -">{row.learning}</td>
                                                                            <td data-label="Implementation Outcome -">{row.outcome}</td>
                                                                            <td data-label="Patent -">{row.patent}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={12} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setResearchModalVisible(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <ResearchRecord
                                                            show={researchModalVisible}
                                                            hide={() => setResearchModalVisible(false)} />
                                                    </div>
                                                </Panel>
                                                {/* Research project collapse end */}

                                                {/* Awards/Honours collapse start */}
                                                <Panel header="Awards/Honours" key="4" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Year</th>
                                                                    <th>Class</th>
                                                                    <th>Details of Activity</th>
                                                                    <th>Type</th>
                                                                    <th>Your Role</th>
                                                                    <th>Level</th>
                                                                    <th>Rank</th>
                                                                    <th>Remarks</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {awardsData.map((row, index) => (
                                                                    row.class > 0 ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Year -">{row.year}</td>
                                                                            <td data-label="Class -">{row.class}</td>
                                                                            <td data-label="Details of Activity -">{row.activity}</td>
                                                                            <td data-label="Type -">{row.type}</td>
                                                                            <td data-label="Your Role -">{row.role}</td>
                                                                            <td data-label="Level -">{row.level}</td>
                                                                            <td data-label="Rank -">{row.rank}</td>
                                                                            <td data-label="Remarks -">{row.remarks}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={10} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setAwardsModalVisible(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <AwardsRecords
                                                            show={awardsModalVisible}
                                                            hide={() => setAwardsModalVisible(false)} />
                                                    </div>
                                                </Panel>
                                                {/* Awards/Honours collapse end */}

                                                {/* Internships collapse start */}
                                                <Panel header="Internships" key="5" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Year</th>
                                                                    <th>Class</th>
                                                                    <th>Details of Activity</th>
                                                                    <th>Type</th>
                                                                    <th>Your Role</th>
                                                                    <th>Level</th>
                                                                    <th>Rank</th>
                                                                    <th>Remarks</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {internsData.map((row, index) => (
                                                                    row.class > 0 ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Year -">{row.year}</td>
                                                                            <td data-label="Class -">{row.class}</td>
                                                                            <td data-label="Details of Activity -">{row.activity}</td>
                                                                            <td data-label="Type -">{row.type}</td>
                                                                            <td data-label="Your Role -">{row.role}</td>
                                                                            <td data-label="Level -">{row.level}</td>
                                                                            <td data-label="Rank -">{row.rank}</td>
                                                                            <td data-label="Remarks -">{row.remarks}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={10} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setInternsModalVisible(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <InternshipsRecords
                                                            show={internsModalVisible}
                                                            hide={() => setInternsModalVisible(false)} />
                                                    </div>
                                                </Panel>
                                                {/* Internships collapse end */}

                                                {/* Publications collapse start */}
                                                <Panel header="Publications" key="6" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Year</th>
                                                                    <th>Class</th>
                                                                    <th>Details of Activity</th>
                                                                    <th>Type</th>
                                                                    <th>Your Role</th>
                                                                    <th>Level</th>

                                                                    <th>Remarks</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {publicationsData.map((row, index) => (
                                                                    row.class > 0 ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Year -">{row.year}</td>
                                                                            <td data-label="Class -">{row.class}</td>
                                                                            <td data-label="Details of Activity -">{row.activity}</td>
                                                                            <td data-label="Type -">{row.type}</td>
                                                                            <td data-label="Your Role -">{row.role}</td>
                                                                            <td data-label="Level -">{row.level}</td>

                                                                            <td data-label="Remarks -">{row.remarks}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={10} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setPublicationModalVisible(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <PublicationsRecords
                                                            show={publicationModalVisible}
                                                            hide={() => setPublicationModalVisible(false)} />
                                                    </div>
                                                </Panel>
                                                {/* Publications collapse end */}

                                                {/* Community Services collapse start */}
                                                <Panel header="Community Services" key="8" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Sno</th>
                                                                    <th>Year</th>
                                                                    <th>Class</th>
                                                                    <th>Details of Activity	</th>
                                                                    <th>Type</th>
                                                                    <th>Role</th>
                                                                    <th>Hours Devoted</th>
                                                                    <th>Final Outcome</th>
                                                                    <th>Your Learning</th>
                                                                    <th>Patent</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {communityData.map((row, index) => (
                                                                    row.class > 0 ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Year -">{row.year}</td>
                                                                            <td data-label="Class -">{row.class}</td>
                                                                            <td data-label="Details of Activity -">{row.activity}</td>
                                                                            <td data-label="Type -">{row.type}</td>
                                                                            <td data-label="Role -">{row.role}</td>
                                                                            <td data-label="Hours Devoted -">{row.hours}</td>
                                                                            <td data-label="Final Outcome -">{row.outcome}</td>
                                                                            <td data-label="Your Learning -">{row.learning}</td>
                                                                            <td data-label="Patent -">{row.patent}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={10} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setCommunityModalVisible(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <CommunityRecords
                                                            show={communityModalVisible}
                                                            hide={() => setCommunityModalVisible(false)} />
                                                    </div>
                                                </Panel>
                                                {/* Community Services collapse end */}

                                                {/* Others collapse start */}
                                                <Panel header="Others" key="9" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Year</th>
                                                                    <th>Class</th>
                                                                    <th>Details of Activity</th>
                                                                    <th>Type</th>
                                                                    <th>Your Role</th>
                                                                    <th>Hours Devoted</th>
                                                                    <th>Final Outcome</th>
                                                                    <th>Your Learning</th>
                                                                    <th>Patent</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {othersData.map((row, index) => (
                                                                    row.class > 0 ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Year -">{row.year}</td>
                                                                            <td data-label="Class -">{row.class}</td>
                                                                            <td data-label="Details of Activity -">{row.activity}</td>
                                                                            <td data-label="Type -">{row.type}</td>
                                                                            <td data-label="Your Role -">{row.role}</td>
                                                                            <td data-label="Hours Devoted -">{row.hours}</td>
                                                                            <td data-label="Final Outcome -">{row.outcome}</td>
                                                                            <td data-label="Your Learning -">{row.learning}</td>
                                                                            <td data-label="Patent -">{row.patent}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={10} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setOtherActivityModalVisible(true)}
                                                        >
                                                            Edit
                                                        </Button>
                                                        <OtherRecords
                                                            show={otherActivityModalVisible}
                                                            hide={() => setOtherActivityModalVisible(false)} />
                                                    </div>
                                                </Panel>
                                                {/*Others collapse end */}
                                            </Collapse>
                                        </Col>
                                    </Row>

                                </div>
                            </Col>
                        </Row>

                    </Content>
                )
                }
            </DashboardLayout>
        </>
    )
}

const ProfileModal = () => {
    const [imageUrl, setImageUrl] = useState();
    const [loading, setLoading] = useState(true);
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <CloudUploadOutlined />}
            <div
                className="text-base text-[#4D4D4D]"
                style={{
                    marginTop: 8,
                }}
            >
                Upload Your Photo
            </div>
        </div>
    );
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };
    const handleChangeImage = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };
    return (
        <div className="form-layout my-10" style={{ borderTop: '8px solid #114D89', boxShadow: '0px 3px 6px #00000029' }}>
            <div className="md:flex justify-center items-center mx-10">
                <Form
                    name="normal_login"
                    className="my-7"
                //form={form}
                // onFinish={onFinish}
                >
                    <div className="lg:flex justify-between">
                        <div className="md:flex flex-col">
                            <Row gutter={50}>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="name"
                                        label="Your Name"
                                        wrapperCol={{ span: 24 }}
                                        labelCol={{ span: 24 }}
                                    //initialValue={studentData.name}
                                    >
                                        <Input
                                        //onChange={(e) => handleChange('name', e.target.value)} 
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24}>
                                    <Form.Item
                                        name="form_number"
                                        label="Allen Form Number"
                                        wrapperCol={{ span: 24 }}
                                        labelCol={{ span: 24 }}
                                    //initialValue={studentData.form_number}
                                    >
                                        <Input
                                        //onChange={(e) => handleChange('form_number', e.target.value)} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={24} xs={24}>
                                    <Form.Item
                                        name="course_name"
                                        label="Allen Course Name"
                                        wrapperCol={{ span: 24 }}
                                        labelCol={{ span: 24 }}
                                    //initialValue={studentData.course_name}
                                    >
                                        <Input
                                        //onChange={(e) => handleChange('course_name', e.target.value)} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Form.Item>
                            <Col md={4} xs={24} className='mr-20'>
                                <Upload
                                    name="profile_picture"
                                    listType="picture-card"
                                    className="avatar-uploader p-5"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChangeImage}

                                >
                                    {imageUrl ? (
                                        <img
                                            src={imageUrl}
                                            alt="avatar"
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Col>
                        </Form.Item>
                    </div>
                    <Row gutter={30}>
                        <Col md={8} xs={24}>
                            <Form.Item
                                name="batch"
                                label="Batch"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            //initialValue={studentData.batch}
                            >
                                <Input
                                //onChange={(e) => handleChange('batch', e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                            <Form.Item
                                name="dob"
                                label="DOB"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            // initialValue={studentData.dob}
                            >
                                <DatePicker style={{ width: '100%' }}
                                //onChange={(e) => handleChange('dob', e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                            <Form.Item
                                name="class"
                                label="Class"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            // initialValue={studentData.class}
                            >
                                <Input
                                //onChange={(e) => handleChange('class', e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col md={12} xs={24}>
                            <Form.Item
                                name="school"
                                label="School's Name With Full Address & Contact Information"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            //initialValue={studentData.school}
                            >
                                <Input.TextArea type='address'
                                //onChange={(e) => handleChange('school', e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Item
                                name="address"
                                label="Address"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            //initialValue={studentData.address}
                            >
                                <Input.TextArea
                                    //onChange={(e) => handleChange('address', e.target.value)}
                                    type="name"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col md={12}>
                            <Form.Item
                                name="father_name"
                                label="Father's Name"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            //initialValue={studentData.father_name}
                            >
                                <Input type='name'
                                //onChange={(e) => handleChange('father_name', e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name="father_occupation"
                                label="Father's Occupation"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            //initialValue={studentData.father_occupation}
                            >
                                <Input
                                //onChange={(e) => handleChange('father_occupation', e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={30}>
                        <Col md={12}>
                            <Form.Item
                                name="mother_name"
                                label="Mother's Name"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            //initialValue={studentData.mother_name}
                            >
                                <Input
                                //onChange={(e) => handleChange('mother_name', e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item
                                name="mother_occupation"
                                label="Mother's Occupation"
                                wrapperCol={{ span: 24 }}
                                labelCol={{ span: 24 }}
                            //initialValue={studentData.mother_occupation}
                            >
                                <Input
                                    type="name"
                                //onChange={(e) => handleChange('mother_occupation', e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default ProfileSummary