import React, { useState, useEffect } from 'react';
import { Modal, Form, Popconfirm, } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url } from '../components/constants';

const AcademicRecord = ({ show, hide, }) => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(false);
    const [academicData, setAcademicData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "" }]);
    const [satData, setSatData] = useState({ sat_attempt: '', sat_composite: '', sat_math: '', sat_english: '' }) // eslint-disable-line
    const [actData, setActData] = useState({ act_attempt: '', act_composite: '', act_math: '', act_english: '', act_science: '', act_writing: '', act_reading: '' }) // eslint-disable-line
    const [apData, setApData] = useState({ ap_subject: '', ap_attempt: '', ap_score: '' }) // eslint-disable-line

    const handleAddRow = () => {
        setAcademicData([...academicData, { year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "" }]);
    };


    const handleChange = (e, index) => {
        const newData = [...academicData];

        newData[index][e.target.name] = e.target.value;
        setAcademicData(newData);

    };

    const handleSubmit = () => {
        const rows = [];
        let error = "";
        for (let i = 0; i < academicData.length; i++) {
            if (academicData[i].year !== "" && academicData[i].year != null && academicData[i].class !== "" && academicData[i].class != null
                && academicData[i].activity !== "" && academicData[i].activity != null
                && academicData[i].marks !== "" && academicData[i].marks != null && academicData[i].type !== "" && academicData[i].level != null) {
                rows.push(academicData[i]);
            } else {
                error = "1";
            }
        }
        if (error) {
            toast.error("Please fill your academic details !", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }

        axios.post(api_url + 'user/update_academic_records', {
            user_id: user_data.id,
            aca_data: rows,

        })
            .then(result => {
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => { // eslint-disable-line
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error("Please fill your academic details !", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    };

    useEffect(() => {
        const fetchUserData = async () => {
            await axios.post(api_url + 'user/get_academic_data', {
                user_id: user_data.id,

            }).then((res) => {
                let data = res.data.academic_data;
                let sat_data = res.data.sat_data;
                let act_data = res.data.act_data;
                let ap_data = res.data.ap_data;

                if (data) {
                    setAcademicData(JSON.parse(data));
                }
                if (sat_data) {
                    setSatData(JSON.parse(sat_data));
                }
                if (act_data) {
                    setActData(JSON.parse(act_data));
                }
                if (ap_data) {
                    setApData(JSON.parse(ap_data));
                }
                setLoading(false)
            })
        }
        fetchUserData()
    }, [user_data.id])


    const handleRemove = (index) => {
        const newTable = [...academicData]
        newTable.splice(index, 1)
        setAcademicData(newTable)
    }

    return (
        <>
            <ToastContainer />
            {loading ? (
                <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
            ) : (
                <Modal
                    title="Academic Records"
                    open={show}
                    onCancel={hide}
                    onOk={() => {
                        handleSubmit();
                    }
                    }
                    okText='Save'
                    width={1200}
                    cancelText='Cancel'
                    okButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                    cancelButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                >
                    <Form
                        name="normal_login"
                        className="my-7 mx-5"
                    >
                        <table className='curricular-data'>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Class</th>
                                    <th>Activity</th>
                                    <th>Type</th>
                                    <th>Your Role</th>
                                    <th>Level</th>
                                    <th>Marks (%)</th>
                                    <th>Rank</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {academicData.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <select value={row.year == null ? '' : row.year}
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                name="year"
                                                onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="2015">2015</option>
                                                <option value="2016">2016</option>
                                                <option value="2017">2017</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.class == null ? '' : row.class}
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                name="class"
                                                onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="activity"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.activity == null ? '' : row.activity}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <select value={row.type == null ? '' : row.type} name="type" width={100} className="ant-input css-dev-only-do-not-override-k83k30" onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="Achievement">Achievement</option>
                                                <option value="Participant">Participant</option>
                                                <option value="Honour">Honour</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.role == null ? '' : row.role} name="role" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="Participant">Participant</option>
                                                <option value="Leader">Leader</option>
                                                <option value="Not Applicable">Not Applicable</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.level == null ? '' : row.level} name="level" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="School">School</option>
                                                <option value="Interschool">Interschool</option>
                                                <option value="District">District</option>
                                                <option value="State">State</option>
                                                <option value="National">National</option>
                                                <option value="International">International</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="marks"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.marks == null ? '' : row.marks}
                                                onChange={(e) => handleChange(e, index)}
                                                step="0.01" // Allows input of numbers with up to 2 decimal places
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="rank"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.rank == null ? '' : row.rank}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            {
                                                (index > 0) ? <Popconfirm title="Sure to delete?" onConfirm={() => handleRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                    <button className='bg-transparent border-none p-0 m-0 cursor-pointer'>
                                                        <DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' />
                                                    </button>
                                                </Popconfirm> : ""}

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5" onClick={handleAddRow} style={{ float: 'right' }}>
                            Add Row
                        </button>

                    </Form>
                </Modal>
            )}
        </>

    )

}

export default AcademicRecord
