import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Layout, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import '../styles/dashboard.css';
import axios from 'axios';
import DashboardLayout from '../components/dashboard-layout';
import { api_url, documentPath } from '../components/constants';

const { Content } = Layout;

const DocumentUpload = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState({
        class10Marksheet: [],
        passportPhoto: [],
        aadharCardFront: [],
        aadharCardBack: [],
        schoolReceipt: [],
        itrDocuments: []
    });
    const login_user = JSON.parse(localStorage.getItem('user'));
    const user_data = login_user?.user;

    if (!login_user) {
        window.location.href = "/";
    }


    useEffect(() => {

        const fetchDocumentData = async () => {
            try {
                setLoading(true);
                const response = await axios.post(api_url + 'ajax/my-document', {
                    user_id: user_data.id
                });

                if (response.data.status) {
                    const { data } = response.data;
                    const formattedFileList = {
                        class10Marksheet: data.class10Marksheet ? [{ uid: '-1', status: 'done', url: documentPath + data.class10Marksheet }] : [],
                        passportPhoto: data.passportPhoto ? [{ uid: '-2', name: data.passportPhoto, status: 'done', url: documentPath + data.passportPhoto }] : [],
                        aadharCardFront: data.aadharCardFront ? [{ uid: '-3', name: data.aadharCardFront, status: 'done', url: documentPath + data.aadharCardFront }] : [],
                        aadharCardBack: data.aadharCardBack ? [{ uid: '-4', name: data.aadharCardBack, status: 'done', url: documentPath + data.aadharCardBack }] : [],
                        schoolReceipt: data.schoolReceipt ? [{ uid: '-5', name: data.schoolReceipt, status: 'done', url: documentPath + data.schoolReceipt }] : [],
                        itrDocuments: data.itrDocuments ? [{ uid: '-6', name: data.itrDocuments, status: 'done', url: documentPath + data.itrDocuments }] : []
                    };

                    setFileList(formattedFileList);
                    form.setFieldsValue(formattedFileList);
                } else {
                    // message.error('Failed to fetch document data.');
                }
            } catch (error) {
                //message.error('An error occurred while fetching document data.');
            } finally {
                setLoading(false);
            }
        };

        fetchDocumentData();
    }, [user_data.id]); // eslint-disable-line



    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('user_id', user_data.id);

        Object.keys(values).forEach(key => {
            if (Array.isArray(values[key])) {
                values[key].forEach(file => {
                    formData.append(key, file.originFileObj);
                });
            }
        });

        axios.post(api_url + 'ajax/upload-document', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(result => {
                message.success('Documents uploaded successfully.');
            })
            .catch(error => {
                message.error('Error uploading files.');
            });
    };

    const beforeUpload = (file, type) => {
        const isAcceptedType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

        if (!isAcceptedType) {
            message.error('You can only upload JPG, PNG, PDF, or Word files!');
            return Upload.LIST_IGNORE;
        }

        // Remove existing file before adding a new one
        if (fileList[type].length > 0) {
            setFileList({ ...fileList, [type]: [] });
        }

        return isAcceptedType;
    };

    const handleChange = (info, type) => {
        setFileList({ ...fileList, [type]: info.fileList });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <DashboardLayout>
            <Content
                className="site-layout-background lg:p-7 md:p-5"
                style={{
                    margin: '20px 16px 24px',
                    background: '#fff',
                    width: '100%'
                }}
            >
                {loading ? (
                    <div className="loaders"><img src='/images/loader.gif' alt="loading" /></div>
                ) : (
                    <Row gutter={30}>
                        <Col md={24}>
                            <div className="form-layout form-space">
                                <div className="flex justify-between bg-[#0F4275] py-2.5 px-7">
                                    <h1 className="text-[#fff] text-2xl uppercase text-center">My Documents</h1>
                                </div>
                                <div className="bg-[#F5F5F5]">
                                    <Form
                                        form={form}
                                        name="document_upload"
                                        className="mx-2.5 p-5"
                                        onFinish={onFinish}
                                        initialValues={fileList}
                                        validateTrigger="onSubmit"
                                    >
                                        <h2 className="text-[#0C3762] text-base mb-5 font-medium">Upload Required Documents</h2>
                                        <div className="p-2.5 my-5" style={{ border: '1px solid #EEE6E6' }}>

                                            <Form.Item
                                                className="form-item-label-wrap"
                                                name="class10Marksheet"
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                label="Class 10th Marksheet"
                                                valuePropName="fileList"
                                                getValueFromEvent={normFile}
                                                rules={[{ required: true, message: 'Please upload Class 10th Marksheet!' }]}
                                            >
                                                <Row align="middle" gutter={16}>
                                                    <Col span={12}>
                                                        <Upload
                                                            name="class10Marksheet"
                                                            listType="picture"
                                                            fileList={fileList.class10Marksheet}
                                                            onChange={(info) => handleChange(info, 'class10Marksheet')}
                                                            beforeUpload={(file) => beforeUpload(file, 'class10Marksheet')}
                                                            showUploadList={false}
                                                        >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Upload>
                                                    </Col>
                                                    <Col span={12} style={{ display: "flex", justifyContent: 'end' }}>
                                                        <Upload
                                                            name="class10Marksheet"
                                                            listType="picture"
                                                            fileList={fileList.class10Marksheet}
                                                            onChange={(info) => handleChange(info, 'class10Marksheet')}
                                                            beforeUpload={(file) => beforeUpload(file, 'class10Marksheet')}
                                                            showUploadList={{
                                                                showPreviewIcon: false,
                                                                showRemoveIcon: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>


                                            <Form.Item
                                                className="form-item-label-wrap"
                                                name="passportPhoto"
                                                label="Passport size Photograph"
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                valuePropName="fileList"
                                                getValueFromEvent={normFile}
                                                rules={[{ required: true, message: 'Please upload Passport size Photograph!' }]}
                                            >
                                                <Row align="middle" gutter={16}>
                                                    <Col span={12}>
                                                        <Upload
                                                            name="passportPhoto"
                                                            listType="picture"
                                                            fileList={fileList.passportPhoto}
                                                            onChange={(info) => handleChange(info, 'passportPhoto')}
                                                            beforeUpload={(file) => beforeUpload(file, 'passportPhoto')}
                                                            showUploadList={false}
                                                        >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Upload>
                                                    </Col>
                                                    <Col span={12} style={{ display: "flex", justifyContent: 'end' }}>
                                                        <Upload
                                                            name="passportPhoto"
                                                            listType="picture"
                                                            fileList={fileList.passportPhoto}
                                                            onChange={(info) => handleChange(info, 'passportPhoto')}
                                                            beforeUpload={(file) => beforeUpload(file, 'passportPhoto')}
                                                            showUploadList={{
                                                                showPreviewIcon: false,
                                                                showRemoveIcon: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>



                                            <Form.Item
                                                className="form-item-label-wrap"
                                                name="aadharCardFront"
                                                label="Aadhar Card Front"
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                valuePropName="fileList"
                                                getValueFromEvent={normFile}
                                                rules={[{ required: true, message: 'Please upload Aadhar Card Front!' }]}
                                            >
                                                <Row align="middle" gutter={16}>
                                                    <Col span={12}>
                                                        <Upload
                                                            name="aadharCardFront"
                                                            listType="picture"
                                                            fileList={fileList.aadharCardFront}
                                                            onChange={(info) => handleChange(info, 'aadharCardFront')}
                                                            beforeUpload={(file) => beforeUpload(file, 'aadharCardFront')}
                                                            showUploadList={false}
                                                        >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Upload>
                                                    </Col>
                                                    <Col span={12} style={{ display: "flex", justifyContent: 'end' }}>
                                                        <Upload
                                                            name="aadharCardFront"
                                                            listType="picture"
                                                            fileList={fileList.aadharCardFront}
                                                            onChange={(info) => handleChange(info, 'aadharCardFront')}
                                                            beforeUpload={(file) => beforeUpload(file, 'aadharCardFront')}
                                                            showUploadList={{
                                                                showPreviewIcon: false,
                                                                showRemoveIcon: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>


                                            <Form.Item
                                                className="form-item-label-wrap"
                                                name="aadharCardBack"
                                                label="Aadhar Card Back"
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                valuePropName="fileList"
                                                getValueFromEvent={normFile}
                                                rules={[{ required: true, message: 'Please upload Aadhar Card Back!' }]}
                                            >
                                                <Row align="middle" gutter={16}>
                                                    <Col span={12}>
                                                        <Upload
                                                            name="aadharCardBack"
                                                            listType="picture"
                                                            fileList={fileList.aadharCardBack}
                                                            onChange={(info) => handleChange(info, 'aadharCardBack')}
                                                            beforeUpload={(file) => beforeUpload(file, 'aadharCardBack')}
                                                            showUploadList={false}
                                                        >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Upload>
                                                    </Col>
                                                    <Col span={12} style={{ display: "flex", justifyContent: 'end' }}>
                                                        <Upload
                                                            name="aadharCardBack"
                                                            listType="picture"
                                                            fileList={fileList.aadharCardBack}
                                                            onChange={(info) => handleChange(info, 'aadharCardBack')}
                                                            beforeUpload={(file) => beforeUpload(file, 'aadharCardBack')}
                                                            showUploadList={{
                                                                showPreviewIcon: false,
                                                                showRemoveIcon: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>



                                            <Form.Item
                                                className="form-item-label-wrap"
                                                name="schoolReceipt"
                                                label="Latest School Receipt"
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                valuePropName="fileList"
                                                getValueFromEvent={normFile}
                                                rules={[{ required: true, message: 'Please upload Latest School Receipt!' }]}
                                            >
                                                <Row align="middle" gutter={16}>
                                                    <Col span={12}>
                                                        <Upload
                                                            name="schoolReceipt"
                                                            listType="picture"
                                                            fileList={fileList.schoolReceipt}
                                                            onChange={(info) => handleChange(info, 'schoolReceipt')}
                                                            beforeUpload={(file) => beforeUpload(file, 'schoolReceipt')}
                                                            showUploadList={false}
                                                        >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Upload>
                                                    </Col>
                                                    <Col span={12} style={{ display: "flex", justifyContent: 'end' }}>
                                                        <Upload
                                                            name="schoolReceipt"
                                                            listType="picture"
                                                            fileList={fileList.schoolReceipt}
                                                            onChange={(info) => handleChange(info, 'schoolReceipt')}
                                                            beforeUpload={(file) => beforeUpload(file, 'schoolReceipt')}
                                                            showUploadList={{
                                                                showPreviewIcon: false,
                                                                showRemoveIcon: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>


                                            <Form.Item
                                                className="form-item-label-wrap"
                                                name="itrDocuments"
                                                label="Recent ITR (Income tax return) of both the parents (for 90% rebate)"
                                                labelCol={{ span: 6 }}
                                                wrapperCol={{ span: 18 }}
                                                valuePropName="fileList"
                                                getValueFromEvent={normFile}
                                                rules={[{ required: true, message: 'Please upload Recent ITR!' }]}
                                            >
                                                <Row align="middle" gutter={16}>
                                                    <Col span={12}>
                                                        <Upload
                                                            name="itrDocuments"
                                                            listType="picture"
                                                            fileList={fileList.itrDocuments}
                                                            onChange={(info) => handleChange(info, 'itrDocuments')}
                                                            beforeUpload={(file) => beforeUpload(file, 'itrDocuments')}
                                                            showUploadList={false}
                                                        >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Upload>
                                                    </Col>
                                                    <Col span={12} style={{ display: "flex", justifyContent: 'end' }}>
                                                        <Upload
                                                            name="itrDocuments"
                                                            listType="picture"
                                                            fileList={fileList.itrDocuments}
                                                            onChange={(info) => handleChange(info, 'itrDocuments')}
                                                            beforeUpload={(file) => beforeUpload(file, 'itrDocuments')}
                                                            showUploadList={{
                                                                showPreviewIcon: false,
                                                                showRemoveIcon: true,
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>



                                            {/* </Row> */}
                                        </div>

                                        <Row justify="center">
                                            <Col>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="text-lg text-white bg-[#2E6798] rounded-lg py-1.5 px-5 flex justify-center"
                                                    style={{ height: 'auto' }}
                                                >
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </Content>
        </DashboardLayout>
    );
};

export default DocumentUpload;
