import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import { Button, Col, Row, Layout, Modal, Form, Space } from 'antd';
import DashboardLayout from '../components/dashboard-layout';
import Loader from '../components/loader';
import axios from 'axios';
import { api_url } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Content } = Layout;


const BatchRequest = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [doubtLoading, setDoubtLoading] = useState(true);
    const [serviceList, setServices] = useState({ label: '', value: '' });  // eslint-disable-line
    const [batchData, setbatchData] = useState([]);
    const [classModalVisible, setClassModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [timeSlot, settimeSlot] = useState({});
    const [userSlotloading, setUserSlotLoading] = useState(true);  // eslint-disable-line
    const [slotSet, setSlot] = useState(true);  // eslint-disable-line
    const [selectedBatchIds, setSelectedBatchIds] = useState({});
    const [ajax_loading, setAjaxLoading] = useState(false);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

    // Utility function to format time (e.g., "09:00:00" to "9:00 AM")
    const formatTime = (timeString) => {
        const time = new Date('1970-01-01T' + timeString);
        return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };

    const handleBatchChange = (service, phase, batchId) => {
        setSelectedBatchIds(prevState => ({
            ...prevState,
            [service]: batchId
        }));
    };

    const handleReset = () => {
        setSelectedBatchIds({});
    };

    const fetchServiceList = async () => {

        await axios.post(api_url + 'ajax/get-user-wise-service', {
            user_id: user_data.id
        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            let c_options = [];

            setServices(c_options);
            setLoading(false)

        })
    }

    const fetchUserBatchList = async () => {
        await axios.post(api_url + 'ajax/get-user-batch-request', {
            user_id: user_data.id

        }).then((res) => {


            if (res.data.status) {
                const batch_data = res.data.batch_data;
                setbatchData(batch_data);
            }

            setDoubtLoading(false)

        })
    }

    const fetchUserSlot = async () => {
        await axios.post(api_url + 'ajax/get-user-wise-service', {
            user_id: user_data.id,

        }).then((res) => {

            if (res.data.time_slot) {


                const time_slot = res.data.time_slot;

                if (res.data.count > 0) {
                    if (Array.isArray(res.data.batch_count) && res.data.batch_count.length > 0) {
                        setClassModalVisible(false);
                    } else {
                        setClassModalVisible(false);
                    }

                    settimeSlot(time_slot);
                    setUserSlotLoading(true);
                } else {

                    setUserSlotLoading(false)
                }
            } else {
                setUserSlotLoading(false)
                setSlot(false);
            }

        })
    }

    useEffect(() => {
        fetchServiceList()
        fetchUserBatchList()
        fetchUserSlot()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onFinish = () => {
        setAjaxLoading(true);

        axios.post(api_url + 'ajax/submit_user_slot_request', {
            batch_id: Object.values(selectedBatchIds),
            user_id: user_data.id,
        })
            .then(result => {

                setAjaxLoading(false);
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => { // eslint-disable-line
                        setClassModalVisible(false);
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })

    };


    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background lg:p-7 md:p-5"
                        style={{
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mb-0 mx-0">
                            <Row>
                                <Col md={24}>
                                    <div className="form-layout form-space">
                                        <div className=" bg-[#0F4275] py-2.5 px-7 md:flex justify-between">
                                            <h1 className="text-[#fff] text-2xl uppercase">Batch Request</h1>
                                            <button type='button' className="text-right text-white text-xl bg-transparent border-none p-0 m-0 cursor-pointer" onClick={() => setClassModalVisible(true)}>Create Batch Request</button>
                                            <Modal title="Select your Class Slot"
                                                open={classModalVisible}
                                                onOk={() => setClassModalVisible(false)}
                                                onCancel={() => setClassModalVisible(false)}
                                                footer={null}
                                                maskClosable={false}
                                                width={900}
                                            >

                                                <div>
                                                    {loading ? (
                                                        <p>Loading...</p>
                                                    ) : (
                                                        <Form form={form} onFinish={onFinish}>
                                                            <table className="class-slot responsive-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Service</th>
                                                                        <th>Phase</th>
                                                                        <th>Batch</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(timeSlot).map(service => (
                                                                        Object.keys(timeSlot[service]).map((phase, phaseIndex) => (
                                                                            timeSlot[service][phase].map((batch, batchIndex) => (
                                                                                <tr key={`${service}_${phase}_${batch.batch_id}`}>
                                                                                    {phaseIndex === 0 && batchIndex === 0 && (
                                                                                        <td align='center' rowSpan={Object.values(timeSlot[service]).flat().length}>{service}</td>
                                                                                    )}
                                                                                    {batchIndex === 0 && (
                                                                                        <td align='center' rowSpan={timeSlot[service][phase].length}>
                                                                                            <strong>{phase}</strong> <br />
                                                                                            {formatDate(batch.start_date) + ' - ' + formatDate(batch.end_date)}
                                                                                        </td>
                                                                                    )}
                                                                                    <td>
                                                                                        <Form.Item
                                                                                            key={batch.batch_id}
                                                                                            name={service}
                                                                                        >
                                                                                            <input
                                                                                                type="radio"
                                                                                                name={service}
                                                                                                id={`${service}_${phase}_${batch.batch_id}`}
                                                                                                value={batch.batch_id}
                                                                                                className="checkbox-radio"
                                                                                                onChange={() => handleBatchChange(service, phase, batch.batch_id)}
                                                                                                checked={selectedBatchIds[service] === batch.batch_id}
                                                                                            />
                                                                                            <label htmlFor={`${service}_${phase}_${batch.batch_id}`}></label>
                                                                                            {formatTime(batch.start_time) + ' - ' + formatTime(batch.end_time)} {batch.days}
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                </tr>

                                                                            ))
                                                                        ))
                                                                    ))}
                                                                </tbody>
                                                            </table>

                                                            <Space className="flex justify-center items-center">
                                                                <Form.Item >
                                                                    <Button type="primary" htmlType="submit" className="bg-[#F8B133] ">
                                                                        Submit
                                                                    </Button>
                                                                </Form.Item>
                                                                <Form.Item >
                                                                    <Button type="primary" onClick={handleReset} className="bg-[#F8B133]">
                                                                        Reset
                                                                    </Button>
                                                                </Form.Item>
                                                            </Space>
                                                        </Form>
                                                    )}
                                                </div>

                                            </Modal>
                                        </div>
                                        <div className='px-2 py-3'>

                                            {doubtLoading ? (
                                                <div className="loaders">
                                                    <img alt='loader' src='/images/loader.gif' />
                                                </div>
                                            ) : (
                                                batchData.length > 0 ? (
                                                    batchData.map((row, index) => (

                                                        <Row key={index}>
                                                            <Col md={24}>
                                                                <div className='md:flex justify-between bg-[#F2EEEB] px-5 py-5 m-1.5' style={{ borderRadius: 10 }}>
                                                                    <div className='column-style'>
                                                                        <h4 className='text-[#F1AC12] text-base mb-2.5'>{row.service}</h4>
                                                                    </div>
                                                                    <div className='column-style'>
                                                                        <h4 className='text-[#F1AC12] text-base mb-2.5'>{row.phase}</h4>
                                                                    </div>
                                                                    <div className='column-style'>
                                                                        <h4 className='text-[#F1AC12] text-base mb-2.5'>{row.name}</h4>
                                                                    </div>
                                                                    <div className='column-style'>
                                                                        <h4 className='text-[#F1AC12] text-base mb-2.5'>{row.days}</h4>
                                                                    </div>
                                                                    <div className='column-style'>
                                                                        <h4 className='text-[#F1AC12] text-base mb-2.5'>{formatTime(row.start_time) + ' - ' + formatTime(row.end_time)}</h4>
                                                                    </div>
                                                                    <div className='flex justify-center items-center column-style-no-border'>
                                                                        <Button className='bg-[#EC9655] text-white rounded-full'>Request Pending</Button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ))
                                                ) : (
                                                    <div className="my-24">
                                                        <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                                    </div>
                                                )
                                            )}

                                        </div>


                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Content>
                )
                }
            </DashboardLayout>
            <Loader loading={ajax_loading} />
        </>
    )
}

export default BatchRequest