import React, { useState, useEffect } from 'react';
import { Modal, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { api_url } from '../components/constants';


const CommunityServices = ({ show, hide }) => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [communityData, setCommunityData] = useState([{ year: "", class: "", activity: "", type: "", role: "", hours: "", outcome: "", learning: "", patent: "" }]);

    const handleAddResearch = () => {
        setCommunityData([...communityData, { year: "", class: "", activity: "", type: "", role: "", hours: "", outcome: "", learning: "", patent: "" }]);
    };

    const handleCommunityChange = (e, index) => {
        const newData = [...communityData];
        newData[index][e.target.name] = e.target.value;
        setCommunityData(newData);

    };

    const UpdateCommunityRecord = () => {
        axios.post(api_url + 'user/update_community_records', {
            user_id: user_data.id,
            data: communityData,
        })
            .then(result => {
                if (result.data.status) {

                    Swal.fire({
                        icon: 'success',
                        title: result.data.message
                    });
                    window.location.reload();
                    //navigate("/student-portal/student-profile-report")
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something went wrong'
                    });
                }
            })
    };

    const fetchCommunityData = async () => {

        await axios.post(api_url + 'user/get_community_data', {
            user_id: user_data.id,

        }).then((res) => {
            let data = res.data.community_data;
            if (data) {
                setCommunityData(JSON.parse(data));
            }

            setLoading(false)

        })
    }

    useEffect(() => {
        fetchCommunityData()

    }, []) // eslint-disable-line

    const handleCommunityRemove = (index) => {
        const newTable = [...communityData]
        newTable.splice(index, 1)
        setCommunityData(newTable)
    }

    return (
        <>
            {loading ? (
                <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
            ) : (
                <Modal
                    title="Community services"
                    open={show}
                    onCancel={hide}
                    onOk={() => {
                        UpdateCommunityRecord();
                        hide();
                    }
                    }
                    okText='Save'
                    width={1200}
                    cancelText='cancel'
                    okButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                    cancelButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                >
                    <form >
                        <table className='curricular-data'>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Class</th>
                                    <th>Details of Activity	</th>
                                    <th>Type</th>
                                    <th>Role</th>
                                    <th>Hours Devoted</th>
                                    <th>Final Outcome</th>
                                    <th>Your Learning</th>
                                    <th>Patent</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {communityData.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <select value={row.year}
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                name="year"
                                                onChange={(e) => handleCommunityChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="2015">2015</option>
                                                <option value="2016">2016</option>
                                                <option value="2017">2017</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.class}
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                name="class"
                                                onChange={(e) => handleCommunityChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="activity"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.activity}
                                                onChange={(e) => handleCommunityChange(e, index)}
                                            />
                                        </td>

                                        <td>
                                            <select value={row.type} name="type" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleCommunityChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="Achievement">Achievement</option>
                                                <option value="Participant">Participant</option>
                                                <option value="Honour">Honour</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.role} name="role" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleCommunityChange(e, index)} >
                                                <option value="">Select</option>
                                                <option value="Participant">Participant</option>
                                                <option value="Leader">Leader</option>
                                                <option value="Not Applicable">Not Applicable</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="hours"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.hours}
                                                onChange={(e) => handleCommunityChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="outcome"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.outcome}
                                                onChange={(e) => handleCommunityChange(e, index)}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                type="text"
                                                name="learning"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.learning}
                                                onChange={(e) => handleCommunityChange(e, index)}
                                            />
                                        </td>

                                        <td>
                                            <input
                                                type="text"
                                                name="patent"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.patent}
                                                onChange={(e) => handleCommunityChange(e, index)}
                                            />
                                        </td>

                                        <td>
                                            <Popconfirm title="Sure to delete?" onConfirm={() => handleCommunityRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                <button className='bg-transparent border-none p-0 m-0 cursor-pointer'>
                                                    <DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' />
                                                </button>
                                            </Popconfirm>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5" onClick={handleAddResearch} style={{ float: 'right' }}>
                            Add Row
                        </button>
                    </form>

                </Modal>
            )}
        </>

    )

}


export default CommunityServices
