import React from "react";
import CKEditor from "react-ckeditor-component";

const Editor = ({ content, onChange }) => {

  return (
    <CKEditor
      content={content}
      events={{
        change: (event) => {
          onChange(event.editor.getData());
        }
      }}
    />
  );
};

export default Editor;
