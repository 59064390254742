import React, { useState, useEffect } from "react";
import '../styles/dashboard.css';
import '../styles/responsive.css';
import { Col, Row, Layout } from 'antd';
import DashboardLayout from '../components/dashboard-layout';
import axios from 'axios';
import { api_url, reportPath } from '../components/constants';
const { Content } = Layout;


const DiagnosticSummary = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [diagnostic, setDiagnostic] = useState(null);



    useEffect(() => {
        const fetchReportData = async () => {
            await axios.post(api_url + 'ajax/get_user_report', {
                user_id: user_data.id,

            }).then((res) => {
                setDiagnostic(res.data.data.diagnostic_report);
                setLoading(false);

            })
        }
        fetchReportData()
    }, [user_data.id])

    return (
        <>
            <DashboardLayout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <Row gutter={30}>

                        <Col md={24}>

                            <div className="form-layout form-space">
                                <div>
                                    <h1 className="bg-[#0F4275] text-[#fff] text-2xl flex justify-center text-center py-2.5 uppercase">Student Diagnostic Report</h1>
                                </div>
                                <div className="bg-[#F5F5F5] p-7">

                                    <div className="md:flex justify-center my-10">
                                        <div>
                                            <img src='/images/pdf-icon.png' alt="pdf" className="pl-5" />
                                            <p className="text-center pt-2.5 text-[#114D89] text-lg font-medium">Diagnostic Report</p>
                                        </div>

                                        <div className="ml-16">
                                            {loading ? (
                                                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                                            ) : (
                                                <div className="ml-16">
                                                    <a href={reportPath + diagnostic} target="_blank"
                                                        rel="noreferrer"
                                                        className="text-lg text-white bg-[#F0AA0C] py-1.5 px-10 flex justify-center mr-2.5"
                                                        style={{ height: 'auto', borderRadius: 20 }}
                                                    >
                                                        View Report
                                                    </a>
                                                    {/* <a href={reportPath + psychrometric} className="text-lg text-[#114D89] bg-[#CBC9C3] py-1.5 px-5 flex justify-center mt-5"
                                                        style={{ height: 'auto', borderRadius: 20 }} download>
                                                        Download Report
                                                    </a> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>

                </Content>

            </DashboardLayout>
        </>
    )
}

export default DiagnosticSummary