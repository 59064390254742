import React, { useState } from "react";
import { Button, Col, Row, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { api_url } from '../components/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Login = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [form] = Form.useForm()

  const login_user = JSON.parse(localStorage.getItem('user'));
  if (login_user) {
    const user_data = login_user.user;

    if (user_data) {
      if (user_data.userType === "Student") {
        window.location.href = "/student-dashboard/dashboard";
      } else {
        if (user_data.userType === "Counsellor") {
          window.location.href = "/counsellor-dashboard/dashboard-home";
        } else if (user_data.userType === "Faculty") {
          window.location.href = "/teacher-dashboard/dashboard";
        }
      }
    }
  }

  const handleApi = () => {
    axios.post(api_url + 'auth/login', {
      email: email,
      password: password
    })
      .then(result => {

        if (result.data.status) {
          let userLogin = { "token": result.data.access_token, "user": result.data.user };
          localStorage.setItem('user', JSON.stringify(userLogin));

          if (result.data.user.userType === "Student") {
            navigate("/student-dashboard/dashboard")
          }
          else {
            if (result.data.user.userType === "Counsellor") {
              navigate("/counsellor-dashboard/dashboard-home")
            } else if (result.data.user.userType === "Faculty") {
              navigate("/teacher-dashboard/dashboard")
            }
            else {
              navigate("/student-dashboard/dashboard")
            }
          }

        } else {
          toast.error(result.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      })
  }

  return (
    <>
      <ToastContainer />
      <img className="wave" src="images/wave.png" alt='wave-img' />
      <div className="login">
        <div className="login-container md:mx-5">
          <Row gutter={40}>
            <Col md={12} xs={24}>
              <img src="images/allen-global-logo.png" style={{ width: 200 }} alt='allen-global-logo' className="for-mobile" />
              <h1 className="text-[#0F4275] text-2xl text-center font-medium mb-0">Login Portal</h1>
              <p className="text-[#8A8181] text-lg text-center font-light"><b style={{ color: '#000000', fontWeight: '500' }}>Think Study Abroad, Think ALLEN Global</b></p>
              <img src="images/login-img.png" alt='login-img' className="mb-5 md:mb-0" />
            </Col>
            <Col md={12} xs={24}>
              <Col span={24}>
                <div className="mb-7 flex justify-center">
                  <img src="images/allen-global-logo.png" style={{ width: 200 }} alt='allen-global-logo' className="for-web" />
                </div>
              </Col>
              <Form
                name="normal_login"
                form={form}
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                //onFinish={onFinish}

                onFinishFailed={(errorInfo) => console.log('validation-error:', errorInfo)}
              >
                <Col md={24} xs={24} lg={22}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your username !',
                      },
                    ]}
                  >
                    <Input suffix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                      type="text"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} xs={24} lg={22}>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Password!',
                      },
                    ]}
                  >
                    <Input
                      suffix={<LockOutlined
                        className="site-form-item-icon" />}
                      type="password"
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} xs={22}>
                  <Form.Item>
                    <Form.Item name="checkbox" valuePropName="checked" noStyle>
                      <Checkbox className="text-sm text-[#808080]">Keep me logged in</Checkbox>
                    </Form.Item>

                    {/* <a className="login-form-forgot text-[#F0AA0C] md:pl-2.5 pl-7 lg:pl-24" href="">
                      Forgot password?
                    </a> */}
                  </Form.Item>
                </Col>
                <Col md={24} xs={24} lg={22}>
                  <Form.Item>
                    <Button type="primary"
                      onClick={handleApi}
                      // to={"/student-portal/general-enquiry"}
                      htmlType="submit"
                      className="login-btn text-xl text-[#0D3761] bg-[#FCC222]"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Col>
          </Row>

        </div>
      </div>
    </>
  )

}

export default Login