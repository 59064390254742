import React from "react";
import { Form } from "antd";
import TopBanner from '../components/top-banner';
import EnquiryForm from '../student-portal/general-enquiry';
import IntelligenceQuotient from '../student-portal/general-intelligence-quotient';
import StudentProfile from '../student-portal/student-profile-report';
import PyschometricReport from '../student-portal/psychometric-report';



const StepForm = () => {
    const [stepForm] = Form.useForm();
    // const onFinish = (fieldsValue) => {
    //     const formData = stepForm.getFieldsValue();

    //     // POST the data to backend and show Notification
    //     //console.log(formData);
    // };
    const items = [
        {
            step: 1,
            title: "GEF Form",
            content: <EnquiryForm />
        },
        {
            step: 2,
            title: "GIQ Form",
            content: <IntelligenceQuotient />
        },
        {
            step: 3,
            title: "Profile Format",
            content: <StudentProfile />
        },
        {
            step: 4,
            title: "Psychometric Report",
            content: <PyschometricReport />
        },
    ];
    return (
        <>
            <Form form={stepForm} >
                <TopBanner items={items} />
            </Form>
        </>
    )

}

export default StepForm