import React, { useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import DashboardLayout from '../components/dashboard-layout';
import { api_url } from '../components/constants';
import Swal from 'sweetalert2';


const Application = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    useEffect(() => {
        const fetchApplicationData = async () => {
            try {
                const res = await axios.post(api_url + 'application_login', {
                    user_id: user_data.id,
                });

                if (res.data.status) {
                    const newWindow = window.open(res.data.url, '_blank');
                    if (newWindow) {
                        newWindow.focus();
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "You are not authorized.",
                    }).then(() => {
                        window.location.href = '/student-dashboard/dashboard';
                    });
                }
            } catch (error) {
                console.error("Error fetching application data:", error);
            }
        };

        fetchApplicationData();
    }, [user_data.id]);


    return (
        <DashboardLayout>

        </DashboardLayout>
    )
}

export default Application