import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout, Form, Modal, Spin } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
const { Search } = Input;
const { Content } = Layout;

const PBSList = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [loadingPBS, setPBSLoading] = useState(true); // eslint-disable-line
    const [loadingTime, setTimeLoading] = useState(true);
    const [pbsList, setPBSList] = useState([]);
    const [assignModal, setAssignModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [timeSlot, settimeSlot] = useState(null);
    const [counsellorList, setCounsellorList] = useState(null); // eslint-disable-line
    const [pbsData, setPBSData] = useState({ user_id: "", counsellor_id: "", id: "", date: "", time: "", link: "" }); // eslint-disable-line
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const handleDateChange = (e) => {
        const newDate = new Date(e);
        setStartDate(newDate);
    };

    const fetchAllPBSList = async () => {

        await axios.post(api_url + 'ajax/get_all_pbs_list', {
            user_id: user_data.id,
        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const st_list = res.data.pbs_list;

            const k = st_list.map((item, index) =>
            ({
                'sno': index + 1,
                'username': decrypt(item.username, encriptionKey),
                'mentor': (item.mentor) ? decrypt(item.mentor, encriptionKey) : "",
                'class': decrypt(item.class, encriptionKey),
                'course': decrypt(item.course, encriptionKey),
                'center': item.center,
                'date': item.date + ' ' + item.time,
                'status': item.status,
                'pbs_id': item.id,
                'user_id': item.user_id,
                'link': item.link,
                'counsellor_id': item.counsellor_id,
            })
            );

            setPBSList(k);
            setLoading(false)

        })
    }
    useEffect(() => {
        fetchAllPBSList()
        getTimeSlot()
    }, []) // eslint-disable-line  

    const getTimeSlot = async () => {

        await axios.post(api_url + 'pbs-time-slot', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const time_slot = res.data.data;

            settimeSlot(time_slot);
            setTimeLoading(false);
        })

    }


    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
            align: 'center'

        },
        {
            key: "2",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "3",
            title: "User",
            dataIndex: "username",
            align: 'center'
        },

        {
            key: "4",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "5",
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            key: "6",
            title: "Actions",
            align: 'center',
            render: (record) => {
                return (
                    <>

                        <Space>
                            {record.status === 'Complete' ? (
                                <>
                                    {/* <Button onClick={() => { onPBSFeedback(record);}} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Enter Feedback</Button> */}
                                    <Button onClick={() => { onViewPBSFeedback(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>PBS Timeline</Button>
                                </>
                            ) : ('-')}

                        </Space>

                    </>
                );
            },
        },
    ];

    const onViewPBSFeedback = (record) => {

        const user_id = encrypt(record.user_id.toString(), encriptionKey);
        navigate("/counsellor-dashboard/pbs-timeline/" + user_id)
    };

    const searchItems = (searchValue) => {

        setSearchInput(searchValue)
        if (searchInput !== '') {
            const filteredData = pbsList.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilteredResults(filteredData)
        }
        else {
            setFilteredResults(pbsList)
        }
    }


    const onFinish = (values) => {
        axios.post(api_url + 'counsellor/assign_pbs_mentor', {
            counsellor_id: user_data.id,
            data: values,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => { // eslint-disable-line
                        navigate("/counsellor-dashboard/pbs-list")
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
    }

    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mx-0 mt-0">
                            <Row>
                                <Col md={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5">
                                        <h1 className="text-white text-2xl ">All PBS Session</h1>
                                    </div>
                                </Col>
                            </Row>

                            <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>

                                <Row>

                                    <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                        <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                                    </Col>
                                    <Col md={24}>
                                        {searchInput.length > 1 ? (
                                            <Table className="record" bordered columns={columns} dataSource={filteredResults} />
                                        ) : (
                                            <Table className="record" bordered columns={columns} dataSource={pbsList} />
                                        )}

                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <Modal width={700} title="Assign Counsellor"
                            open={assignModal}
                            footer={null}
                            onCancel={() => setAssignModal(false)}>
                            {loadingPBS ? (<Spin />) : (<Form onFinish={onFinish}>
                                {pbsData.time}
                                <Row gutter={30}>

                                    <Col md={12}>
                                        <Form.Item style={{ width: '100%' }} name="date" wrapperCol={{ span: 24 }} labelCol={{ span: 24 }} label="Select Date">
                                            <DatePicker style={{ width: '100%' }} dateFormat="yyyy-MM-dd" selected={startDate} onChange={(e) => handleDateChange(e)} />
                                        </Form.Item>

                                    </Col>

                                    <Col md={12}>
                                        <Form.Item
                                            name="time"
                                            label="Time"
                                            wrapperCol={{ span: 24 }}
                                            labelCol={{ span: 24 }}
                                        >
                                            {loadingTime ? (
                                                <Spin />
                                            ) : (

                                                <select
                                                    value={pbsData.time}
                                                    name="time"
                                                    width={100}
                                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                                    placeholder="Select"
                                                >

                                                    {timeSlot.map((element, index) => {

                                                        return (
                                                            <option key={element.id} value={element.id}>
                                                                {element.slot}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="counsellor"
                                            label="Counsellor"
                                            wrapperCol={{ span: 24 }}
                                            labelCol={{ span: 24 }}
                                        >
                                            {loadingTime ? (
                                                <Spin />
                                            ) : (

                                                <select
                                                    value={pbsData.counsellor_id}
                                                    name="counsellor"
                                                    width={100}
                                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                                    placeholder="Select"
                                                >
                                                    {counsellorList.map((element) => {
                                                        return (
                                                            <option key={element.id} value={element.id}>
                                                                {decrypt(element.name, encriptionKey)}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            name="link"
                                            label="Meeting Link"
                                            wrapperCol={{ span: 24 }}
                                            labelCol={{ span: 24 }}
                                            initialValue={pbsData.link}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button type="primary"

                                            htmlType="submit"
                                            className="text-sm text-white bg-[#0C3762] py-1.5 px-7 flex justify-center"
                                            style={{ height: 'auto', border: '1px solid #EEE6E6', borderRadius: 5 }}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>)}

                        </Modal>


                    </Content>
                )}
            </CounsellorLayout>
        </>
    )
}

export default PBSList