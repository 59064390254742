import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Layout, Modal, Space } from 'antd';
import '../styles/dashboard.css';
import '../styles/responsive.css';
import DashboardLayout from '../components/dashboard-layout';
import Loader from '../components/loader';
import { ClockCircleOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { api_url } from '../components/constants';
import { useNavigate } from "react-router-dom";
const { Content } = Layout;


const Dashboard = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [ajax_loading, setAjaxLoading] = useState(false);
    const [userSlotloading, setUserSlotLoading] = useState(true);
    const navigate = useNavigate();
    const [classModalVisible, setClassModalVisible] = useState(false);
    const [timeSlot, settimeSlot] = useState({});
    const [slotSet, setSlot] = useState(true); // eslint-disable-line
    const [events, setEvents] = useState([
        { id: "", date: "", time: "", status: "", event: "", user: "", user_id: "" },
    ]);
    const [form] = Form.useForm();

    const [selectedBatchIds, setSelectedBatchIds] = useState({});

    const fetchPBSData = async () => {
        await axios.post(api_url + 'user/get_event_data', {
            user_id: user_data.id,
            type: 'Student'

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.data;

            setEvents(pbs_data);
            setLoading(false)

        })
    }
    const fetchUserSlot = async () => {
        await axios.post(api_url + 'user/get_my_service', {
            user_id: user_data.id,

        }).then((res) => {

            if (res.data.time_slot) {

                const time_slot = res.data.time_slot;

                if (res.data.count > 0) {
                    if (Array.isArray(res.data.batch_count) && res.data.batch_count.length > 0) {
                        setClassModalVisible(false);
                    } else {
                        setClassModalVisible(true);
                    }

                    settimeSlot(time_slot);
                    setUserSlotLoading(true);
                } else {

                    setUserSlotLoading(false)
                }
            } else {
                setUserSlotLoading(false)
                setSlot(false);
            }

        })
    }
    useEffect(() => {
        fetchUserSlot()
        fetchPBSData()
    }, []) // eslint-disable-line


    const onFinish = () => {
        setAjaxLoading(true);
        axios.post(api_url + 'submit_user_slot', {
            batch_id: Object.values(selectedBatchIds),
            user_id: user_data.id,
        })
            .then(result => {
                setAjaxLoading(false);
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => { // eslint-disable-line
                        setClassModalVisible(false);
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })

    };

    const setEditHandle = (value) => {
        if (value === "Live Class") {
            navigate("/student-dashboard/my-classes-new");
        } else {
            navigate("/student-dashboard/pbs-session");
        }
    };

    // Utility function to format date (e.g., "2022-06-30" to "June 30, 2022")
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

    // Utility function to format time (e.g., "09:00:00" to "9:00 AM")
    const formatTime = (timeString) => {
        const time = new Date('1970-01-01T' + timeString);
        return time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    };

    const handleBatchChange = (service, phase, batchId) => {
        setSelectedBatchIds(prevState => ({
            ...prevState,
            [service]: batchId
        }));
    };

    const handleReset = () => {
        setSelectedBatchIds({});
    };

    return (
        <>

            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        {/* {loading ? (
                            <div className="loaders"><div class="hourglass"></div></div>
                        ) : ( */}
                        <Row gutter={30}>

                            <Col md={24} xs={24}>
                                <Row>
                                    <Col md={24} xs={24}>
                                        <div className="flex justify-between deshboard-top">
                                            <div className="flex py-2.5 px-5" style={{ boxShadow: '0px 1px 8px #00000029', borderRadius: 10 }}>
                                                <img src='/images/calendar-icon.png' alt='calendar-icon' />
                                                {/* <h1 className="text-[#8A8A8A] text-base pl-5">{ date +'-'+month+'-'+year }</h1> */}
                                                <h1 className="text-[#8A8A8A] text-base pl-5">All Notifications</h1>
                                            </div>
                                            {userSlotloading && (
                                                <div className="btn-outer">
                                                    <Button type="primary"
                                                        className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                        style={{ height: 'auto', borderRadius: 20, float: 'right' }}
                                                        onClick={() => setClassModalVisible(true)}  > Book Class Slot</Button>
                                                </div>
                                            )}
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        {classModalVisible && (
                                            <Modal title="Select your Class Slot"
                                                open={classModalVisible}
                                                onOk={() => setClassModalVisible(false)}
                                                onCancel={() => setClassModalVisible(false)}
                                                footer={null}
                                                maskClosable={false}
                                                width={900}
                                            >
                                                <div>
                                                    {loading ? (
                                                        <p>Loading...</p>
                                                    ) : (
                                                        <Form form={form} onFinish={onFinish}>
                                                            <table className="class-slot responsive-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Service</th>
                                                                        <th>Phase</th>
                                                                        <th>Batch</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Object.keys(timeSlot).map(service => (
                                                                        Object.keys(timeSlot[service]).map((phase, phaseIndex) => (
                                                                            timeSlot[service][phase].map((batch, batchIndex) => (
                                                                                <tr key={`${service}_${phase}_${batch.batch_id}`}>
                                                                                    {phaseIndex === 0 && batchIndex === 0 && (
                                                                                        <td align='center' rowSpan={Object.values(timeSlot[service]).flat().length}>{service}</td>
                                                                                    )}
                                                                                    {batchIndex === 0 && (
                                                                                        <td align='center' rowSpan={timeSlot[service][phase].length}>
                                                                                            <strong>{phase}</strong> <br />
                                                                                            {formatDate(batch.start_date) + ' - ' + formatDate(batch.end_date)}
                                                                                        </td>
                                                                                    )}
                                                                                    <td>
                                                                                        <Form.Item
                                                                                            key={batch.batch_id}
                                                                                            name={service}
                                                                                        >
                                                                                            <input
                                                                                                type="radio"
                                                                                                name={service}
                                                                                                id={`${service}_${phase}_${batch.batch_id}`}
                                                                                                value={batch.batch_id}
                                                                                                className="checkbox-radio"
                                                                                                onChange={() => handleBatchChange(service, phase, batch.batch_id)}
                                                                                                checked={selectedBatchIds[service] === batch.batch_id}
                                                                                            />
                                                                                            <label htmlFor={`${service}_${phase}_${batch.batch_id}`}></label>
                                                                                            {formatTime(batch.start_time) + ' - ' + formatTime(batch.end_time)} {batch.days}
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                </tr>

                                                                            ))
                                                                        ))
                                                                    ))}
                                                                </tbody>
                                                            </table>

                                                            <Space className="flex justify-center items-center">
                                                                <Form.Item >
                                                                    <Button type="primary" htmlType="submit" className="bg-[#F8B133] ">
                                                                        Submit
                                                                    </Button>
                                                                </Form.Item>
                                                                <Form.Item >
                                                                    <Button type="primary" onClick={handleReset} className="bg-[#F8B133]">
                                                                        Reset
                                                                    </Button>
                                                                </Form.Item>
                                                            </Space>
                                                        </Form>
                                                    )}
                                                </div>

                                            </Modal>
                                        )}
                                    </Col>
                                </Row>

                                <div className="bg-[#F6F6F6] mt-7 px-7 py-7">
                                    {events.length === 0 ? (
                                        <div className="flex justify-center items-center">
                                            <div className="flex py-5 px-32 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                                                <span className="text-[#F8A30C] text-xl text-center">No notification found.</span>
                                            </div>
                                        </div>
                                    ) : (
                                        events.map((value, k) => (
                                            /* eslint-disable jsx-a11y/anchor-is-valid */
                                            <a onClick={() => setEditHandle(value.event)}
                                                className="xl:flex justify-between p-5 my-2.5 sm:pb-7 bg-white" style={{ borderRadius: 25 }}>
                                                <div className="md:flex">
                                                    <div className="bg-[#FFEFCC] p-2.5" style={{ borderRadius: 2, display: 'flex', flexShrink: 0 }}>
                                                        <img alt="counselling" src='/images/counselling.png' />
                                                    </div>
                                                    <div className="pl-2.5">
                                                        {value.event === "Live Class" ? (
                                                            <h3 className="text-[#8A8A8A] text-base">Live Classes</h3>
                                                        ) : (
                                                            <h3 className="text-[#8A8A8A] text-base">PBS Session</h3>
                                                        )}

                                                        {value.event === "Live Class" ? (
                                                            <span className="text-[#F8A30C] text-sm">Your Class Schedule on {value.date} {value.time}</span>
                                                        ) : (
                                                            <span className="text-[#F8A30C] text-sm">Your PBS Session Schedule on {value.date}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                {value.event !== "Live Class" ? (
                                                    <h4 className="text-[#0F4275] text-sm flex items-center ml-0 lg:ml-14 xl:ml-0 sm:float-right"><ClockCircleOutlined className="pr-2.5" />Time : {value.time}</h4>
                                                ) : ('')}
                                            </a>
                                            /* eslint-enable jsx-a11y/anchor-is-valid */
                                        ))
                                    )}
                                </div>
                            </Col>
                        </Row>
                        {/* )} */}
                    </Content>
                )}
            </DashboardLayout>
            <Loader loading={ajax_loading} />

        </>
    )
}

export default Dashboard