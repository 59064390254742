import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Col, Row, Form, Layout, Tabs, Collapse, Timeline } from 'antd';
import CounsellorLayout from "./counsellor-layout";
import { DownOutlined } from '@ant-design/icons';
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { useParams } from 'react-router-dom'
import { api_url, studentPath, reportPath } from '../components/constants';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
const { Panel } = Collapse
const { Content } = Layout;

const StudentInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [studentData, setStudentData] = useState({
        name: '', form_number: '', course_name: '', profile_picture: '', batch: '', dob: '', class: '', school: '', address: '', father_name: '', mother_name: '', father_occupation: '', mother_occupation: '',
    })
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }


    useEffect(() => {
        const fetchStudentData = async () => {

            await axios.post(api_url + 'user/get_profile_data', {
                user_id: id

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }
                const user_info = [res.data.user_info];

                const stArray = user_info.map(item => ({
                    'user_id': item.user_id,
                    'name': decrypt(item.name, encriptionKey),
                    'email': decrypt(item.email, encriptionKey),
                    'form_number': decrypt(item.form_number, encriptionKey),
                    'mobile': decrypt(item.mobile, encriptionKey),
                    'mother_name': decrypt(item.mother_name, encriptionKey),
                    'father_name': decrypt(item.father_name, encriptionKey),
                    'dob': decrypt(item.dob, encriptionKey),
                    'city': decrypt(item.city, encriptionKey),
                    'psychrometric_report': item.psychrometric_report,
                    'batch': item.batch,
                    'father_occupation': item.father_occupation,
                    'mother_occupation': item.mother_occupation,
                    'diagnostic_report': item.diagnostic_report,
                    'admission_date': item.admission_date,
                    'address': item.address,
                    'class': decrypt(item.class, encriptionKey),
                    'course': decrypt(item.course, encriptionKey),
                    'school': item.school,
                    'photo': item.photo,

                }));
                setStudentData(stArray[0]);
                setLoading(false)

            })
        }
        fetchStudentData()
    }, [id])

    const panelStyle = {
        marginBottom: 24,
        border: 'none',
        background: '#E3E3E3',
        boxShadow: '0px 3px 3px #00000029',
        borderRadius: 5,
        color: '#fff'
    };
    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mx-0 my-7 form-layout">
                            <Row>
                                <Col md={24} xs={24}>
                                    <h1 className="bg-[#0f4275] text-white py-2.5 px-5 text-xl font-medium">Student Information</h1>

                                </Col>
                            </Row>
                            <div className="mx-7 my-10">
                                <Row gutter={30}>
                                    <Col md={6}>
                                        <div className="profile-pic">
                                            {studentData.photo === null || studentData.photo === "" ? (
                                                <img src='/images/avatar.png' className="profile-pic flex justify-start items-center" alt="user avatar" />
                                            ) : (
                                                <img src={studentPath + studentData.photo} className="profile-pic flex justify-start items-center" alt="user avatar" />
                                            )}
                                        </div>
                                    </Col>
                                    <Col md={18}>
                                        <Row gutter={30}>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Form Number :</strong> {studentData.form_number}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Admission Date :</strong> {studentData.admission_date}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Center Name :</strong> {studentData.city}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Name :</strong> {studentData.name}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Mobile :</strong> {studentData.mobile}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Email :</strong> {studentData.email}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>DOB :</strong> {studentData.dob}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Course :</strong> {studentData.course}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Class :</strong> {studentData.class}</p>
                                            </Col>

                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Father's Name :</strong> {studentData.father_name}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb=5 text-base text-[#6c757d]"><strong>Mother's Name :</strong> {studentData.mother_name}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Father's Occupation :</strong> {studentData.father_occupation}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Mother's Occupation :</strong> {studentData.mother_occupation}</p>
                                            </Col>
                                            <Col md={8}>
                                                <p className="mb-5 text-base text-[#6c757d]"><strong>Address :</strong> {studentData.address}</p>
                                            </Col>
                                        </Row>

                                        <div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <Row>
                                <Col md={24} xs={24} className='md:px-5 px-2.5'>
                                    <Collapse
                                        className="information"
                                        //defaultActiveKey={['1']}
                                        bordered={false}
                                        expandIconPosition='end'
                                        style={{ marginTop: "-1vh", }}
                                        expandIcon={({ isActive }) => (
                                            <DownOutlined
                                                style={{ color: '#A2A1A1', paddingTop: 10, }}
                                                rotate={isActive ? 180 : 0}
                                            />
                                        )}
                                    >
                                        <Panel header="GENERAL ENQUIRY FORM" key="1" style={panelStyle}>
                                            <GefInfo />
                                        </Panel>
                                        <Panel header="GENERAL INTROSPECTION QUESTIONNAIRE" key="2" style={panelStyle}>
                                            <GiqInfo />
                                        </Panel>
                                        <Panel header="PROFILE FORMAT" key="3" style={panelStyle}>
                                            <ProfileInfo />
                                        </Panel>
                                        <Panel header="TEST SCORE RECORDS" key="4" style={panelStyle}>
                                            <TestInfo />
                                        </Panel>
                                        {studentData.class === "9" ? (<Panel header="PSYCHOMETRIC FORMAT" key="5" style={panelStyle}>
                                            <Psychometric />
                                        </Panel>) : ('')}

                                        <Panel header="ALL PBS SESSION" key="9" style={panelStyle}>
                                            <PbsSession user_id={id} />
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                )
                }
            </CounsellorLayout>

        </>
    )
}

const GefInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const [studentData, setStudentData] = useState(
        {
            name: '', email: '', form_number: '', city: '', school: '', father_name: '', mother_name: '', parent_mobile: '', mother_occupation: '', father_occupation: '', grade: '',
            grade_8: '', grade_9: '', grade_10: '', grade_11: '', grade_12: '', sat: '', act: '', toefl: '', ielts: '', sat_ap: '', scholarships: '', hearAbout: '', allen_number: '', mobile: '', address: '',
            university: '', intended: '',
        }
    )

    useEffect(() => {
        const fetchGEFData = async () => {

            await axios.post(api_url + 'get_gef_form_data', {
                user_id: id

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const user_info = [res.data.data];

                const stArray = user_info.map(item => ({
                    'user_id': item.user_id,
                    'name': decrypt(item.name, encriptionKey),
                    'email': decrypt(item.email, encriptionKey),
                    'form_number': decrypt(item.form_number, encriptionKey),
                    'mobile': decrypt(item.mobile, encriptionKey),
                    'mother_name': decrypt(item.mother_name, encriptionKey),
                    'father_name': decrypt(item.father_name, encriptionKey),
                    'dob': decrypt(item.dob, encriptionKey),
                    'city': decrypt(item.city, encriptionKey),
                    'psychrometric_report': item.psychrometric_report,
                    'batch': item.batch,
                    'father_occupation': item.father_occupation,
                    'mother_occupation': item.mother_occupation,
                    'diagnostic_report': item.diagnostic_report,
                    'admission_date': item.admission_date,
                    'address': item.address,
                    'class': decrypt(item.class, encriptionKey),
                    'course_name': decrypt(item.course, encriptionKey),
                    'school': item.school,
                    'photo': item.photo,
                    'allen_number': item.allen_number,
                    'grade_8': item.grade_8,
                    'grade_9': item.grade_9,
                    'grade_10': item.grade_10,
                    'grade_11': item.grade_11,
                    'grade_12': item.grade_12,
                    'sat': item.sat,
                    'act': item.act,
                    'ielts': item.ielts,
                    'toefl': item.toefl,
                    'sat_ap': item.sat_ap,
                    'scholarships': item.scholarships,
                    'hearAbout': item.hearAbout,
                    'university': item.university,
                    'intended': item.intended,
                    'parent_mobile': item.parent_mobile,
                }));

                setStudentData(stArray[0]);
                setLoading(false)

            })
        }
        fetchGEFData()
    }, [id])

    return (
        <Form
            name="normal_login"
            className="mx-0 py-5 px-2.5"
        >
            {loading ? (
                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
            ) : (

                <Row gutter={30} style={{ marginBottom: 20 }}>
                    <Col md={24}>
                        <Row gutter={30}>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Form Number :</strong> {studentData.form_number}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Admission Date :</strong> {studentData.admission_date}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Center Name :</strong> {studentData.city}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Name :</strong> {studentData.name}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Mobile :</strong> {studentData.mobile}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Email :</strong> {studentData.email}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>DOB :</strong> {studentData.dob}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Course :</strong> {studentData.course}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Class :</strong> {studentData.class}</p>
                            </Col>

                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Father's Name :</strong> {studentData.father_name}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb=5 text-base text-[#6c757d]"><strong>Mother's Name :</strong> {studentData.mother_name}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Father's Occupation :</strong> {studentData.father_occupation}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Mother's Occupation :</strong> {studentData.mother_occupation}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Address :</strong> {studentData.address}</p>
                            </Col>
                            {studentData.grade_9 && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Grade 9 :</strong> {studentData.grade_9}</p>
                                </Col>
                            )}
                            {studentData.grade_10 && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Grade 10 :</strong> {studentData.grade_10}</p>
                                </Col>
                            )}

                            {studentData.grade_11 && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Grade 11 :</strong> {studentData.grade_11}</p>
                                </Col>
                            )}
                            {studentData.grade_12 && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>Grade 12 :</strong> {studentData.grade_12}</p>
                                </Col>
                            )}
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Target Universities :</strong> {studentData.university}</p>
                            </Col>
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Intended Major (s) :</strong> {studentData.intended}</p>
                            </Col>
                            {studentData.sat && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>SAT Score :</strong> {studentData.sat}</p>
                                </Col>
                            )}
                            {studentData.act && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>ACT Score  :</strong> {studentData.act}</p>
                                </Col>
                            )}
                            {studentData.toefl && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>TOEFL Score  :</strong> {studentData.toefl}</p>
                                </Col>
                            )}
                            {studentData.ielts && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>IELTS Score  :</strong> {studentData.ielts}</p>
                                </Col>
                            )}
                            {studentData.sat_ap && (
                                <Col md={8} xs={24}>
                                    <p className="mb-5 text-base text-[#6c757d]"><strong>SAT/AP Score  :</strong> {studentData.sat_ap}</p>
                                </Col>
                            )}
                            <Col md={8} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Where/How/When did you hear about AGSD :</strong> {studentData.hearAbout}</p>
                            </Col>
                            <Col md={24} xs={24}>
                                <p className="mb-5 text-base text-[#6c757d]"><strong>Will you be able to afford your studies abroad if scholarships are not provided </strong> </p>
                                <p>{studentData.scholarships}</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Form>
    )
}

const Psychometric = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const [studentData, setStudentData] = useState(
        {
            psychrometric_report: "", name: '', email: '', form_number: '', city: '', school: '', father_name: '', mother_name: '', parent_mobile: '', mother_occupation: '', father_occupation: '', grade: '',
            grade_8: '', grade_9: '', grade_10: '', grade_11: '', grade_12: '', sat: '', act: '', toefl: '', ielts: '', sat_ap: '', scholarships: '', hearAbout: '', allen_number: '', mobile: '', address: '',
            university: '', intended: '',
        }
    )

    useEffect(() => {
        const fetchGEFData = async () => {

            await axios.post(api_url + 'get_gef_form_data', {
                user_id: id

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const user_info = [res.data.data];

                const stArray = user_info.map(item => ({
                    'user_id': item.user_id,
                    'name': decrypt(item.name, encriptionKey),
                    'email': decrypt(item.email, encriptionKey),
                    'form_number': decrypt(item.form_number, encriptionKey),
                    'mobile': decrypt(item.mobile, encriptionKey),
                    'mother_name': decrypt(item.mother_name, encriptionKey),
                    'father_name': decrypt(item.father_name, encriptionKey),
                    'dob': decrypt(item.dob, encriptionKey),
                    'city': decrypt(item.city, encriptionKey),
                    'psychrometric_report': item.psychrometric_report,
                    'batch': item.batch,
                    'father_occupation': item.father_occupation,
                    'mother_occupation': item.mother_occupation,
                    'diagnostic_report': item.diagnostic_report,
                    'admission_date': item.admission_date,
                    'address': item.address,
                    'class': decrypt(item.class, encriptionKey),
                    'course_name': decrypt(item.course, encriptionKey),
                    'school': item.school,
                    'photo': item.photo,
                    'allen_number': item.allen_number,
                    'grade_8': item.grade_8,
                    'grade_9': item.grade_9,
                    'grade_10': item.grade_10,
                    'grade_11': item.grade_11,
                    'grade_12': item.grade_12,
                    'sat': item.sat,
                    'act': item.act,
                    'ielts': item.ielts,
                    'toefl': item.toefl,
                    'sat_ap': item.sat_ap,
                    'scholarships': item.scholarships,
                    'hearAbout': item.hearAbout,
                    'university': item.university,
                    'intended': item.intended,
                    'parent_mobile': item.parent_mobile,
                }));

                setStudentData(stArray[0]);
                setLoading(false)

            })
        }
        fetchGEFData()

    }, [id])

    return (
        <Form
            name="normal_login"
            className="mx-7 p-5"


        >
            <Row gutter={30} style={{ marginBottom: 20 }}>
                <Col md={24}>
                    <Row gutter={30}>
                        <Col md={24}>
                            <div className="bg-[#F5F5F5] p-7">

                                <div className="md:flex justify-center my-10">
                                    <div>
                                        <img src='/images/pdf-icon.png' alt="pdf" className="pl-5" />
                                        <p className="text-center pt-2.5 text-[#114D89] text-lg font-medium">Psychometric Report</p>
                                    </div>
                                    {loading ? (
                                        <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                                    ) : (
                                        <div className="ml-16">
                                            <a href={reportPath + studentData.psychrometric_report} target="_blank" rel="noreferrer"
                                                className="text-lg text-white bg-[#F0AA0C] py-1.5 px-10 flex justify-center mr-2.5"
                                                style={{ height: 'auto', borderRadius: 20 }}
                                            >
                                                View Report
                                            </a>

                                        </div>
                                    )}
                                </div>
                            </div>

                        </Col>

                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

const PbsSession = (props) => {

    const [loading, setLoading] = useState(false);
    const [testData, setTestData] = useState(null);
    const [academicData, setAcademicData] = useState(null);
    const [internData, setInternData] = useState(null);
    const [suggestioData, setSuggestionData] = useState(null);
    const [pbsData, setPBSData] = useState([{ id: "", date: "", time: "", status: "", mentor: "", link: "", test_records: "", academic_records: "", internship_records: "", suggestion_records: "" }]); // eslint-disable-line

    useEffect(() => {
        const pbsTimeline = () => {
            axios.post(api_url + 'student-pbs-timeline', {
                user_id: encrypt(props.user_id, encriptionKey)

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                if (res.data.pbs_data.length > 0) {

                    const st_list = res.data.pbs_data;
                    const test_target = res.data.test_target_array;
                    const academic_target = res.data.academic_target_array;
                    const st_intern_data = res.data.st_intern_array;
                    const st_suggestion_array = res.data.st_suggestion_array;
                    if (academic_target.length > 0) {
                        setAcademicData(JSON.parse(academic_target))
                    }
                    if (test_target.length > 0) {
                        setTestData(JSON.parse(test_target));
                    }
                    if (st_intern_data.length > 0) {
                        setInternData(JSON.parse(st_intern_data))
                    }
                    if (st_suggestion_array.length > 0) {
                        setSuggestionData(JSON.parse(st_suggestion_array))
                    }

                    setPBSData(st_list);
                    setLoading(false)
                }

            })
        }

        pbsTimeline()
    }, []) // eslint-disable-line

    const items = [
        {
            key: '1',
            label: `STUDENT TARGETS`,
            children: <AcademicTarget data={academicData} />,
        },
        {
            key: '2',
            label: `ACADEMICS TARGETS`,
            children: <StudentTarget data={testData} />,
        },
        {
            key: '3',
            label: `INTERNSHIP ASSISTANCE`,
            children: <InternshipAssistance data={internData} />,
        },
        {
            key: '4',
            label: `PROFILE BUILDING SUGGESTIONS`,
            children: <ProfileBuilding data={suggestioData} />,
        },
    ];
    return (
        <>
            {loading ? (
                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
            ) : (
                <div className="mx-0">
                    <Tabs defaultActiveKey="1" items={items} />
                </div>
            )}
        </>
    )
}


const StudentTarget = (props) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (


                                <Timeline.Item key={index} label={formatDate(row.date)}>
                                    <div className="bg-white md:p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Exam</th>
                                                        <th>Your Achivement</th>
                                                        <th>Assign Target</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.test_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.test}</td>
                                                            <td>{value.achive}</td>
                                                            <td>{value.assign}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>

                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>

    )
}

const AcademicTarget = (props) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={formatDate(row.date)}>
                                    <div className="bg-white md:p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Class</th>
                                                        <th>Your Achivement</th>
                                                        <th>Assign Target</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.academic_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.grade}</td>
                                                            <td>{value.achive}</td>
                                                            <td>{value.assign}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>
    )
}

const InternshipAssistance = (props) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={formatDate(row.date)}>
                                    <div className="bg-white md:p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Suggestion</th>
                                                        <th>Subject</th>
                                                        <th>Deadline</th>
                                                        <th>Remark</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.intern_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.suggestion}</td>
                                                            <td>{value.subject}</td>
                                                            <td>{formatDate(value.deadline)}</td>
                                                            <td>{value.remark}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}

                    </Timeline>
                </Col>
            </Row>
        </>

    )
}

const ProfileBuilding = (props) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>
                    <Timeline mode="left" >
                        {props.data != null ?
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={formatDate(row.date)}>
                                    <div className="bg-white md:p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span className="text-[#707070] ml-5 lg:text-lg text-base py-1 px-7 bg-[#F1F1F1]" style={{ borderRadius: 12 }}>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th width='20%'>Type</th>
                                                        <th width='30%'>Suggestion</th>
                                                        <th width='30%'>Student Output</th>
                                                        <th width='20%'>Follow Up Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {row.suggestion_data.map((value, k) => (


                                                        <tr key={k}>
                                                            <td>{value.type}</td>
                                                            <td>{value.suggestion}</td>
                                                            <td>{value.output}</td>
                                                            <td>{formatDate(value.deadline)}</td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            )) : (<div className="my-24">
                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                            </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>

    )
}

const GiqInfo = () => {
    const { id } = useParams();
    const [questions, setQuestions] = useState([{ qid: "", question: "", answer: "" }])
    const [loading, setLoading] = useState(true) // eslint-disable-line


    useEffect(() => {
        const fetchUserData = async () => {

            await axios.post(api_url + 'get_giq_form_data', {
                user_id: id,

            }).then((res) => {
                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }
                const giq_data = res.data.giq_data;
                let obj = JSON.parse(giq_data);
                //console.log(obj);
                setQuestions(obj);
                setLoading(false)
            })
        }
        fetchUserData()
    }, [id])

    return (
        <>
            <div className="mx-0">
                {questions.map((ques, index) => (
                    <div key={index}>
                        <div className='py-5'>
                            <strong className="text-[#6c757d] text-base">{ques.question}</strong>
                            <p className="text-[#6c757d] text-base">{ques.answer}</p>
                        </div>
                    </div>
                ))}

            </div>
        </>
    )
}

const ProfileInfo = () => {

    const infoItems = [
        {
            key: '1',
            label: `Academic Records`,
            children: <AcademicInfo />,
        },
        {
            key: '2',
            label: `Co-Curricular`,
            children: <CurricularInfo />,
        },
        {
            key: '3',
            label: `Research Records`,
            children: <ResearchInfo />,
        },
        {
            key: '4',
            label: `Awards/Honours`,
            children: <AwardsInfo />,
        },
        {
            key: '5',
            label: `Internships`,
            children: <InternshipsInfo />,
        },
        {
            key: '6',
            label: `Publications`,
            children: <PublicationsInfo />,
        },
        {
            key: '7',
            label: `Community Services`,
            children: <CommunityInfo />,
        },
        {
            key: '8',
            label: `School Projects`,
            children: <SchoolProjectInfo />,
        },
        {
            key: '9',
            label: `Other Activities`,
            children: <OtherInfo />,
        },
    ];
    return (
        <div className="my-5">
            <Tabs
                defaultActiveKey="1"
                type="card"
                items={infoItems}
            />
        </div>
    )
}

const AcademicInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [academicData, setAcademicData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "" }]);

    useEffect(() => {
        const fetchAcademicData = async () => {
            await axios.post(api_url + 'user/get_student_records', {
                user_id: id,
            }).then((res) => {
                let academic_data = res.data.academic_data;
                //console.log(academic_data);
                if (academic_data) {
                    setAcademicData(JSON.parse(academic_data));
                }

                setLoading(false)
            })
        }
        fetchAcademicData()
    }, [id])

    return (
        <div className="responsive-table">
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Marks</th>
                        <th>Rank</th>
                    </tr>
                </thead>
                <tbody>
                    {academicData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.marks}</td>
                                <td>{row.rank}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const CurricularInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [curricularData, setCurricularData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", learning: "" }]);

    useEffect(() => {
        const fetchCurricularData = async () => {
            await axios.post(api_url + 'user/get_student_records', {
                user_id: id,
            }).then((res) => {
                let curricular_data = res.data.curricular_data;

                if (curricular_data) {
                    let cur_records = JSON.parse(curricular_data);
                    setCurricularData(cur_records);
                }

                setLoading(false)
            })
        }
        fetchCurricularData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Marks</th>
                        <th>Learning</th>
                    </tr>
                </thead>
                <tbody>
                    {curricularData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.marks}</td>
                                <td>{row.learning}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const ResearchInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [researchData, setResearchData] = useState([{ year: "", class: "", activity: "", published: "", role: "", reference_link: "", level: "", outcome: "", learning: "", patent: "" }]);

    useEffect(() => {
        const fetchResearchData = async () => {
            await axios.post(api_url + 'user/get_student_records', {
                user_id: id,
            }).then((res) => {
                let research_data = res.data.research_data;

                if (research_data) {
                    setResearchData(JSON.parse(research_data));
                }
                setLoading(false)
            })
        }
        fetchResearchData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of project work</th>
                        <th>Your Role</th>
                        <th>Published</th>
                        <th>Level</th>
                        <th>Reference Link</th>
                        <th>Your Learning</th>
                        <th>Implementation Outcome</th>
                        <th>Patent</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {researchData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.role}</td>
                                <td>{row.published}</td>
                                <td>{row.level}</td>
                                <td>{row.reference_link}</td>
                                <td>{row.learning}</td>
                                <td>{row.outcome}</td>
                                <td>{row.patent}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={12} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const AwardsInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [awardsData, setAwardsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);

    useEffect(() => {
        const fetchAwardData = async () => {
            await axios.post(api_url + 'user/get_student_records', {
                user_id: id,
            }).then((res) => {
                let awards_data = res.data.awards_data;

                if (awards_data) {
                    setAwardsData(JSON.parse(awards_data));
                }
                setLoading(false)
            })
        }
        fetchAwardData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Remarks</th>

                    </tr>
                </thead>
                <tbody>
                    {awardsData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.rank}</td>
                                <td>{row.remarks}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const InternshipsInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [internsData, setinternsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);

    useEffect(() => {
        const fetchInternsData = async () => {
            await axios.post(api_url + 'user/get_student_records', {
                user_id: id,
            }).then((res) => {
                let interns_data = res.data.interns_data;

                if (interns_data) {
                    setinternsData(JSON.parse(interns_data));
                }
                setLoading(false)
            })
        }

        fetchInternsData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {internsData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.rank}</td>
                                <td>{row.remarks}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const PublicationsInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [publicationsData, setpublicationsData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", rank: "", remarks: "" }]);

    useEffect(() => {
        const fetchPublicationData = async () => {
            await axios.post(api_url + 'user/get_student_records', {
                user_id: id,
            }).then((res) => {
                let publication_data = res.data.publication_data;

                if (publication_data) {
                    setpublicationsData(JSON.parse(publication_data));
                }
                setLoading(false)
            })
        }
        fetchPublicationData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {publicationsData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.level}</td>
                                <td>{row.rank}</td>
                                <td>{row.remarks}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={9} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const CommunityInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [communityData, setCommunityData] = useState([{ year: "", class: "", activity: "", type: "", role: "", hours: "", outcome: "", learning: "", patent: "" }]);


    useEffect(() => {
        const fetchCommunityData = async () => {
            await axios.post(api_url + 'user/get_student_records', {
                user_id: id,
            }).then((res) => {
                let community_data = res.data.community_data;

                if (community_data) {
                    setCommunityData(JSON.parse(community_data));
                }
                setLoading(false)
            })
        }
        fetchCommunityData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>Sno</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity	</th>
                        <th>Type</th>
                        <th>Role</th>
                        <th>Hours Devoted</th>
                        <th>Final Outcome</th>
                        <th>Your Learning</th>
                        <th>Patent</th>
                    </tr>
                </thead>
                <tbody>
                    {communityData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.hours}</td>
                                <td>{row.outcome}</td>
                                <td>{row.learning}</td>
                                <td>{row.patent}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={10} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const SchoolProjectInfo = () => {
    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Level</th>
                        <th>Rank</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>01</td>
                        <td>2020</td>
                        <td>10th</td>
                        <td>-</td>
                        <td>Achivement</td>
                        <td>Participant</td>
                        <td>School</td>
                        <td>First</td>
                        <td>ddf</td>

                    </tr>
                </tbody>
            </table>
        </>
    )
}

const OtherInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [othersData, setOthersData] = useState([{ year: "", class: "", activity: "", type: "", role: "", hours: "", outcome: "", learning: "", patent: "" }]);


    useEffect(() => {
        const fetchothersActivityData = async () => {
            await axios.post(api_url + 'user/get_student_records', {
                user_id: id,
            }).then((res) => {
                let other_data = res.data.other_data;

                if (other_data) {
                    setOthersData(JSON.parse(other_data));
                }
                setLoading(false)
            })
        }

        fetchothersActivityData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Year</th>
                        <th>Class</th>
                        <th>Details of Activity</th>
                        <th>Type</th>
                        <th>Your Role</th>
                        <th>Hours Devoted</th>
                        <th>Final Outcome</th>
                        <th>Your Learning</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {othersData.map((row, index) => (
                        row.class > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.year}</td>
                                <td>{row.class}</td>
                                <td>{row.activity}</td>
                                <td>{row.type}</td>
                                <td>{row.role}</td>
                                <td>{row.hours}</td>
                                <td>{row.outcome}</td>
                                <td>{row.learning}</td>
                                <td>{row.patent}</td>
                            </tr>)
                            : (<tr>
                                <td colSpan={10} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)
                    ))}
                </tbody>
            </table>
        </>
    )
}

const TestInfo = () => {
    const examinfo = [
        {
            key: '1',
            label: `SAT`,
            children: <SatInfo />,
        },
        {
            key: '2',
            label: `ACT`,
            children: <ActInfo />,
        },
        {
            key: '3',
            label: `IELTS`,
            children: <IeltsInfo />,
        },
        {
            key: '4',
            label: `TOEFL`,
            children: <ToeflInfo />,
        },
        {
            key: '5',
            label: `Dulingo`,
            children: <DulingoInfo />,
        },
        {
            key: '6',
            label: `PTE`,
            children: <PteInfo />,
        },
        {
            key: '7',
            label: `Cambridge`,
            children: <CambridgeInfo />,
        },
    ];
    return (
        <div className="my-5">
            <Tabs
                defaultActiveKey="1"
                type="card"
                items={examinfo}
            />
        </div>
    )

}

const SatInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [satData, setSatData] = useState([{ attempt: "", score: "", maths: "", english: "" }]);


    useEffect(() => {
        const fetchSatData = async () => {
            await axios.post(api_url + 'user/get_test_data', {
                user_id: id,
            }).then((res) => {
                let sat_data = res.data.sat_data;

                if (sat_data) {
                    setSatData(JSON.parse(sat_data));
                }
                setLoading(false)
            })
        }
        fetchSatData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Attempt</th>
                        <th>Total Score</th>
                        <th>English Score</th>
                        <th>Math Score</th>
                    </tr>
                </thead>
                <tbody>

                    {satData.map((row, index) => (
                        row.score > 0 ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.attempt}</td>
                                <td>{row.score}</td>
                                <td>{row.english}</td>
                                <td>{row.maths}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={5} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

const ActInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [actData, setActData] = useState([{ attempt: "", score: "", maths: "", english: "", science: "", reading: "", writing: "" }]);


    useEffect(() => {
        const fetchActData = async () => {
            await axios.post(api_url + 'user/get_test_data', {
                user_id: id,
            }).then((res) => {
                let act_data = res.data.act_data;

                if (act_data) {
                    setActData(JSON.parse(act_data));
                }
                setLoading(false)
            })
        }
        fetchActData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Attempt</th>
                        <th>Composite Score</th>
                        <th>Math Score</th>
                        <th>Science Score</th>
                        <th>English Score</th>
                        <th>Reading Score</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {actData.map((row, index) => (
                        row.score !== "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.attempt}</td>
                                <td>{row.score}</td>
                                <td>{row.maths}</td>
                                <td>{row.science}</td>
                                <td>{row.english}</td>
                                <td>{row.reading}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={8} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

const IeltsInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [IeltsData, setIeltsData] = useState([{ over_all_score: "", listening_score: "", reading: "", writing: "" }]);


    useEffect(() => {
        const fetchIeltsData = async () => {
            await axios.post(api_url + 'user/get_test_data', {
                user_id: id,
            }).then((res) => {
                let ielts_data = res.data.ielts_data;

                if (ielts_data) {
                    setIeltsData(JSON.parse(ielts_data));
                }
                setLoading(false)
            })
        }
        fetchIeltsData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Over All Band Score</th>
                        <th>Listening Score</th>
                        <th>Reading Score</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {IeltsData.map((row, index) => (
                        row.over_all_score !== "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.listening_score}</td>
                                <td>{row.reading}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={5} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}
                </tbody>
            </table>
        </>
    )
}

const ToeflInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [ToeflData, setToeflData] = useState([{ over_all_score: "", listening_score: "", reading: "", writing: "", speaking: "" }]);

    useEffect(() => {
        const fetchToeflData = async () => {
            await axios.post(api_url + 'user/get_test_data', {
                user_id: id,
            }).then((res) => {
                let toefl_data = res.data.toefl_data;

                if (toefl_data) {
                    setToeflData(JSON.parse(toefl_data));
                }
                setLoading(false)
            })
        }

        fetchToeflData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Total Score</th>
                        <th>Listening Score</th>
                        <th>Reading Score</th>
                        <th>Speaking Score</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {ToeflData.map((row, index) => (
                        row.over_all_score !== "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.listening_score}</td>
                                <td>{row.reading}</td>
                                <td>{row.speaking}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={6} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

const DulingoInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [DulingoData, setDulingoData] = useState([{ over_all_score: "", conversation: "", comprehension: "", literacy: "", production: "" }]);

    useEffect(() => {
        const fetchDulingoData = async () => {
            await axios.post(api_url + 'user/get_test_data', {
                user_id: id,
            }).then((res) => {
                let dulingo_data = res.data.dulingo_data;

                if (dulingo_data) {
                    setDulingoData(JSON.parse(dulingo_data));
                }
                setLoading(false)
            })
        }
        fetchDulingoData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Overall Score</th>
                        <th>Conversation</th>
                        <th>Comprehension</th>
                        <th>Literacy</th>
                        <th>Production</th>
                    </tr>
                </thead>
                <tbody>

                    {DulingoData.map((row, index) => (
                        row.over_all_score !== "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.conversation}</td>
                                <td>{row.comprehension}</td>
                                <td>{row.literacy}</td>
                                <td>{row.production}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={6} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

const PteInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [PteData, setPteData] = useState([{ over_all_score: "", listening_score: "", reading: "", writing: "", speaking: "" }]);

    useEffect(() => {
        const fetchPteData = async () => {
            await axios.post(api_url + 'user/get_test_data', {
                user_id: id,
            }).then((res) => {
                let pte_data = res.data.pte_data;

                if (pte_data) {
                    setPteData(JSON.parse(pte_data));
                }
                setLoading(false)
            })
        }

        fetchPteData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Total Score</th>
                        <th>Listening Score</th>
                        <th>Reading Score</th>
                        <th>Speaking Score</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {PteData.map((row, index) => (
                        row.over_all_score !== "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.listening_score}</td>
                                <td>{row.reading}</td>
                                <td>{row.speaking}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={6} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}
                </tbody>
            </table>
        </>
    )
}

const CambridgeInfo = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [CambrigeData, setCambrigeData] = useState([{ over_all_score: "", use_english: "", reading: "", writing: "" }])


    useEffect(() => {
        const fetchCambridgeData = async () => {
            await axios.post(api_url + 'user/get_test_data', {
                user_id: id,
            }).then((res) => {
                let cambrige_data = res.data.cambrige_data;

                if (cambrige_data) {
                    setCambrigeData(JSON.parse(cambrige_data));
                }
                setLoading(false)
            })
        }
        fetchCambridgeData()
    }, [id])

    return (
        <>
            <table className='curricular'>
                <thead>
                    <tr>
                        <th>S.no</th>
                        <th>Overall Score</th>
                        <th>Reading Score</th>
                        <th>Use of English</th>
                        <th>Writing Score</th>
                    </tr>
                </thead>
                <tbody>
                    {CambrigeData.map((row, index) => (
                        row.over_all_score !== "" ? (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{row.over_all_score}</td>
                                <td>{row.reading}</td>
                                <td>{row.use_english}</td>
                                <td>{row.writing}</td>

                            </tr>)
                            : (<tr>
                                <td colSpan={5} className="text-lg text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                            </tr>)

                    ))}

                </tbody>
            </table>
        </>
    )
}

export default StudentInfo