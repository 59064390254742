import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout, Modal, Form } from "antd";
import DashboardLayout from '../components/dashboard-layout';
import { useNavigate } from 'react-router-dom'
import { api_url, studentPath } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';
const { Search } = Input;
const { Content } = Layout;

const AssessmentReoprtList = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [assessmentList, setAssessmentList] = useState([]);
    const [materialList, setMatrialList] = useState([]);
    const [studyModalVisible, setStudyModalVisible] = useState(false);
    // const [tableParams, setTableParams] = useState({
    //     pagination: {
    //         current: 1,
    //         pageSize: 10,
    //     },
    // });

    useEffect(() => {
        const fetchAssessmentData = async () => {
            try {
                const res = await axios.post(api_url + 'user/get_report_data', {
                    user_id: user_data.id,
                    type: 'Assessment'
                });

                const st_list = res.data.report_data;
                if (st_list) {
                    const k = st_list.map((item, index) => ({
                        'sno': index + 1,
                        'month': item.month,
                        'faculty': decrypt(item.faculty_name, encriptionKey),
                        'created_date': item.created_date,
                        'id': item.id,
                    }));

                    setAssessmentList(k);
                }
            } catch (error) {
                console.error("Error fetching assessment data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchAssessmentData();
        setMatrialList()
    }, [user_data.id]);

    const viewReport = (record) => {
        navigate('/student-dashboard/view-assessment/' + record.id)

    };

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },

        {
            key: "3",
            title: "Month",
            dataIndex: "month",
            align: 'center'
        },
        {
            key: "4",
            title: "Faculty",
            dataIndex: "faculty",
            align: 'center'
        },
        {
            key: "5",
            title: "Created Date",
            dataIndex: "created_date",
            align: 'center'
        },

        {
            key: "7",
            title: "Action",
            render: (record) => {
                return (
                    <>
                        <Space>
                            <Button onClick={() => {
                                viewReport(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>View Report</Button>

                        </Space>
                    </>
                );
            },
        },
    ];


    return (
        <DashboardLayout>
            {loading ? (
                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
            ) : (
                <Content
                    className="site-layout-background"
                    style={{
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mx-0 mt-0">
                        <Row>
                            <Col md={24} xs={24}>
                                <div className="bg-[#114D89] px-5 py-2.5">
                                    <h1 className="text-white text-2xl ">Assessment Report</h1>
                                </div>
                            </Col>
                        </Row>
                        <div className="py-5 px-5" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                            <Row>

                                <Col md={6} xs={24} style={{ display: 'flex', justifyContent: 'space-between' }} className='mb-5'>

                                    <Search placeholder="Search...." />
                                </Col>
                                <Col md={24} className="responsive-table">
                                    <Table
                                        className="record"
                                        bordered
                                        columns={columns}
                                        dataSource={assessmentList}
                                    //pagination={tableParams.pagination}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Row>
                        <Col>
                            {studyModalVisible && (
                                <Modal title="View & Download study material"
                                    open={studyModalVisible}
                                    onOk={() => setStudyModalVisible(false)}
                                    onCancel={() => setStudyModalVisible(false)}
                                    footer={null}
                                    maskClosable={false}
                                >
                                    <Form >
                                        <Row gutter={30}>
                                            {loading ? (
                                                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                                            ) : (
                                                <Col md={24}>
                                                    <div class="ant-modal-body responsive-table">
                                                        <table class="curricular">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sno</th>
                                                                    <th>Material</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {materialList.map(item =>
                                                                    <tr key={item.sno}>
                                                                        <td>{item.sno}</td>
                                                                        <td>{item.key}</td>
                                                                        <td className="center"><a href={studentPath + item.value} className="bg-[#205584] text-white"
                                                                            target="_blank" rel="noreferrer" style={{ borderRadius: 11, padding: '4px 10px' }}> View</a></td>

                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </Col>

                                            )}
                                        </Row>
                                    </Form>
                                </Modal>
                            )}
                        </Col>
                    </Row>
                </Content>
            )
            }

        </DashboardLayout>
    )
}

export default AssessmentReoprtList