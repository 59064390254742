import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout, Form, Modal, Spin, Select } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

const PBSList = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [loadingPBS, setPBSLoading] = useState(true);
    const [counsellorLoding, setCounsellorLoading] = useState(true); // eslint-disable-line
    const [loadingTime, setTimeLoading] = useState(true);
    const [pbsList, setPBSList] = useState([]);
    const [assignModal, setAssignModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [timeId, setTimeId] = useState(null); // eslint-disable-line
    const [pbs_id, setPBSid] = useState(null); // eslint-disable-line
    const [timeSlot, settimeSlot] = useState(null);
    const [counsellorList, setCounsellorList] = useState(null);
    const [currentDateTime, setCurrentDateTime] = useState(new Date()); // eslint-disable-line
    const [pbsData, setPBSData] = useState({ user_id: "", counsellor_id: "", id: "", date: "", time: "", link: "" });
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filterDate, setFilterDate] = useState(null);
    const [filterStatus, setFilterStatus] = useState('');


    const handleDateChange = (e) => {
        const newDate = new Date(e);
        setStartDate(newDate);
    };


    useEffect(() => {
        const fetchAllPBSList = async () => {

            await axios.post(api_url + 'ajax/get_all_pending_pbs_list', {
                user_id: user_data.id,
            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const st_list = res.data.pbs_list;

                const k = st_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'username': decrypt(item.username, encriptionKey),
                    'mentor': (item.mentor) ? decrypt(item.mentor, encriptionKey) : "",
                    'class': decrypt(item.class, encriptionKey),
                    'course': decrypt(item.course, encriptionKey),
                    'center': item.center,
                    'date': item.date + ' ' + item.time,
                    'status': item.status,
                    'pbs_id': item.id,
                    'user_id': item.user_id,
                    'link': item.link,
                    'counsellor_id': item.counsellor_id,
                    'meeting_id': item.meeting_id,
                })
                );

                setPBSList(k);
                setFilteredResults(st_list);
                setLoading(false)

            })
        }
        fetchAllPBSList()
    }, [user_data.id])


    useEffect(() => {
        const applyFilters = () => {
            let filteredData = pbsList;

            if (searchInput) {
                filteredData = filteredData.filter(item =>
                    Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
                );
            }

            if (filterDate) {
                filteredData = filteredData.filter(item =>
                    new Date(item.date).toDateString() === new Date(filterDate).toDateString()
                );
            }

            if (filterStatus) {
                filteredData = filteredData.filter(item =>
                    item.status.toLowerCase() === filterStatus.toLowerCase()
                );
            }

            setFilteredResults(filteredData);
        };

        applyFilters();
    }, [searchInput, filterDate, filterStatus, pbsList]);

    const getCounsellorList = () => {

        axios.post(api_url + 'ajax/get_centerwise_counsellor_list', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const counsellor_list = res.data.counsellor_list;

            setCounsellorList(counsellor_list);
            setCounsellorLoading(false);
        })

    }
    const getTimeSlot = async () => {

        await axios.post(api_url + 'pbs-time-slot', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const time_slot = res.data.data;

            settimeSlot(time_slot);
            setTimeLoading(false);
        })

    }


    const dateAfterAddition = new Date(currentDateTime);

    const joinZoomMeeting = (record) => {
        navigate("/counsellor-dashboard/join-session/" + record.meeting_id);
    };

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
        },
        {
            key: "2",
            title: "User",
            dataIndex: "username",
            align: 'center'
        },
        {
            key: "3",
            title: "Mentor",
            dataIndex: "mentor",
            align: 'center'
        },
        {
            key: "4",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "5",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "6",
            title: "Date & Time",
            dataIndex: "date",
            align: 'center'
        },
        {
            key: "7",
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            key: "8",
            title: "Actions",
            align: 'center',
            render: (record) => {
                return (
                    <>

                        <Space>
                            {record.status === 'Complete' ? (
                                <>
                                    {/* <Button onClick={() => { onPBSFeedback(record);}} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Enter Feedback</Button> */}
                                    <Button onClick={() => { onViewPBSFeedback(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>PBS Timeline</Button>
                                </>
                            ) : ('')}

                            {(new Date(record.date).getTime() < dateAfterAddition.getTime()) && record.meeting_id > 0 ? (
                                <>
                                    <Button className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }} onClick={() => joinZoomMeeting(record)}>
                                        Join Session
                                    </Button>
                                </>
                            ) : (
                                <Button className="bg-[#205584] text-white" style={{ borderRadius: 11 }} onClick={() => setCounsellorAssignModal(record)}>Assign Counsellor</Button>
                            )}

                        </Space>

                    </>
                );
            },
        },
    ];

    const setCounsellorAssignModal = (record) => {
        setPBSid(record.pbs_id);
        getCounsellorList();
        getTimeSlot()
        axios.post(api_url + 'ajax/get_student_pbs_session', {
            pbs_id: record.pbs_id,
        }).then((res) => {
            console.log(res.data.pbs_list);
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            if (res.data.status) {
                const date = res.data.pbs_list.date;
                const time_id = res.data.time_id;
                setTimeId(time_id);
                setStartDate(new Date(date));
                setPBSData(res.data.pbs_list)
                setPBSLoading(false);
                const timer = setTimeout(() => { // eslint-disable-line
                    setAssignModal(true)
                }, 300);

            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER
                });

            }


        })
    };

    const onViewPBSFeedback = (record) => {

        const user_id = encrypt(record.user_id.toString(), encriptionKey);
        navigate("/counsellor-dashboard/pbs-timeline/" + user_id)
    };

    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
        let filteredData = pbsList;

        if (searchInput) {
            filteredData = filteredData.filter((item) =>
                Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        if (filterDate) {
            filteredData = filteredData.filter(item => new Date(item.date).toDateString() === new Date(filterDate).toDateString());
        }

        if (filterStatus) {
            filteredData = filteredData.filter(item => item.status.toLowerCase() === filterStatus.toLowerCase());
        }

        setFilteredResults(filteredData);
    };


    const onFinish = () => {

        axios.post(api_url + 'counsellor/assign_pbs_mentor', {
            assignBy: user_data.id,
            data: pbsData,
            date: startDate,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => { // eslint-disable-line
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
    }

    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mx-0 mt-0">
                            <Row>
                                <Col md={24} xs={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5">
                                        <h1 className="text-white text-2xl ">All PBS Session</h1>
                                    </div>
                                </Col>
                            </Row>

                            <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <Row gutter={16} className="mt-7">
                                    <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                                    </Col>

                                    <Col md={6}>
                                        <DatePicker
                                            selected={filterDate}
                                            onChange={(date) => setFilterDate(date)}
                                            placeholderText="Filter by Date"
                                            dateFormat="yyyy-MM-dd"
                                            style={{ width: '100%' }}
                                            className="filter-date"
                                        />
                                    </Col>

                                    <Col md={6}>
                                        <Select
                                            placeholder="Filter by Status"
                                            style={{ width: '100%' }}
                                            onChange={(value) => setFilterStatus(value)}
                                            allowClear
                                        >
                                            <Option value="Approved">Approved</Option>
                                            <Option value="Pending">Pending</Option>
                                        </Select>
                                    </Col>


                                </Row>

                                <Row className="mt-5">
                                    <Col md={24} className="responsive-table">
                                        {searchInput || filterDate || filterStatus ? (
                                            <Table
                                                className="record"
                                                bordered
                                                columns={columns}
                                                dataSource={filteredResults}
                                            />
                                        ) : (
                                            <Table
                                                className="record"
                                                bordered
                                                columns={columns}
                                                dataSource={pbsList}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>

                        <Modal width={700} title="Assign Counsellor"
                            open={assignModal}
                            footer={null}
                            onCancel={() => setAssignModal(false)}>
                            {loadingPBS ? (<Spin />) : (
                                <Form onFinish={onFinish}>
                                    <Row gutter={30}>
                                        <Col md={12}>
                                            <label htmlFor="date" className="mb-2.5">
                                                Select Date
                                            </label>
                                            <DatePicker
                                                style={{ width: '100%' }}
                                                className="date-pic-select mb-2.5"
                                                dateFormat="yyyy-MM-dd"
                                                selected={startDate}
                                                onChange={handleDateChange}
                                            />
                                        </Col>

                                        <Col md={12} className="assign-counsellor-select">
                                            <label htmlFor="time" className="mb-2.5">
                                                Select Time
                                            </label>
                                            {loadingTime ? (
                                                <Spin />
                                            ) : (
                                                <select
                                                    value={pbsData.time}
                                                    name="time"
                                                    width={100}
                                                    className="ant-input css-dev-only-do-not-override-k83k30 mb-2.5"
                                                    placeholder="Select"
                                                    onChange={(e) => setPBSData({ ...pbsData, time: e.target.value })}

                                                >
                                                    {timeSlot.map((element) => (
                                                        <option key={element.slot} value={element.slot}>
                                                            {element.slot}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </Col>
                                        <Col md={12} className="assign-counsellor-select">
                                            <label htmlFor="counsellor" className="mb-2.5">
                                                Select Counsellor
                                            </label>
                                            {loadingTime ? (
                                                <Spin />
                                            ) : (
                                                <select
                                                    value={pbsData.counsellor_id}
                                                    name="counsellor"
                                                    width={100}
                                                    className="ant-input css-dev-only-do-not-override-k83k30 mb-2.5"
                                                    placeholder="Select"
                                                    onChange={(e) => setPBSData({ ...pbsData, counsellor_id: e.target.value })}
                                                >
                                                    <option value="">Select Counsellor</option>
                                                    {counsellorList.map((e) => (
                                                        <option key={e.id} value={e.id}>
                                                            {decrypt(e.name, encriptionKey)}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        </Col>

                                        <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="text-sm text-white bg-[#0C3762] mt-5 py-1.5 px-7 flex justify-center"
                                                style={{ height: 'auto', border: '1px solid #EEE6E6', borderRadius: 5 }}
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>)}
                        </Modal>
                    </Content>
                )}
            </CounsellorLayout>
        </>
    )
}

export default PBSList