import React, { useEffect, useState } from 'react';
import '../styles/faculty.css';
import axios from 'axios';
import { Layout, Col, Row, Table } from 'antd';
import TeacherLayout from "../components/teacher-layout";
import { useNavigate } from "react-router-dom";
import { decrypt, encriptionKey } from '../components/utils';
import { api_url } from '../components/constants';
const { Content } = Layout;

const FacultyAttendanceReport = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    const [loading, setLoading] = useState(true);
    const [classList, setClassList] = useState([]);
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;


    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
            align: 'center'
        },
        {
            key: "2",
            title: "User Id",
            dataIndex: "user_id",
            align: 'center'
        },
        {
            key: "3",
            title: "Emp Id",
            dataIndex: "emp_id",
            align: 'center'
        },
        {
            key: "4",
            title: "Faculty Name",
            dataIndex: "faculty_name",
            align: 'center',
            render: (text, record) => (
                <button type='button' className='bg-transparent border-none p-0 m-0 cursor-pointer' onClick={() => { getDetails(record); }} style={{ color: "#166fc7" }}>{text}</button>
            ),
        },

        {
            key: "5",
            title: "Center",
            dataIndex: "center_name",
            align: 'center'
        },
        {
            key: "6",
            title: "Total Class",
            dataIndex: "total_class",
            align: 'center'
        },
        {
            key: "7",
            title: "Total Present",
            dataIndex: "present",
            align: 'center'
        },
        {
            key: "8",
            title: "Total Absent",
            dataIndex: "absent",
            align: 'center'
        },
    ];


    useEffect(() => {
        const fetchAttendanceList = async () => {

            await axios.post(api_url + 'faculty/get-faculty-attendance', {
                faculty_id: user_data.id

            }).then((res) => {
                //console.log(res.data.live_class);
                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const class_list = res.data.class_info;

                const k = class_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'user_id': item.user_id,
                    'faculty_name': decrypt(item.username, encriptionKey),
                    'emp_id': decrypt(item.form_number, encriptionKey),
                    'center_name': item.center,
                    'meeting_id': item.meeting_id,
                    'total_class': item.class_count,
                    'present': item.attend_count,
                    'absent': item.class_count - item.attend_count,
                })
                );
                setClassList(k);
                setLoading(false)

            })
        }
        fetchAttendanceList()
    }, [user_data.id])


    const getDetails = (record) => {
        navigate("/teacher-dashboard/faculty-attendance-report/" + record.user_id);
    }
    return (
        <>
            <TeacherLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        <div className="mx-16 mt-7" style={{ border: '1px solid #E5E5E5' }}>
                            <Row>
                                <Col md={24}>
                                    <div className=' bg-[#114D89] px-5 py-2.5'>
                                        <h1 className="text-white text-lg">Faculty Attendance Report</h1>
                                    </div>
                                </Col>
                            </Row>

                            <div className='bg-[#F1F3F6] py-5'>




                                <Row>
                                    <Col md={24}>
                                        <Table
                                            className="attendance mx-2.5"
                                            bordered
                                            columns={columns}
                                            //pagination={tableParams.pagination}
                                            dataSource={classList}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Content>
                )}
            </TeacherLayout>
        </>
    )
}

export default FacultyAttendanceReport