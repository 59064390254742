import React, { useEffect, useState } from 'react';
import '../styles/faculty.css';
import axios from 'axios';
import { Layout, Button, Form, Col, Row, Input, Radio } from 'antd';
import { BackwardOutlined } from '@ant-design/icons';
import TeacherLayout from "../components/teacher-layout";
import { useParams, useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decrypt, encriptionKey } from '../components/utils';
const { Content } = Layout;
const { TextArea } = Input;

const UpdateAssessmentReport = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({ username: "", centername: "", classname: "", coursename: "" });
    const [reportColumn, setReportColumn] = useState({ id: "", user_id: "", month: "", report_data: "", created_by: "" });
    const [reportData, setReportData] = useState({
        overall_feedback: '', performance: "", grammar_score: "", grammar_feedback: "", vocabulary_score: "",
        vocabulary_feedback: "", listening_score: '', listening_feedback: '', speaking_score: '', speaking_feedback: '', reading_score: '', reading_feedback: '', writing_score: '', writing_feedback: ''
    });


    useEffect(() => {
        const fetchAssessmentDetails = async () => {

            await axios.post(api_url + 'faculty/get_assessment_data', {
                id: id
            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const report_list = res.data.report_data;
                setReportColumn(report_list);
                const obj = JSON.parse(report_list.report_data);
                const st_list = res.data.user_data;
                setReportData(obj);
                setUserData(st_list);
                setLoading(false)
            })
        }
        fetchAssessmentDetails()
    }, [id])

    const onFinish = (values) => {
        axios.post(api_url + 'faculty/submit_assessment', {
            id: reportColumn.id,
            faculty_id: user_data.id,
            records: values,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    // const timer = setTimeout(() => {
                    //     navigate("/counsellor-dashboard/targets/" + id)
                    // }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
    };
    const onBackRequest = () => {
        navigate('/teacher-dashboard/assessment-list/' + reportColumn.user_id)
    }
    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                {loading ? (
                    <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
                ) : (
                    <div className="mx-16 mt-7" style={{ border: '1px solid #E5E5E5' }}>
                        <Row>

                            <Col md={24}>
                                <div className='flex bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <div>
                                        <h1 className="text-white text-xl text-center">ASSESSMENT REPORT</h1>
                                        <span className='text-sm text-white text-center'>(2022-2023)</span>
                                    </div>
                                    <BackwardOutlined onClick={onBackRequest} className='text-white text-3xl flex items-center justify-center' />
                                </div>
                            </Col>
                        </Row>
                        <div className='bg-[#F1F3F6]' style={{ padding: '20px 30px' }}>
                            <Row gutter={30} >
                                <Col md={12}>
                                    <div className='flex py-1.5'>
                                        <h3 className='text-base text-[#0C3762] font-medium'>Name</h3>
                                        <span className='text-base text-[#787878] font-medium pl-16'>{decrypt(userData.username, encriptionKey)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className='flex py-1.5'>
                                        <h3 className='text-base text-[#0C3762] font-medium'>Class</h3>
                                        <span className='text-base text-[#787878] font-medium pl-20'>{decrypt(userData.classname, encriptionKey)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className='flex py-1.5'>
                                        <h3 className='text-base text-[#0C3762] font-medium'>Centre</h3>
                                        <span className='text-base text-[#787878] font-medium pl-16'>{userData.centername}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row style={{ marginTop: 30 }}>
                            <Col md={24}>
                                <div className='bg-[#F1F3F6] px-7'>
                                    <Form className='evaluation py-5' onFinish={onFinish} >
                                        <Row>
                                            <Col md={24}>

                                                <table className='test-result'>
                                                    <thead>
                                                        <tr>
                                                            <th>MODULE</th>
                                                            <th>SCORE</th>
                                                            <th>FEEDBACK</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td className='topic'>Grammar</td>
                                                            <td>
                                                                <Form.Item name='grammar_score' initialValue={reportData.grammar_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item name='grammar_feedback' initialValue={reportData.grammar_feedback}>
                                                                    <TextArea rows={1} />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='topic'>Vocabulary</td>
                                                            <td>
                                                                <Form.Item name='vocabulary_score' initialValue={reportData.vocabulary_score}>
                                                                    <Input />
                                                                </Form.Item>

                                                            </td>
                                                            <td>
                                                                <Form.Item name='vocabulary_feedback' initialValue={reportData.vocabulary_feedback}>
                                                                    <TextArea rows={1} />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='topic'>Listening</td>
                                                            <td>
                                                                <Form.Item name='listening_score' initialValue={reportData.listening_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item name='listening_feedback' initialValue={reportData.listening_feedback}>
                                                                    <TextArea rows={1} />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='topic'>Speaking</td>
                                                            <td>
                                                                <Form.Item name='speaking_score' initialValue={reportData.speaking_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item name='speaking_speaking' initialValue={reportData.speaking_speaking}>
                                                                    <TextArea rows={1} />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <td className='topic'>Reading</td>
                                                            <td>
                                                                <Form.Item name='reading_score' initialValue={reportData.reading_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item name='reading_feedback' initialValue={reportData.reading_feedback}>
                                                                    <TextArea rows={1} />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='topic'>Writing</td>
                                                            <td>
                                                                <Form.Item name='writing_score' initialValue={reportData.writing_score}>
                                                                    <Input />
                                                                </Form.Item>
                                                            </td>
                                                            <td>
                                                                <Form.Item name='writing_feedback' initialValue={reportData.writing_feedback}>
                                                                    <TextArea rows={1} />
                                                                </Form.Item>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>

                                        <div className='mt-7 bg-[#F1F3F6] p-5'>
                                            <Row>
                                                <Col md={24}>
                                                    <h2 className='text-[#0C3762] text-lg font-bold mb-5'>Overall Feedback</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={24}>
                                                    <Form.Item name='overall_feedback' initialValue={reportData.overall_feedback}>
                                                        <TextArea rows={4} className='remark' />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='mt-7 bg-[#F1F3F6] p-5'>
                                            <Row>
                                                <Col md={24}>
                                                    <h2 className='text-[#0C3762] text-lg font-bold mb-5'>Overall Performence</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={24}>
                                                    <Form.Item name='performance' initialValue={reportData.performance}>
                                                        <Radio.Group className='overall_performance'  >
                                                            <Radio value={1}>Excellent</Radio>
                                                            <Radio value={2}>Good</Radio>
                                                            <Radio value={3}>Can Do Better</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>

                                                </Col>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col md={24} className='flex justify-center my-7'>

                                                <Button className="bg-[#205584] text-white" htmlType="submit" >Submit</Button>


                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Content>

        </TeacherLayout>
    )
}

export default UpdateAssessmentReport