import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import { Col, Row, Layout, Modal, Input, Form } from 'antd';
import { UploadOutlined, EnterOutlined } from '@ant-design/icons';
import DashboardLayout from '../components/dashboard-layout';
import axios from 'axios';
import { api_url, doubtPath } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
import { decrypt, encriptionKey } from '../components/utils';
const { Content } = Layout;

const DoubtArea = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }

    const user_data = login_user.user;
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [doubtData, setDoubtData] = useState([{ id: "", message: "", username: "", userType: "", sent_at: "" }]);
    const [file, setFile] = useState(null);
    const [visible, setVisible] = useState(false);
    const [selectedImagePath, setSelectedImagePath] = useState(null);

    useEffect(() => {
        const fetchConversationList = async () => {
            await axios.post(api_url + 'ajax/view_conversation_list', {
                thread_id: id

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }
                const doubt_data = res.data.conversation;
                setDoubtData(doubt_data);
                setLoading(false)

            })
        }
        fetchConversationList()
    }, [id])

    const onFinish = (values) => {
        const formData = new FormData();

        if (file) {
            formData.append('files', file[0]);
        }
        formData.append('message', values.doubt);
        formData.append('thread_id', id);
        formData.append('user_id', user_data.id);

        axios.post(api_url + 'ajax/submit_doubt', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(result => {

            if (result.data.status) {
                toast.success(result.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

                const timer = setTimeout(() => { // eslint-disable-line
                    window.location.reload();
                }, 3000);

            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER
                });
            }

        }).catch(error => {
            console.error(error);
        });
    };

    return (
        <>
            <DashboardLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        background: '#fff',
                        width: '100%',
                        padding: '20px'
                    }}
                >
                    <div className="mb-0 mx-0">
                        <Row>
                            <Col md={24}>
                                <div className="form-layout form-space bg-[#F6F6F6]">
                                    <div className=" bg-[#0F4275] py-2.5 px-5">
                                        <h1 className="text-[#fff] text-xl">Doubt Conversation</h1>
                                    </div>

                                    {loading ? (
                                        <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
                                    ) : (

                                        doubtData.length > 0 ? (doubtData.map((row) => (
                                            <>
                                                {row.userType === "Faculty" ? (
                                                    <Row className='my-2 mx-5'>
                                                        <Col xl={12} lg={16} md={24} xs={24}>
                                                            <h3 className='text-[#0C3762] text-base'>{decrypt(row.username, encriptionKey)}</h3>
                                                            <div className='p-2.5 rounded mb-2.5' style={{ border: '1px solid #C5BEC6' }}>
                                                                <p className='text-base'>{row.message}</p>
                                                                <span className='text-base flex justify-end'>{row.sent_at}</span>
                                                            </div>
                                                            {row.attachment != null ? (
                                                                <div className='p-2.5 rounded bg-[#FDF9EF] mb-2.5' style={{ border: '1px solid #EEE1C0' }}>
                                                                    <p> <img
                                                                        src={doubtPath + row.attachment}
                                                                        alt="avatar"
                                                                        style={{ width: '30%' }}
                                                                        onClick={() => {
                                                                            setSelectedImagePath(doubtPath + row.attachment);
                                                                            setVisible(true);
                                                                        }}
                                                                    /></p>

                                                                    <span className='text-base flex justify-end'>{row.sent_at}</span>
                                                                </div>
                                                            ) : ('')}
                                                        </Col>
                                                    </Row>

                                                ) : (
                                                    <Row className='my-2 mx-5' style={{ display: 'flex', justifyContent: 'end' }}>
                                                        <Col xl={12} lg={16} md={24} xs={24}>
                                                            <h3 className='text-[#0C3762] text-base'>You</h3>
                                                            <div className='p-2.5 rounded bg-[#FDF9EF] mb-2.5' style={{ border: '1px solid #EEE1C0' }}>
                                                                <p className='text-base'>{row.message}</p>
                                                                <span className='text-base flex justify-end'>{row.sent_at}</span>
                                                            </div>
                                                            {row.attachment != null ? (
                                                                <div className='p-2.5 rounded bg-[#FDF9EF]' style={{ border: '1px solid #EEE1C0' }}>
                                                                    <p> <img
                                                                        src={doubtPath + row.attachment}
                                                                        alt="avatar"
                                                                        style={{ width: '30%' }}
                                                                        onClick={() => {
                                                                            setSelectedImagePath(doubtPath + row.attachment);
                                                                            setVisible(true);
                                                                        }}
                                                                    />
                                                                    </p>
                                                                    <Modal
                                                                        className='preview'
                                                                        open={visible}
                                                                        onOk={() => setVisible(false)}
                                                                        onCancel={() => setVisible(false)}
                                                                        footer={null}
                                                                    >
                                                                        {selectedImagePath && (
                                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                <img src={selectedImagePath} alt="avatar" style={{ width: '100%' }} />
                                                                            </div>
                                                                        )}
                                                                    </Modal>
                                                                    <span className='text-base flex justify-end'>{row.sent_at}</span>
                                                                </div>
                                                            ) : ('')}
                                                        </Col>
                                                    </Row>

                                                )}

                                            </>
                                        ))) : (<div className="my-24">
                                            <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                        </div>)
                                    )}

                                    <Row className='mx-5 my-5'>
                                        <Col md={24}>
                                            <Form className='doubt' onFinish={onFinish}>
                                                <div className='flex justify-between py-2.5 px-5' style={{ border: '1px solid #EEDCDC', borderRadius: 10 }}>
                                                    <Col md={22}>
                                                        <Form.Item name='doubt' rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please Enter Your Doubt !',
                                                            },
                                                        ]}>
                                                            <Input placeholder='message.....' style={{ border: 'none' }} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col md={2}>
                                                        <div className='flex justify-end'>
                                                            <Form.Item name='upload'>
                                                                <input type="file" className='upload-btn' onChange={(e) => setFile(e.target.files)} />
                                                                <UploadOutlined className='custom-upload text-3xl text-[#BFBFBF] font-bold'

                                                                />

                                                            </Form.Item>
                                                            <Form.Item name='enter' initialValue="submit">
                                                                <button type='submit'>
                                                                    <EnterOutlined className='text-3xl text-[#BFBFBF] font-bold' />
                                                                </button>
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Form>

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </DashboardLayout>
        </>
    )
}

export default DoubtArea