import React, { useState, useEffect } from 'react';
import { Modal, Form, Popconfirm, Spin } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url } from '../components/constants';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ActRecord = ({ show, hide }) => {
    const cur_date = moment(new Date());
    const today_date = cur_date.format("YYYY-MM-DD");
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [actData, setActData] = useState([{ exam_date: today_date, attempt: "", score: "", maths: "", english: "" }])
    const [startDate, setStartDate] = useState(new Date());
    const handleAddRow = () => {
        setActData([...actData, { exam_date: today_date, attempt: "", score: "", maths: "", english: "" }]);
    };

    const handleChange = (e, index) => {
        const newData = [...actData];

        if (e.target.name === "exam_date") {
            setStartDate(new Date(e.target.value));

            const momentObj = moment(e.target.value);
            newData[index][e.target.name] = momentObj.format("YYYY-MM-DD");

        } else {
            newData[index][e.target.name] = e.target.value;
        }
        setActData(newData);

    };

    const handleRemove = (index) => {
        const newTable = [...{ actData }]
        newTable.splice(index, 1)
        setActData(newTable)
    }

    const handleSubmit = () => {

        axios.post(api_url + 'user/update_test_records', {
            user_id: user_data.id,
            data: actData,
            type: 'ACT',
        })
            .then(result => {
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => { // eslint-disable-line
                        navigate(window.location.pathname);
                        hide();
                    }, 3000);

                } else {
                    toast.error("Please fill your academic details !", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    };

    useEffect(() => {
        const fetchActData = async () => {

            await axios.post(api_url + 'user/get_test_data', {
                user_id: user_data.id,

            }).then((res) => {

                let act_data = res.data.act_data;


                if (act_data) {
                    setActData(JSON.parse(act_data));
                }
                setLoading(false)

            })
        }
        fetchActData()

    }, [user_data.id])

    return (
        <>
            <ToastContainer />
            {loading ? (
                <Spin />
            ) : (
                <Modal
                    title="ACT Test Records"
                    open={show}
                    onCancel={hide}
                    onOk={() => {
                        handleSubmit();
                    }
                    }
                    okText='Save'
                    width={1200}
                    cancelText='Cancel'
                    okButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                    cancelButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                >
                    <Form
                        name="normal_login"
                        className="my-7 mx-5"
                    >
                        <table className='curricular-data'>
                            <thead>
                                <tr>
                                    <th>Exam Date</th>
                                    <th>Attempt</th>

                                    <th>Maths Score</th>
                                    <th>English Score</th>
                                    <th>Science Score</th>
                                    <th>Reading Score</th>
                                    <th>Composite Score</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {actData.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            {row.exam_date === null || row.exam_date === "" ? (
                                                <DatePicker style={{ width: '80%' }} dateFormat="yyyy-MM-dd" selected={startDate} onChange={(date) => handleChange({ target: { name: 'exam_date', value: date } }, index)} />
                                            ) : (
                                                <DatePicker style={{ width: '80%' }} dateFormat="yyyy-MM-dd" selected={new Date(row.exam_date)} onChange={(date) => handleChange({ target: { name: 'exam_date', value: date } }, index)} />
                                            )}

                                        </td>
                                        <td>
                                            <select value={row.attempt} name="attempt" width={100}
                                                className="ant-input css-dev-only-do-not-override-k83k30" onChange={(e) => handleChange(e, index)}
                                                disabled={new Date(row.exam_date) > new Date()} >
                                                <option value="">Select</option>
                                                <option value="Attempt-1">Attempt-1</option>
                                                <option value="Attempt-2">Attempt-2</option>
                                                <option value="Attempt-3">Attempt-3</option>
                                                <option value="Attempt-4">Attempt-4</option>
                                                <option value="Attempt-5">Attempt-5</option>
                                            </select>
                                        </td>

                                        <td>
                                            <input
                                                type="number"
                                                name="maths"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.maths}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={new Date(row.exam_date) > new Date()}
                                                step="0.01"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="english"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.english}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={new Date(row.exam_date) > new Date()}
                                                step="0.01"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="science"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.science}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={new Date(row.exam_date) > new Date()}
                                                step="0.01"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="reading"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.reading}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={new Date(row.exam_date) > new Date()}
                                                step="0.01"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="score"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.score}
                                                onChange={(e) => handleChange(e, index)}
                                                disabled={new Date(row.exam_date) > new Date()}
                                                step="0.01"
                                            />
                                        </td>


                                        <td>
                                            <Popconfirm title="Sure to delete?" onConfirm={() => handleRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                <button className='bg-transparent border-none p-0 m-0 cursor-pointer'>
                                                    <DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' />
                                                </button>
                                            </Popconfirm>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5" onClick={handleAddRow} style={{ float: 'right' }}>
                            Add Row
                        </button>



                    </Form>


                </Modal>
            )}
        </>

    )

}

export default ActRecord
