import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Select, Button, Layout, Modal, Form, Menu, Dropdown } from "antd";
import TeacherLayout from "../components/teacher-layout";
import { useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";

const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

const Class = () => {
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [classList, setClassList] = useState([]);
    const [editModal, setEditModal] = useState(false);
    const [file, setFile] = useState(null);
    const [status, setStatus] = useState(null);
    const [classLink, setClassLink] = useState(null); // eslint-disable-line
    const [remarks, setClassRemarks] = useState('');
    const [classId, setClassId] = useState(null);
    const [form] = Form.useForm();
    const [currentDateTime, setCurrentDateTime] = useState(new Date()); // eslint-disable-line
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');

    const [filterDate, setFilterDate] = useState(null);
    const [filterStatus, setFilterStatus] = useState('');

    const dateAfterAddition = new Date(currentDateTime);
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const onFinish = () => {

        const formData = new FormData();

        if (status === "Complete" || status === "Cancelled") {

            if (file) {
                for (let i = 0; i < file.length; i++) {

                    formData.append('files_' + i, file[i]);
                }
            }

            formData.append('status', status)
            // formData.append('link', classLink)
            formData.append('remarks', remarks)
            formData.append('classId', classId);
            formData.append('user_id', user_data.id);

            axios.post(api_url + 'faculty/update-class-new', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {  // eslint-disable-line
                        setEditModal(false);
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }

            }).catch(error => {
                console.error(error);
            });
        }

    };

    const joinZoomMeeting = (record) => {
        navigate("/teacher-dashboard/join-class/" + record.meeting_id);
    };

    const viewZoomMeeting = (record) => {
        navigate("/teacher-dashboard/view-class/" + record.meeting_id);
    };

    const setEditHandle = (record) => {

        axios.post(api_url + 'faculty/get-class-details-new', {
            class_id: record.meeting_id

        }).then((res) => {

            if (res.data.status) {
                setClassLink(res.data.data.class_link)
                setStatus(res.data.data.status)
                if (res.data.data.status === "Approved") {
                    setStatus('Pending')
                }

                setClassId(record.meeting_id)
                setClassRemarks(res.data.data.reject_remarks)
                setTimeout(() => {
                    setEditModal(true);
                }, 500);

            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        })

    }

    const viewFeedback = (record) => {
        const url = "/teacher-dashboard/view-feedback/" + record.class_id;
        window.open(url, '_blank', 'noopener,noreferrer');
    };


    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
            align: 'center',
        },
        {
            key: "2",
            title: "Class Type",
            dataIndex: "class_type",
            align: 'center'
        },
        {
            key: "3",
            title: "Batch",
            dataIndex: "batch",
            align: 'center'
        },
        {
            key: "4",
            title: "Topic",
            dataIndex: "topic",
            align: 'center'
        },
        {
            key: "5",
            title: "Date",
            dataIndex: "date",
            align: 'center'
        },
        {
            key: "6",
            title: "Duration (Min)",
            dataIndex: "duration",
            align: 'center'
        },
        {
            key: "7",
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            key: "8",
            title: "Action",
            align: 'center',
            render: (record) => {
                return (
                    <>
                        <Dropdown overlay={
                            <Menu>
                                {record.status === "Complete" && record.attendance === 1 ? (
                                    <Menu.Item key="viewClass">
                                        <button type="button" className="bg-transparent border-none p-0 m-0 cursor-pointer" onClick={() => viewZoomMeeting(record)}>
                                            View Recording
                                        </button>
                                    </Menu.Item>
                                ) : (
                                    ''
                                )}

                                <Menu.Item key="edit">
                                    <button type="button" className="bg-transparent border-none p-0 m-0 cursor-pointer" onClick={() => setEditHandle(record)}>
                                        Edit
                                    </button>
                                </Menu.Item>
                                {record.is_feedback === "Y" ? (<Menu.Item key="edit">
                                    <button type="button" className="bg-transparent border-none p-0 m-0 cursor-pointer" style={{ borderRadius: 11 }} onClick={() => viewFeedback(record)}> Feedback Form</button>
                                </Menu.Item>) : ('')}

                                {record.status === "Pending" && (
                                    dateAfterAddition.getTime() >= new Date(record.date).getTime() - 15 * 60000 &&
                                        dateAfterAddition.getTime() <= new Date(record.date).getTime() + record.duration * 60000 ? (
                                        <Menu.Item key="joinClass">
                                            <button type="button" className="bg-transparent border-none p-0 m-0 cursor-pointer" onClick={() => joinZoomMeeting(record)}>
                                                Join Class
                                            </button>
                                        </Menu.Item>
                                    ) : null
                                )}

                            </Menu>
                        } placement="bottomLeft">
                            <Button className="bg-[#F0AA0C] text-white" style={{ borderRadius: 11 }}>
                                Actions
                            </Button>
                        </Dropdown>

                        <Modal title="Update Class Details" open={editModal} onCancel={() => setEditModal()} footer={null}>
                            <Form form={form} onFinish={onFinish}>

                                <Form.Item label="Class Status" name="class_status" >
                                    {remarks ? <><Select value={status} onChange={(value) => setStatus(value)}>
                                        <Option value="Cancelled">Cancelled</Option>
                                    </Select></> : <>

                                        <Select value={status} onChange={(value) => setStatus(value)}>
                                            <Option value="Pending" >Pending</Option>
                                            <Option value="Complete" >Complete</Option>
                                            <Option value="Cancelled">Cancelled</Option>
                                        </Select>

                                    </>}

                                </Form.Item>

                                {status === "Cancelled" ? <><Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter remarks',
                                        },
                                    ]}
                                    label="Remarks"
                                    initialValue={remarks} // Ensure initialValue is set to remarks
                                    name="remarks"
                                    style={{ display: status === 'Cancelled' ? 'block' : 'none' }}
                                >
                                    <Input
                                        placeholder="Enter the Remarks"
                                        onChange={(e) => setClassRemarks(e.target.value)} // Update remarks when input changes
                                        disabled={status === 'Complete' ? true : false}
                                    />
                                </Form.Item></> : <></>}

                                {remarks ? <></> : <> <Form.Item label="Class Notes" name="file" style={{ display: status === 'Pending' || status === 'Cancelled' ? 'none' : 'block' }}>
                                    <input type="file" onChange={(e) => setFile(e.target.files)} multiple disabled={status === 'Pending' || status === 'Cancelled' ? true : false} />
                                    <p className="text-red-600 text-sm">Note: Can Upload multiple files</p>
                                </Form.Item></>}

                                <Form.Item wrapperCol={{ offset: 6 }}>
                                    <Button type="primary" htmlType="submit" className="bg-[#F0AA0C]">
                                        Submit
                                    </Button>
                                </Form.Item>

                            </Form>
                        </Modal>

                    </>
                );
            },
        },
    ];


    useEffect(() => {
        const fetchClassesList = async () => {

            await axios.post(api_url + 'faculty/get-class-list', {
                faculty_id: user_data.id

            }).then((res) => {
                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const class_list = res.data.live_class;
                const k = class_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'batch': item.batch,
                    'topic': item.class_name,
                    'date': item.date + ' ' + item.start_time,
                    'duration': item.duration,
                    'time': item.time_slot,
                    'status': item.status === 'Approved' ? 'Pending' : (item.status === "Cancelled" && item.reject_status === "1") ? 'Cancelled - ' + item.reject_remarks : item.status,
                    'class_id': item.id,
                    'meeting_id': item.meeting_id,
                    'attendance': item.attendance,
                    'link': item.link,
                    'reject_status': item.reject_status,
                    'is_feedback': item.is_feedback,
                    'class_type': item.class_type,
                })
                );

                setClassList(k);
                setFilteredResults(class_list);
                setLoading(false)

            })
        }
        fetchClassesList()
    }, [user_data.id])


    useEffect(() => {
        const applyFilters = () => {
            let filteredData = classList;

            if (searchInput) {
                filteredData = filteredData.filter(item =>
                    Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
                );
            }

            if (filterDate) {
                filteredData = filteredData.filter(item =>
                    new Date(item.date).toDateString() === new Date(filterDate).toDateString()
                );
            }

            if (filterStatus) {
                filteredData = filteredData.filter(item => {
                    if (filterStatus === "Cancelled") {
                        return item.status.toLowerCase().startsWith("cancelled");
                    } else {
                        return item.status.toLowerCase() === filterStatus.toLowerCase();
                    }
                });
            }

            setFilteredResults(filteredData);
        };

        applyFilters();
    }, [searchInput, filterDate, filterStatus, classList]);

    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
        let filteredData = classList;

        if (searchInput) {
            filteredData = filteredData.filter((item) =>
                Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        if (filterDate) {
            filteredData = filteredData.filter(item => new Date(item.date).toDateString() === new Date(filterDate).toDateString());
        }

        if (filterStatus) {
            if (filterStatus === "Cancelled") {
                filteredData = filteredData.filter(item =>
                    item.status.toLowerCase().startsWith("cancelled")
                );
            } else {
                filteredData = filteredData.filter(item =>
                    item.status.toLowerCase() === filterStatus.toLowerCase()
                );
            }
        }

        setFilteredResults(filteredData);
    };

    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="mx-0 mt-0">
                    <Row>
                        <Col md={24} xs={24}>
                            <div className="bg-[#114D89] px-5 py-2.5">
                                <h1 className="text-white text-2xl ">Classes</h1>
                            </div>
                        </Col>
                    </Row>

                    <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                        <Row gutter={16} className="mt-7">

                            <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} >
                                <Search placeholder="Search...." onChange={(e) => searchItems(e.target.value)} />
                            </Col>

                            <Col md={6}>
                                <DatePicker
                                    selected={filterDate}
                                    onChange={(date) => setFilterDate(date)}
                                    placeholderText="Filter by Date"
                                    dateFormat="yyyy-MM-dd"
                                    style={{ width: '100%' }}
                                    className="filter-date"
                                />
                            </Col>

                            <Col md={6}>
                                <Select
                                    placeholder="Filter by Status"
                                    style={{ width: '100%' }}
                                    onChange={(value) => setFilterStatus(value)}
                                    allowClear
                                >
                                    <Option value="Complete">Complete</Option>
                                    <Option value="Pending">Pending</Option>
                                    <Option value="Cancelled">Cancelled</Option>
                                </Select>
                            </Col>
                        </Row>

                        <Row className="mt-5">
                            <Col md={24} className="responsive-table">
                                {searchInput || filterDate || filterStatus ? (
                                    <Table
                                        className="record"
                                        bordered
                                        columns={columns}
                                        dataSource={filteredResults}
                                    />
                                ) : (
                                    <Table
                                        className="record"
                                        bordered
                                        columns={columns}
                                        dataSource={classList}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Content>
        </TeacherLayout>
    )
}

export default Class