import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Input, Upload, message, } from 'antd';
import { LoadingOutlined, CloudUploadOutlined } from '@ant-design/icons';
import Header from '.././components/header';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import '../styles/form.css';
import '../styles/responsive.css';
import { Link } from 'react-router-dom';
import AcademicRecord from '../modal/academic-record';
import CurricularRecords from '../modal/co-curricular';
import ResearchProject from '../modal/research-project';
import Awards from '../modal/awards';
import Internships from '../modal/internships';
import Publications from '../modal/publications';
import CommunityServices from '../modal/community-services';
import OtherActivities from '../modal/other-activities';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { decrypt, encriptionKey } from '../components/utils';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url, studentPath } from '../components/constants';

const StudentProfile = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }

    const user_data = login_user.user;

    const className = decrypt(user_data.class, encriptionKey);

    const [form] = Form.useForm();
    const [studentData, setStudentData] = useState(
        {
            name: '', form_number: '', course_name: '', profile_picture: '', batch: '', dob: '', class: '', school: '', address: '', father_name: '', mother_name: '', father_occupation: '', mother_occupation: '',
        }
    )

    const [imageUrl, setImageUrl] = useState("");
    const [academicStatus, setAcademicStatus] = useState("");
    const [curricularStatus, setCurricularStatus] = useState("");
    const [loading, setLoading] = useState(true);
    const [imageLoading, setImageLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [curricularModalVisible, setcurricularModalVisible] = useState(false);
    const [researchModalVisible, setresearchModalVisible] = useState(false);
    const [awardsModalVisible, setAwardshModalVisible] = useState(false);
    const [internshipModalVisible, setinternshiphModalVisible] = useState(false);
    const [publicationModalVisible, setpublicationModalVisible] = useState(false);
    const [communityModalVisible, setcommunityModalVisible] = useState(false);
    const [activityModalVisible, setactivityModalVisible] = useState(false);

    const fetchUserData = async () => {

        pushPage('student-profile-report');

        await axios.post(api_url + 'user/get_profile_data', {
            user_id: user_data.id,
            type: 'Academic',

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token === null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const user_info = [res.data.user_info];
            setAcademicStatus(res.data.academic_status);
            setCurricularStatus(res.data.curricular_status);

            const stArray = user_info.map(item => ({
                'user_id': item.user_id,
                'name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'form_number': decrypt(item.form_number, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'mother_name': decrypt(item.mother_name, encriptionKey),
                'father_name': decrypt(item.father_name, encriptionKey),
                'dob': decrypt(item.dob, encriptionKey),
                'city': decrypt(item.city, encriptionKey),
                'psychrometric_report': item.psychrometric_report,
                'batch': item.batch,
                'father_occupation': item.father_occupation,
                'mother_occupation': item.mother_occupation,
                'diagnostic_report': item.diagnostic_report,
                'admission_date': item.admission_date,
                'address': item.address,
                'class': decrypt(item.class, encriptionKey),
                'course_name': decrypt(item.course, encriptionKey),
                'school': item.school,
                'photo': item.photo,

            }));

            setStudentData(stArray[0]);

            if (stArray[0].photo === null || stArray[0].photo === "") {
                setImageLoading(false)
            } else {
                let profileImg = studentPath + stArray[0].photo;
                setImageUrl(profileImg)
            }

            setLoading(false)
        })
    }


    useEffect(() => {
        const timer = setTimeout(() => { // eslint-disable-line
            fetchUserData()
        }, 1000);
    }, []) // eslint-disable-line

    const handleChange = (name, value) => {
        setStudentData({ ...studentData, [name]: value });

    };

    const handleSubmit = () => {


        if (academicStatus === "") {
            toast.error("Fill Your Academic Details", {
                position: toast.POSITION.TOP_CENTER
            });


            // Swal.fire({
            //     icon: 'error',
            //     title: 'Fill Academic Details'
            // });
            return false;
        }
        if (curricularStatus === "") {
            toast.error("Fill Your Curricular Details", {
                position: toast.POSITION.TOP_CENTER
            });

            return false;
        }
        axios.post(api_url + 'user/update_profile_format', {
            user_info: studentData,
        })
            .then(result => {
                if (result.data.status) {

                    // toast.success(result.data.message, {
                    //     position: toast.POSITION.TOP_CENTER
                    // });
                    // const timer = setTimeout(() => {
                    if (className === '9' || className === '10') {

                        navigate("/student-portal/psychometric-report")
                    } else if (className === '11' || className === '12') {
                        navigate("/student-portal/diagnostic-report")
                    } else {
                        navigate("/student-dashboard/dashboard")
                    }
                    //}, 3000);

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something went wrong'
                    });
                }


            })
    }

    const pushPage = (pgKey) => {
        //console.log('page', pgKey);
        let visitedPages = JSON.parse(sessionStorage.getItem('visitedPages'));
        if (!visitedPages.includes(pgKey)) {
            visitedPages.push(pgKey);
            sessionStorage.setItem('visitedPages', JSON.stringify(visitedPages));
        }
    }

    const uploadButton = (
        <div>
            {imageLoading ? <LoadingOutlined /> : <CloudUploadOutlined />}
            <div
                className="text-base text-[#4D4D4D]"
                style={{
                    marginTop: 8,
                }}
            >
                Upload Your Photo
            </div>
        </div>
    );

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {

        if (info.file.status === 'uploading') {
            setImageLoading(true);
            return;
        }
        const formData = new FormData();
        formData.append('image', info.file.originFileObj);
        formData.append('user_id', user_data.id);

        axios.post(api_url + 'user/upload_image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {

            const url = studentPath + response.data.image;
            setImageUrl(url);

            form.resetFields();
        }).catch(error => {
            console.error(error);
        });

    };

    return (
        <>
            <ToastContainer />
            <Header />
            {loading ? (
                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
            ) : (

                <>
                    <div className="page-wrapper">
                        <div className="page-corners">
                            <TopBanner />
                            <div className="p-5">
                                <h1 className="text-[#0F4275] text-2xl flex justify-center text-center">Student Profile Report</h1>
                            </div>
                            <div className="container md:flex justify-center items-center">
                                <div className="form-layout form-space my-10" style={{ borderTop: '8px solid #114D89', boxShadow: '0px 3px 6px #00000029' }}>
                                    <div className="md:flex justify-center items-center mx-10">
                                        <Form
                                            name="normal_login"
                                            className="my-7"
                                        >
                                            <div className="lg:flex justify-between">
                                                <div className="md:flex flex-col">
                                                    <Row gutter={50}>
                                                        <Col md={12} xs={24}>
                                                            <Form.Item
                                                                name="name"
                                                                label="Your Name"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                initialValue={studentData.name}

                                                            >
                                                                <Input disabled={true} onChange={(e) => handleChange('name', e.target.value)} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col md={12} xs={24}>
                                                            <Form.Item
                                                                name="form_number"
                                                                label="Form Number"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                initialValue={studentData.form_number}

                                                            >
                                                                <Input disabled={true} onChange={(e) => handleChange('form_number', e.target.value)} />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={50}>
                                                        <Col md={12} xs={24}>
                                                            <Form.Item
                                                                name="course_name"
                                                                label="AGSD Course Name"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                initialValue={studentData.course_name}

                                                            >
                                                                <Input disabled={true} onChange={(e) => handleChange('course_name', e.target.value)} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col md={12} xs={24}>
                                                            <Form.Item
                                                                name="class"
                                                                label="Class"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                initialValue={studentData.class}

                                                            >
                                                                <Input disabled={true} onChange={(e) => handleChange('class', e.target.value)} />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <Form.Item>
                                                    <Col md={4} xs={24} className='mr-20'>
                                                        <Upload
                                                            name="profile_picture"
                                                            listType="picture-card"
                                                            className="avatar-uploader p-5"
                                                            showUploadList={false}
                                                            beforeUpload={beforeUpload}
                                                            onChange={handleChangeImage}

                                                        >
                                                            {imageUrl ? (
                                                                <img
                                                                    src={imageUrl}
                                                                    alt="avatar"
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                />
                                                            ) : (
                                                                uploadButton
                                                            )}
                                                        </Upload>
                                                    </Col>
                                                </Form.Item>

                                            </div>

                                            <Row>
                                                <Col md={24}>
                                                    <h3 className="text-[#F0AA0C] mb-5">Achievement Details (Last Three Years Only)</h3>
                                                </Col>
                                            </Row>

                                            <Row gutter={30}>
                                                <Col md={8} lg={6}>
                                                    <Form.Item>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            className="text-base md:text-sm lg:text-base text-[#2A6496] bg-[#EEEEEE] rounded-lg pb-1.5 px-10 w-52 md:w-40 lg:w-52 flex justify-center flex-shrink-0"
                                                            style={{ height: 'auto' }}
                                                            onClick={() => setModalVisible(true)}
                                                        >
                                                            Academic Records
                                                        </Button>
                                                        {modalVisible && (
                                                            <AcademicRecord
                                                                show={modalVisible}
                                                                hide={() => setModalVisible(false)} />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col md={8} lg={6}>
                                                    <Form.Item>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            className="text-base md:text-sm lg:text-base text-[#2A6496] bg-[#EEEEEE] rounded-lg pb-1.5 px-10 w-52 md:w-40 lg:w-52 flex justify-center flex-shrink-0"
                                                            style={{ height: 'auto' }}
                                                            onClick={() => setcurricularModalVisible(true)}
                                                        >
                                                            Co-Curricular
                                                        </Button>
                                                        {curricularModalVisible && (
                                                            <CurricularRecords
                                                                show={curricularModalVisible}
                                                                hide={() => setcurricularModalVisible(false)} />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col md={8} lg={6}>
                                                    <Form.Item>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            className="text-base md:text-sm lg:text-base text-[#2A6496] bg-[#EEEEEE] rounded-lg pb-1.5 px-10 w-52 md:w-40 lg:w-52 flex justify-center flex-shrink-0"
                                                            style={{ height: 'auto' }}
                                                            onClick={() => setresearchModalVisible(true)}
                                                        >
                                                            Research Projects
                                                        </Button>
                                                        {researchModalVisible && (
                                                            <ResearchProject
                                                                show={researchModalVisible}
                                                                hide={() => setresearchModalVisible(false)} />
                                                        )}

                                                    </Form.Item>
                                                </Col>
                                                <Col md={8} lg={6}>
                                                    <Form.Item>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            className="text-base md:text-sm lg:text-base text-[#2A6496] bg-[#EEEEEE] rounded-lg pb-1.5 px-10 flex w-52 md:w-40 lg:w-52 justify-center flex-shrink-0"
                                                            style={{ height: 'auto' }}
                                                            onClick={() => setAwardshModalVisible(true)}
                                                        >
                                                            Awards/Honours
                                                        </Button>
                                                        {awardsModalVisible && (
                                                            <Awards
                                                                show={awardsModalVisible}
                                                                hide={() => setAwardshModalVisible(false)} />
                                                        )}

                                                    </Form.Item>
                                                </Col>
                                                <Col md={8} lg={6}>
                                                    <Form.Item>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            className="text-base md:text-sm lg:text-base text-[#2A6496] bg-[#EEEEEE] rounded-lg pb-1.5 px-10 w-52 md:w-40 lg:w-52 flex justify-center flex-shrink-0"
                                                            style={{ height: 'auto' }}
                                                            onClick={() => setinternshiphModalVisible(true)}
                                                        >
                                                            Internships
                                                        </Button>
                                                        {internshipModalVisible && (
                                                            <Internships
                                                                show={internshipModalVisible}
                                                                hide={() => setinternshiphModalVisible(false)} />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col md={8} lg={6}>
                                                    <Form.Item>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            className="text-base md:text-sm lg:text-base text-[#2A6496] bg-[#EEEEEE] rounded-lg pb-1.5 px-10 w-52 md:w-40 lg:w-52 flex justify-center flex-shrink-0"
                                                            style={{ height: 'auto' }}
                                                            onClick={() => setpublicationModalVisible(true)}
                                                        >
                                                            Publications
                                                        </Button>
                                                        {publicationModalVisible && (
                                                            <Publications
                                                                show={publicationModalVisible}
                                                                hide={() => setpublicationModalVisible(false)} />
                                                        )}
                                                    </Form.Item>
                                                </Col>

                                                <Col md={8} lg={6}>
                                                    <Form.Item>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            className="text-base md:text-sm lg:text-base text-[#2A6496] bg-[#EEEEEE] rounded-lg pb-1.5 px-10 w-52 md:w-40 lg:w-52 flex justify-center flex-shrink-0"
                                                            style={{ height: 'auto' }}
                                                            onClick={() => setcommunityModalVisible(true)}
                                                        >
                                                            Community Services
                                                        </Button>
                                                        {communityModalVisible && (
                                                            <CommunityServices
                                                                show={communityModalVisible}
                                                                hide={() => setcommunityModalVisible(false)} />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col md={8} lg={6}>
                                                    <Form.Item>
                                                        <Button type="primary"
                                                            htmlType="submit"
                                                            className="text-base md:text-sm lg:text-base text-[#2A6496] bg-[#EEEEEE] rounded-lg pb-1.5 px-10 w-52 md:w-40 lg:w-52 flex justify-center flex-shrink-0"
                                                            style={{ height: 'auto' }}
                                                            onClick={() => setactivityModalVisible(true)}
                                                        >
                                                            Others
                                                        </Button>
                                                        {activityModalVisible && (
                                                            <OtherActivities
                                                                show={activityModalVisible}
                                                                hide={() => setactivityModalVisible(false)} />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row className="container">
                                                <Col md={24} xs={24} className='flex justify-end'>
                                                    <div className="flex">
                                                        <Link type="primary"
                                                            to={'/student-portal/general-intelligence-quotient'}
                                                            htmlType="submit"
                                                            className="text-lg text-[#fff] bg-[#0D3761] rounded-lg py-1.5 px-5 flex justify-center mr-2.5"
                                                            style={{ height: 'auto' }}
                                                        >
                                                            Back
                                                        </Link>
                                                        {className === "10" || className === "11" || className === "12" ? (

                                                            <Button type="primary"
                                                                onClick={handleSubmit}
                                                                htmlType="submit"
                                                                className="text-lg text-[#0D3761] bg-[#FCC222] rounded-lg py-1.5 px-5 flex justify-center"
                                                                style={{ height: 'auto' }}
                                                            >
                                                                Next
                                                            </Button>
                                                        ) : (

                                                            <Button type="primary"
                                                                onClick={handleSubmit}
                                                                htmlType="submit"
                                                                className="text-lg text-[#0D3761] bg-[#FCC222] rounded-lg py-1.5 px-5 flex justify-center"
                                                                style={{ height: 'auto' }}
                                                            >
                                                                Submit
                                                            </Button>

                                                        )}

                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </>
            )}
            <Footer />
        </>
    )
}

export default StudentProfile