import React, { useState } from "react";
import { Col, Row, Dropdown, Space, Button } from 'antd';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { setAuthToken } from '../helpers/setAuthToken';
import { Link } from 'react-router-dom';
import { encriptionKey, decrypt } from "./utils";
import { base_url } from '../components/constants';
const Header = () => {
    //console.log(window.location.origin);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const path = window.location.pathname;

    let isProfileVisible = "";
    if (path.includes('student-dashboard')) {
        isProfileVisible = 'student-dashboard';
    }

    let isCounsellorVisible = "";
    if (path.includes('counsellor-dashboard')) {
        isCounsellorVisible = 'counsellor-dashboard';
    }

    const access_token = JSON.parse(localStorage.getItem('user'));
    if (access_token) {
        setAuthToken(access_token.token);
    }


    const login_user = JSON.parse(localStorage.getItem('user'));
    const user_data = login_user.user;


    const logout = () => {
        localStorage.removeItem('user')
        setIsLoggedIn(false)
    }
    const items = [
        {
            key: '1',
            label: (isProfileVisible && (
                <Link type="primary" to={'/student-dashboard/dashboard'} htmltype="submit" >My Profile </Link>
            )

            ),
        },
        {
            key: '2',
            label: (
                <Button href="/" onClick={logout} style={{ border: 'none' }}>
                    Logout
                </Button>

            ),

        },
    ];


    const visitedPages = [];
    if (sessionStorage.getItem('visitedPages') == null) {
        sessionStorage.setItem('visitedPages', JSON.stringify(visitedPages));
    }

    return (
        <>
            {isLoggedIn}
            <div className="header" >
                <section className="bg-[#061522]">
                    <Row className="md:mx-10 mx-2.5 lg:mx-32 flex justify-between items-center py-5">
                        <Col md={6} className='flex'>
                            <MailOutlined className='flex justify-center items-center text-white text-lg' />
                            <ul>
                                <li className="text-[#fff] text-base pl-2.5"><button className="bg-transparent border-none p-0 m-0 cursor-pointer" href="#">globalstudies@allen.ac.in</button></li>
                            </ul>
                        </Col>
                        <Col md={6} className='flex justify-end'>
                            <PhoneOutlined className='flex justify-center items-center text-white text-lg' />
                            <ul>
                                <li className="text-[#fff] text-base pl-2.5"><button className="bg-transparent border-none p-0 m-0 cursor-pointer" href="#">+91- 7726852446</button></li>
                            </ul>
                        </Col>
                    </Row>
                </section>

                <section style={{ boxShadow: '0px 3px 6px #00000029' }}>
                    <Row className="md:mx-10 mx-2.5 lg:mx-32 flex justify-between items-center py-5">
                        <Col md={6}>
                            {isProfileVisible === "student-dashboard" ? (
                                <Link type="primary" to={'/student-dashboard/dashboard'} htmltype="submit" > <img src={base_url + '/uploads/allen-global-logo.png'} style={{ width: 130 }} alt="ALLEN global" /></Link>
                            ) : (isCounsellorVisible === "counsellor-dashboard" ? (
                                <Link type="primary" to={'/counsellor-dashboard/dashboard-home'} htmltype="submit" > <img src={base_url + '/uploads/allen-global-logo.png'} style={{ width: 130 }} alt="ALLEN global" /></Link>
                            ) : (<Link type="primary" to={'/student-portal/general-enquiry'} htmltype="submit" > <img src={base_url + '/uploads/allen-global-logo.png'} style={{ width: 130 }} alt="ALLEN global" /></Link>))

                            }

                        </Col>

                        <Col md={6} className='flex justify-end'>
                            <div className="flex">
                                <img alt='upload' src={base_url + '/uploads/avatar.png'} className="user-img flex justify-start items-center" />
                                <Dropdown
                                    menu={{
                                        items,
                                    }}
                                >
                                    <button className="bg-transparent border-none p-0 m-0 cursor-pointer" onClick={(e) => e.preventDefault()}>
                                        <Space style={{ paddingLeft: 5, paddingTop: 10 }}>
                                            Hi,{decrypt(user_data.name, encriptionKey)}
                                        </Space>
                                    </button>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </section>

            </div>
        </>
    )


}

export default Header

