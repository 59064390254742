import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Dropdown, Calendar, Modal, Form, Select, Input, DatePicker, TimePicker } from 'antd';
import { DownOutlined, MenuUnfoldOutlined, MenuFoldOutlined, ExclamationCircleOutlined, } from '@ant-design/icons';
import axios from 'axios';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import { setAuthToken } from '../helpers/setAuthToken';
import { encriptionKey, decrypt } from "./utils";
import { api_url, facultyPath } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/responsive.css';

const { Header, Sider, Content } = Layout;
const { TextArea } = Input;

const TeacherLayout = ({ children }) => {
    const [form] = Form.useForm();
    const [collapsed, setCollapsed] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingDuration, setLoadingDuration] = useState(true);
    const [loadingTime, setTimeLoading] = useState(true);
    const [courseTypeLoading, setCourseTypeLoading] = useState(true);
    const [zoomTypeLoading, setZoomTypeLoading] = useState(true);
    const [selectedMenuTitle, setSelectedMenuTitle] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDateLoading, setSelectedDateLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [timeSlot, settimeSlot] = useState({ label: '', value: '' });
    const [courseType, setCourseType] = useState({ label: '', value: '' });
    const [zoomType, setZoomType] = useState({ label: '', value: '' });
    const [events, setEvents] = useState([{ id: "", date: "", time: "", status: "" },]);
    const [PassModalvisible, setPassModalvisible] = useState(false);

    let date = new Date().toLocaleDateString('en-us', { day: "numeric" });
    let day = new Date().toLocaleDateString('en-us', { weekday: "long" });
    let month = new Date().toLocaleDateString('en-us', { month: "long" });
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [classDuration, setClassDuration] = useState(0);

    let year = new Date().getFullYear();

    const path = window.location.pathname;
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const access_token = JSON.parse(localStorage.getItem('user'));
    if (access_token) {
        setAuthToken(access_token.token);
    }
    const logout = () => {
        localStorage.removeItem('user')
        setIsLoggedIn(false);

    }
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const handleStartTimeChange = (startValue) => {
        const endValue = endTime;
        setStartTime(startValue);

        if (startValue && endValue) {
            const duration = endValue.diff(startValue, 'minutes');
            setClassDuration(duration);
            setLoadingDuration(false)

        }
    };

    const handleEndTimeChange = (endValue) => {
        const startValue = startTime;
        setEndTime(endValue);

        if (startValue && endValue) {
            const duration = endValue.diff(startValue, 'minutes');
            // console.log(duration);
            setClassDuration(duration);
            setLoadingDuration(false)

        }
    };

    const fetchEventData = async () => {
        await axios.post(api_url + 'faculty/get-live-classes-event', {
            faculty_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.live_class;

            setEvents(pbs_data);
            setLoading(false)

        })
    }

    const fetchCourseType = (e) => {

        axios.get(api_url + 'get-course-type')
            .then(result => {

                if (!result.data.status) {
                    if (result.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }
                if (result.data.status) {

                    const course_type = result.data.course_type;

                    const c_options = course_type.map(element => ({
                        label: element.name,
                        value: element.id
                    }));

                    setCourseType(c_options);
                    setCourseTypeLoading(false);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }

    const fetchZoomCredentials = (e) => {

        axios.get(api_url + 'ajax/get-zoom-credentials')
            .then(result => {

                if (!result.data.status) {
                    if (result.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }
                if (result.data.status) {

                    const zoom_type = result.data.zoom;

                    const c_options = zoom_type.map(element => ({
                        label: element.name,
                        value: element.id
                    }));

                    setZoomType(c_options);
                    setZoomTypeLoading(false);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }

    useEffect(() => {
        fetchEventData()
        fetchCourseType()
        fetchZoomCredentials()
    }, []) // eslint-disable-line

    const handleChange = (e) => {

        axios.post(api_url + 'faculty/get-faculty-time-slot', {
            course: e,
            date: selectedDate,
            faculty_id: user_data.id,
        })
            .then(result => {
                //console.log(result);
                if (!result.data.status) {
                    if (result.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }
                if (result.data.status) {
                    const time_slot = result.data.data;

                    const options = time_slot.map(element => ({
                        label: element.name,
                        value: element.id
                    }));

                    settimeSlot(options);
                    setTimeLoading(false)
                    //console.log(options);
                    return false;

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }

    const handleSelect = (date) => {

        form.resetFields();
        setSelectedDate(date);
        setSelectedDateLoading(false);
        setModalVisible(true);

    };

    const MainItems = [
        {
            title: 'Dashboard',
            path: '/teacher-dashboard/dashboard',
            icon: '/images/calendar-img.png'
        },
        {
            title: 'My Student',
            icon: '/images/immigration.png',
            path: '/teacher-dashboard/my-student',

        },

        {
            title: 'My Classes',
            icon: '/images/immigration.png',
            path: '/teacher-dashboard/my_classes',

        },
        {

            title: 'Doubt Section',
            path: '/teacher-dashboard/doubt-section',
            icon: '/images/doubt.png'
        },
        {
            title: 'PTM Session',
            icon: '/images/PBS-session.png',
            path: '/teacher-dashboard/ptm-session',

        },
    ]

    const DiagnosticItems = [
        {
            title: 'Diagnostic Students',
            path: '/teacher-dashboard/diagnostic-student',
            icon: '/images/performance-report.png'
        },
    ];

    const AttendanceItems = [
        {
            title: 'Attendance Report',
            path: '/teacher-dashboard/class-attendance',
            icon: '/images/performance-report.png'
        },
    ];

    let menuItems = [];

    if (user_data.diagnostic_test === 'Yes') {
        menuItems = MainItems.concat(DiagnosticItems);
        menuItems = MainItems.concat(AttendanceItems);
    } else {
        menuItems = MainItems.concat(AttendanceItems);
    }

    const items = [
        {
            key: '1',
            label: (
                <a href='/' onClick={logout}>Logout</a>
            ),
        },
        {
            key: '2',
            label: (
                <button type='button' className=' bg-transparent border-none p-0 m-0 cursor-pointer' onClick={() => setPassModalvisible(true)}>Change Password</button>
            ),
        },

    ]

    const handleCancel = () => {
        form.resetFields();
        setPassModalvisible(false)
    };

    const handlePassword = async (values) => {
        try {
            const res = await axios.post(api_url + 'change-password', {
                user_id: user_data.id,
                password: values.currentPassword,
                new_password: values.newPassword,
                confirm_password: values.confirmPassword
            });

            // console.log(res); // log the response data to the console
            if (res.data.status) {

                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

                const timer = setTimeout(() => { // eslint-disable-line
                    setPassModalvisible(false)
                    window.location.reload();
                }, 3000);

            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

            }

        } catch (error) {
            //console.log(error);
        }
    };

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 12 },
    };

    const onFinish = (values) => {

        axios.post(api_url + 'faculty/create-class', {
            data: values,
            date: selectedDate.format("YYYY-MM-DD"),
            faculty_id: user_data.id,
        })
            .then(result => {
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => { // eslint-disable-line
                        setModalVisible(false);
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })

    };

    const dateCellRender = (date) => {
        const formattedDate = date.format('YYYY-MM-DD');

        // Filter events to get only those matching the formatted date
        const options = events
            .filter((element) => element.date === formattedDate)
            .map((element) => ({
                id: element.id,
                date: element.date,
                event: "Event - " + element.status,
            }));

        if (options.length > 0) {
            return (
                <ul className="events">
                    {options.map((item) => (
                        // Assuming you want to render something based on item.id
                        // Add content inside the <li> if needed
                        <li key={item.id}>{item.event}</li>
                    ))}
                </ul>
            );
        }

        return null;
    };

    useEffect(() => {
        const handleResize = () => {
            setCollapsed(window.innerWidth <= 1024);
        };

        handleResize(); // Call the function once to set the initial state

        window.addEventListener('resize', handleResize); // Listen for window resize events

        return () => {
            window.removeEventListener('resize', handleResize); // Clean up the event listener
        };
    }, []);

    return (
        <div className="dashboardSection">
            <ToastContainer />
            <Layout>
                <Header className='sticky top-0 z-10'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #F1F1F1',
                        padding: '0 24px',
                        width: '100%',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            className="trigger"
                            onClick={toggleCollapse}
                            style={{ marginRight: '16px' }}
                        >
                            {collapsed ? (
                                <MenuUnfoldOutlined className='text-2xl' />
                            ) : (
                                <MenuFoldOutlined className='text-2xl' />
                            )}
                        </div>
                        <img
                            src="/images/allen-global-logo.png"
                            width="150px"
                            alt="company logo"
                        // style={{ marginRight: '10px', paddingBottom: 10 }}
                        />
                        <ExclamationCircleOutlined className='flex justify-center items-center text-[#0F4275] text-xl pl-24 dashboard-menu-title' />
                        <h1 className="text-[#F0AA0C] text-lg pl-2.5 dashboard-menu-title">
                            {selectedMenuTitle || 'Faculty Dashboard'}
                        </h1>
                    </div>
                    <div className="flex items-center">


                        <div className="flex justify-center items-center">
                            {user_data.image == null ? (
                                <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                            ) : (
                                <img src={facultyPath + user_data.image} className="user-img flex justify-start items-center" alt="user avatar" />
                            )}

                        </div>

                        <Dropdown menu={{ items }} className='pt-5'>
                            <DownOutlined className='flex justify-center items-center pl-2.5' />
                        </Dropdown>
                        <Modal
                            open={PassModalvisible}
                            title="Change Password"
                            onCancel={handleCancel}
                            footer={null}
                        >
                            <Form form={form} onFinish={handlePassword}>
                                <Form.Item
                                    name="currentPassword"
                                    label="Current Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your current password!',
                                        },
                                    ]}
                                >
                                    <Input.Password name="password" />
                                </Form.Item>
                                <Form.Item
                                    name="newPassword"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your new password!',
                                        },
                                    ]}
                                >
                                    <Input.Password name="new_password" />
                                </Form.Item>
                                <Form.Item
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    dependencies={['newPassword']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your new password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password name="confirm_password" />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 6 }}>
                                    <Button type="primary" htmlType="submit" className='bg-[#F0AA0C]'>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                </Header>
                <Layout>
                    <Sider
                        width={300}
                        trigger={null}
                        style={{ height: '93vh' }}
                        id="main-sidebar"
                        collapsible
                        collapsed={collapsed}
                        //onCollapse={toggleCollapse}
                        onCollapse={setCollapsed}
                        className={collapsed ? 'collapsed-sider' : ''}
                    >

                        <div className={`flex my-5 ${collapsed ? 'collapsed-sider-content' : ''}`}>
                            {user_data.image == null ? (
                                collapsed ? null : <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                            ) : (
                                <img src={facultyPath + user_data.image} className="user-img flex justify-start items-center" alt="user avatar" />
                            )}

                            {!collapsed && (
                                <div className='pl-2.5 pb-5'>
                                    <h3 className='text-[#707070] text-base'>{decrypt(user_data.name, encriptionKey)}</h3>
                                </div>
                            )}
                        </div>

                        <Menu
                            theme='light'
                            mode="inline"
                            defaultSelectedKeys={[window.location.pathname]}
                            defaultOpenKeys={['sub1']}
                            className={collapsed ? 'collapsed-menu' : ''}
                        >
                            {menuItems.map((item, subm) => (
                                item.subMenu ?
                                    <Menu.SubMenu key={subm}
                                        style={{ paddingLeft: collapsed ? 10 : 15 }}
                                        title={
                                            // <div className='flex items-center'>
                                            //     <img src={item.icon} alt={item.title} className='flex items-center justify-center' style={{ width: collapsed ? '35px' : '24px', height: collapsed ? '35px' : '24px', }} />
                                            //     {!collapsed && (
                                            //         <span className='pl-2.5 text-[#0F4275] text-base font-medium' style={{ paddingLeft: collapsed ? 10 : 12 }}>{item.title}</span>
                                            //     )}
                                            // </div>
                                            <div className='flex items-center'>
                                                {collapsed ? null : (
                                                    <img
                                                        src={item.icon}
                                                        alt={item.title}
                                                        className='flex items-center justify-center'
                                                        style={{ width: collapsed ? '35px' : '24px', height: collapsed ? '35px' : '24px' }}
                                                    />
                                                )}
                                                {!collapsed && (
                                                    <span className='pl-2.5' style={{ paddingLeft: collapsed ? 10 : 5 }}>{item.title}</span>
                                                )}
                                            </div>
                                        }
                                        onClick={() => setSelectedMenuTitle(item.title)}
                                    >
                                        {item.subMenu.map(item =>
                                            <Menu.Item key={item.path}  >
                                                <Link to={item.path}>{item.title}</Link>
                                            </Menu.Item>
                                        )}
                                    </Menu.SubMenu>
                                    :
                                    <Menu.Item
                                        key={item.path}
                                        title={item.title}
                                        style={{ paddingLeft: collapsed ? 10 : '15px' }}
                                        onClick={() => setSelectedMenuTitle(item.title)}>
                                        <div className='flex items-center'>
                                            <Link
                                                to={item.path} >
                                                <img
                                                    src={item.icon}
                                                    alt={item.title}
                                                    className='flex items-center justify-center'
                                                    style={{ width: collapsed ? '35px' : '24px', height: collapsed ? '35px' : '24px', }} />
                                            </Link>

                                            {!collapsed && (
                                                <Link to={item.path} className='pl-2.5'>{item.title}</Link>
                                            )}
                                        </div>
                                    </Menu.Item>
                            ))}
                        </Menu>
                    </Sider>
                    <div className='dash-cal-sesseion'>
                        {isLoggedIn}
                        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
                            {loading ? (
                                <div className="loading-spinner">
                                    {/* Add your loading spinner here */}
                                </div>
                            ) : (
                                children
                            )}
                        </Content>

                        {path === '/teacher-dashboard/dashboard' ? (
                            <Sider width={300} style={{ background: '#fff', }}>
                                <div className='pt-5 cal-top'>
                                    <span className='text-[#30203A] text-base pl-2.5'>Hi,</span>
                                    <h4 className='text-[#30203A] text-base mb-5 pl-2.5'>{decrypt(user_data.name, encriptionKey)}</h4>
                                    <img src='/images/right-banner.png' alt='right-banner' />

                                    <div className='current-date'>
                                        <div className='date-info'>
                                            <h2>{date} {day}</h2>
                                            <span>{month} {year}</span>
                                        </div>
                                    </div>
                                </div>
                                <Calendar className='cal' dateCellRender={dateCellRender} fullscreen={false} onSelect={handleSelect} />
                                <Modal
                                    title="Create Your Class"
                                    open={modalVisible}
                                    onOk={() => setModalVisible(false)}
                                    onCancel={() => setModalVisible(false)}
                                    footer={null}
                                >
                                    <Form {...layout} onFinish={onFinish}>
                                        {courseTypeLoading ? ('') : (
                                            <Form.Item label="Course Type" name="course" rules={[{ required: true }]}>
                                                <Select onChange={handleChange}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder="Select Slot"
                                                    options={courseType}

                                                />

                                            </Form.Item>
                                        )}
                                        {loadingTime ? ('') : (
                                            <Form.Item label="Time" name="time" rules={[{ required: true }]}>
                                                <Select
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder="Select Slot"
                                                    options={timeSlot}

                                                />
                                            </Form.Item>
                                        )}
                                        {selectedDateLoading ? ('') : (<>
                                            <Form.Item
                                                name="class_date"
                                                label="Select Date"
                                                rules={[{ required: true, message: 'Please select a date' }]}
                                                initialValue={selectedDate}
                                            >
                                                <DatePicker style={{ width: '100%' }} />
                                            </Form.Item>
                                        </>)}


                                        <Form.Item label="Topic" name="topic" rules={[{ required: true }]}>
                                            <Input placeholder="Enter the topic" />
                                        </Form.Item>
                                        <Form.Item name="start_time" label="Start Time" rules={[{ required: true, message: 'Please select start time' }]}>
                                            <TimePicker format="HH:mm" style={{ width: '100%' }} onChange={handleStartTimeChange} />
                                        </Form.Item>
                                        <Form.Item name="end_time" label="End Time" rules={[{ required: true, message: 'Please select end time' }]}>
                                            <TimePicker format="HH:mm" style={{ width: '100%' }} onChange={handleEndTimeChange} />
                                        </Form.Item>

                                        {loadingDuration ? ('') : (<>
                                            <Form.Item
                                                name="duration"
                                                initialValue={classDuration}
                                                disabled={true}
                                                label="Duration (min)"
                                            >
                                                <Input placeholder="Enter the Duration" />
                                            </Form.Item>
                                        </>)}

                                        <Form.Item label="Module" name="module" >
                                            <Input placeholder="Enter the Module" />
                                        </Form.Item>

                                        <Form.Item label="Description" name="description" >
                                            <TextArea rows={4} placeholder="Enter a brief description" />
                                        </Form.Item>
                                        {zoomTypeLoading ? ('') : (
                                            <Form.Item label="Zoom Id" name="license_id" rules={[{ required: true }]}>
                                                <Select onChange={handleChange}
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder="Select Slot"
                                                    options={zoomType}

                                                />

                                            </Form.Item>
                                        )}

                                        <Form.Item wrapperCol={{ offset: 6 }}>
                                            <Button type="primary" htmlType="submit" className='bg-[#F0AA0C]'>
                                                Submit
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Modal>

                            </Sider>
                        ) : ('')}
                        <Footer />
                    </div>
                </Layout>
            </Layout>

        </div>
    )
}



export default TeacherLayout