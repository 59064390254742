import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Define your handleContextMenu function
const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default right-click behavior
}

const disableRightClick = (event) => {
    if (event.button === 2) {
        event.preventDefault();
        //alert('Right-clicking is disabled.');
    }
};

// Attach event listener to the document


// Render your React application
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// Attach the context menu event listener to the document
document.addEventListener('contextmenu', handleContextMenu);
document.addEventListener('contextmenu', disableRightClick);

document.addEventListener('keydown', function (event) {
    if (event.keyCode === 123) {
        event.preventDefault(); // Prevent default action (opening developer tools)
    }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
