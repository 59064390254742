import React from 'react';
import '../styles/loader.css';

const Loader = ({ loading }) => {
  return (
    <>
      <div id="overlay" style={{ display: loading ? 'block' : 'none' }}></div>
      <div id="loadingSpinner" className="loadingio-spinner-spinner-nq4q5u6dq7r" style={{ display: loading ? 'block' : 'none' }}>
        <div className="ldio-x2uulkbinbj">
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
    </>
  );
};

export default Loader;