import React from "react";
import '../styles/counsellor.css';
import { Row, Col, Layout } from "antd";
import TeacherLayout from "../components/teacher-layout";
const { Content } = Layout;

const Quiz = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }

    // const questions = [
    //     {
    //         question: "How many days makes a week ?",
    //         optionA: "10 days",
    //         optionB: "14 days",
    //         optionC: "5 days",
    //         optionD: "7 days",
    //         correctOption: "optionD"
    //     },

    //     {
    //         question: "How many players are allowed on a soccer pitch ?",
    //         optionA: "10 players",
    //         optionB: "11 players",
    //         optionC: "9 players",
    //         optionD: "12 players",
    //         correctOption: "optionB"
    //     },

    //     {
    //         question: "Who was the first President of USA ?",
    //         optionA: "Donald Trump",
    //         optionB: "Barack Obama",
    //         optionC: "Abraham Lincoln",
    //         optionD: "George Washington",
    //         correctOption: "optionD"
    //     },

    //     {
    //         question: "30 days has ______ ?",
    //         optionA: "January",
    //         optionB: "December",
    //         optionC: "June",
    //         optionD: "August",
    //         correctOption: "optionC"
    //     },

    //     {
    //         question: "How manay hours can be found in a day ?",
    //         optionA: "30 hours",
    //         optionB: "38 hours",
    //         optionC: "48 hours",
    //         optionD: "24 hours",
    //         correctOption: "optionD"
    //     },

    //     {
    //         question: "Which is the longest river in the world ?",
    //         optionA: "River Nile",
    //         optionB: "Long River",
    //         optionC: "River Niger",
    //         optionD: "Lake Chad",
    //         correctOption: "optionA"
    //     },

    //     {
    //         question: "_____ is the hottest Continent on Earth ?",
    //         optionA: "Oceania",
    //         optionB: "Antarctica",
    //         optionC: "Africa",
    //         optionD: "North America",
    //         correctOption: "optionC"
    //     },

    //     {
    //         question: "Which country is the largest in the world ?",
    //         optionA: "Russia",
    //         optionB: "Canada",
    //         optionC: "Africa",
    //         optionD: "Egypt",
    //         correctOption: "optionA"
    //     },

    //     {
    //         question: "Which of these numbers is an odd number ?",
    //         optionA: "Ten",
    //         optionB: "Twelve",
    //         optionC: "Eight",
    //         optionD: "Eleven",
    //         correctOption: "optionD"
    //     },

    //     {
    //         question: `"You Can't see me" is a popular saying by`,
    //         optionA: "Eminem",
    //         optionB: "Bill Gates",
    //         optionC: "Chris Brown",
    //         optionD: "John Cena",
    //         correctOption: "optionD"
    //     },

    //     {
    //         question: "Where is the world tallest building located ?",
    //         optionA: "Africa",
    //         optionB: "California",
    //         optionC: "Dubai",
    //         optionD: "Italy",
    //         correctOption: "optionC"
    //     },

    //     {
    //         question: "The longest river in the United Kingdom is ?",
    //         optionA: "River Severn",
    //         optionB: "River Mersey",
    //         optionC: "River Trent",
    //         optionD: "River Tweed",
    //         correctOption: "optionA"
    //     },


    //     {
    //         question: "How many permanent teeth does a dog have ?",
    //         optionA: "38",
    //         optionB: "42",
    //         optionC: "40",
    //         optionD: "36",
    //         correctOption: "optionB"
    //     },

    //     {
    //         question: "Which national team won the football World cup in 2018 ?",
    //         optionA: "England",
    //         optionB: "Brazil",
    //         optionC: "Germany",
    //         optionD: "France",
    //         correctOption: "optionD"
    //     },

    //     {
    //         question: "Which US state was Donald Trump Born ?",
    //         optionA: "New York",
    //         optionB: "California",
    //         optionC: "New Jersey",
    //         optionD: "Los Angeles",
    //         correctOption: "optionA"
    //     },

    //     {
    //         question: "How man states does Nigeria have ?",
    //         optionA: "24",
    //         optionB: "30",
    //         optionC: "36",
    //         optionD: "37",
    //         correctOption: "optionC"
    //     },

    //     {
    //         question: "____ is the capital of Nigeria ?",
    //         optionA: "Abuja",
    //         optionB: "Lagos",
    //         optionC: "Calabar",
    //         optionD: "Kano",
    //         correctOption: "optionA"
    //     },

    //     {
    //         question: "Los Angeles is also known as ?",
    //         optionA: "Angels City",
    //         optionB: "Shining city",
    //         optionC: "City of Angels",
    //         optionD: "Lost Angels",
    //         correctOption: "optionC"
    //     },

    //     {
    //         question: "What is the capital of Germany ?",
    //         optionA: "Georgia",
    //         optionB: "Missouri",
    //         optionC: "Oklahoma",
    //         optionD: "Berlin",
    //         correctOption: "optionD"
    //     },

    //     {
    //         question: "How many sides does an hexagon have ?",
    //         optionA: "Six",
    //         optionB: "Sevene",
    //         optionC: "Four",
    //         optionD: "Five",
    //         correctOption: "optionA"
    //     },

    //     {
    //         question: "How many planets are currently in the solar system ?",
    //         optionA: "Eleven",
    //         optionB: "Seven",
    //         optionC: "Nine",
    //         optionD: "Eight",
    //         correctOption: "optionD"
    //     },

    //     {
    //         question: "Which Planet is the hottest ?",
    //         optionA: "Jupitar",
    //         optionB: "Mercury",
    //         optionC: "Earth",
    //         optionD: "Venus",
    //         correctOption: "optionB"
    //     },

    //     {
    //         question: "where is the smallest bone in human body located?",
    //         optionA: "Toes",
    //         optionB: "Ears",
    //         optionC: "Fingers",
    //         optionD: "Nose",
    //         correctOption: "optionB"
    //     },

    //     {
    //         question: "How many hearts does an Octopus have ?",
    //         optionA: "One",
    //         optionB: "Two",
    //         optionC: "Three",
    //         optionD: "Four",
    //         correctOption: "optionC"
    //     },

    //     {
    //         question: "How many teeth does an adult human have ?",
    //         optionA: "28",
    //         optionB: "30",
    //         optionC: "32",
    //         optionD: "36",
    //         correctOption: "optionC"
    //     }

    // ]



    return (
        <TeacherLayout>
            <Content
                className="site-layout-background"
                style={{
                    margin: '20px 16px 24px',
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="mx-16 mt-10">
                    <Row>
                        <Col md={24}>
                            <div className="bg-[#114D89] px-5 py-2.5">
                                <h1 className="text-white text-2xl ">Class 9th Student Records</h1>
                            </div>
                        </Col>
                    </Row>
                    <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>

                        <main onload="NextQuestion(0)">

                            <div className="modal-container" id="score-modal">

                                <div className="modal-content-container">

                                    <h1>Congratulations, Quiz Completed.</h1>

                                    <div className="grade-details">
                                        <p>Attempts : 10</p>
                                        <p>Wrong Answers : <span id="wrong-answers"></span></p>
                                        <p>Right Answers : <span id="right-answers"></span></p>
                                        <p>Grade : <span id="grade-percentage"></span>%</p>
                                        <p ><span id="remarks"></span></p>
                                    </div>

                                    <div className="modal-button-container">
                                        <button onclick="closeScoreModal()">Continue</button>
                                    </div>

                                </div>
                            </div>

                            <div className="game-quiz-container">

                                <div className="game-details-container">
                                    <h1>Score : <span id="player-score"></span> / 10</h1>
                                    <h1> Question : <span id="question-number"></span> / 10</h1>
                                </div>

                                <div className="game-question-container">
                                    <h1 id="display-question">''</h1>
                                </div>

                                <div className="game-options-container">

                                    <div className="modal-container" id="option-modal">

                                        <div className="modal-content-container">
                                            <h1>Please Pick An Option</h1>

                                            <div className="modal-button-container">
                                                <button onclick="closeOptionModal()">Continue</button>
                                            </div>

                                        </div>

                                    </div>

                                    <span>
                                        <input type="radio" id="option-one" name="option" className="radio" value="optionA" />
                                        <label for="option-one" className="option" id="option-one-label"></label>
                                    </span>


                                    <span>
                                        <input type="radio" id="option-two" name="option" className="radio" value="optionB" />
                                        <label for="option-two" className="option" id="option-two-label"></label>
                                    </span>


                                    <span>
                                        <input type="radio" id="option-three" name="option" className="radio" value="optionC" />
                                        <label for="option-three" className="option" id="option-three-label"></label>
                                    </span>


                                    <span>
                                        <input type="radio" id="option-four" name="option" className="radio" value="optionD" />
                                        <label for="option-four" className="option" id="option-four-label"></label>
                                    </span>


                                </div>

                                <div className="next-button-container">
                                    <button onclick="handleNextQuestion()">Next Question</button>
                                </div>

                            </div>
                        </main>

                    </div>
                </div>
            </Content>
        </TeacherLayout>
    )
}

export default Quiz