import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Button, Row, Col, Tabs, Timeline, Modal, Input, Form, Layout } from 'antd';

import { useParams, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { decrypt, encriptionKey } from '../components/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url } from '../components/constants';
import DashboardLayout from '../components/dashboard-layout';
const { Content } = Layout;

const RecordTimeline = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }

    const [loading, setLoading] = useState(true)
    const [pbsData, setPBSData] = useState([{ id: "", date: "", time: "", status: "", mentor: "", link: "", test_records: "", academic_records: "", internship_records: "", suggestion_records: "" }]);
    const [testData, setTestData] = useState(null);
    const [academicData, setAcademicData] = useState(null);
    const [internData, setInternData] = useState(null);
    const [suggestioData, setSuggestionData] = useState(null);

    useEffect(() => {
        const pbsTimeline = () => {
            axios.post(api_url + 'student-pbs-timeline', {
                pbs_id: id
            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    } else {
                        navigate('/counsellor-dashboard/dashboard');
                    }
                }

                if (res.data.pbs_data.length > 0) {

                    const st_list = res.data.pbs_data;
                    //console.log(st_list);
                    const test_target = res.data.test_target_array;
                    const academic_target = res.data.academic_target_array;
                    const st_intern_data = res.data.st_intern_array;
                    const st_suggestion_array = res.data.st_suggestion_array;
                    if (academic_target.length > 0) {
                        setAcademicData(JSON.parse(academic_target))
                    }
                    if (test_target.length > 0) {
                        setTestData(JSON.parse(test_target));
                    }
                    if (st_intern_data.length > 0) {
                        setInternData(JSON.parse(st_intern_data))
                    }
                    if (st_suggestion_array.length > 0) {
                        setSuggestionData(JSON.parse(st_suggestion_array))
                    }

                    setPBSData(st_list);


                }
                setLoading(false)

            })
        }
        pbsTimeline()
    }, []) // eslint-disable-line


    const items = [
        {
            key: '1',
            label: `STUDENT TARGETS`,
            children: <StudentTarget data={testData} />,
        },
        {
            key: '2',
            label: `ACADEMICS TARGETS`,
            children: <AcademicTarget data={academicData} />,
        },
        {
            key: '3',
            label: `INTERNSHIP ASSISTANCE`,
            children: <InternshipAssistance data={internData} />,
        },
        {
            key: '4',
            label: `PROFILE BUILDING SUGGESTIONS`,
            children: <ProfileBuilding pbs_id={id} data={suggestioData} />,
        },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <ToastContainer />
            <DashboardLayout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <Row gutter={30}>

                        <Col md={24}>
                            {loading ? (
                                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                            ) : (
                                <>
                                    <div className="form-layout" style={{ background: '#F5F5F5' }}>
                                        <div className="bg-[#0F4275] py-2.5 px-7">
                                            <h1 className="text-[#fff] text-2xl uppercase text-center">PBS Details</h1>
                                        </div>
                                    </div>
                                    <div className="mx-10 my-7 p-5 bg-[#FBFBFB]" style={{ borderRadius: 29 }}>
                                        <Row gutter={30}>
                                            <Col md={6}>
                                                <div className="flex pb-2.5">
                                                    <div className="bg-white p-3.5 rounded-full" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                        <img src="/images/calendar.png" alt='calendar-img' />
                                                    </div>
                                                    <span className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">Date : {formatDate(pbsData[0].date)}</span>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="flex pb-2.5">
                                                    <div className="bg-white p-3.5 rounded-full" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                        <img src="/images/clock.png" alt='clock-img' />
                                                    </div>
                                                    <span className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">Time : {pbsData[0].time}</span>
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="flex pb-2.5">
                                                    <div className="bg-white p-3.5 rounded-full" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                        <img src="/images/presentation.png" alt='presentation-img' />
                                                    </div>
                                                    <span className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">
                                                        Suggestted By Mentor : {decrypt(pbsData[0].counsellor, encriptionKey)}

                                                    </span>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>

                                    <Tabs defaultActiveKey="1" items={items} />
                                </>
                            )}

                        </Col>
                    </Row>
                </Content>

            </DashboardLayout>
        </>
    )
}

const StudentTarget = (props) => {

    return (
        <>
            <Row>
                <Col md={24} className="form-space">

                    <Timeline mode="left" >

                        <div className="bg-[#fff] " style={{ borderRadius: 15 }}>
                            <table className="target">
                                <thead>
                                    <tr>
                                        <th>Exam</th>
                                        <th>Your Achivement</th>
                                        <th>Assign Target</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data != null ? (
                                        props.data[0].test_data.map((value, k) => (
                                            <tr key={k}>
                                                <td>{value.test}</td>
                                                <td>{value.achive}</td>
                                                <td>{value.assign}</td>
                                            </tr>
                                        ))
                                    ) : (<div className="my-24">
                                        <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                    </div>)}

                                </tbody>
                            </table>
                        </div>

                    </Timeline>
                </Col>
            </Row>
        </>
    )
}

const AcademicTarget = (props) => {
    //console.log(props);
    return (
        <>
            <Row>
                <Col md={24} className="form-space">
                    <Timeline mode="left" >
                        <div className="bg-[#fff]" style={{ borderRadius: 15 }}>
                            <table className="target">
                                <thead>
                                    <tr>
                                        <th>Class</th>
                                        <th>Your Achivement</th>
                                        <th>Assign Target</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data != null ? (
                                        props.data[0].academic_data.map((value, k) => (
                                            <tr key={k}>
                                                <td>{value.grade}</td>
                                                <td>{value.achive}</td>
                                                <td>{value.assign}</td>

                                            </tr>
                                        ))
                                    ) : (<div className="my-24">
                                        <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                    </div>)}
                                </tbody>
                            </table>
                        </div>

                    </Timeline>
                </Col>
            </Row>
        </>
    )
}

const InternshipAssistance = (props) => {

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <Row>
                <Col md={24} className="form-space">
                    <Timeline mode="left" >
                        <div className="bg-[#fff]" style={{ borderRadius: 15 }}>
                            <table className="target">
                                <thead>
                                    <tr>
                                        <th>Subject</th>
                                        <th>Suggestion</th>
                                        <th>Remark</th>
                                        <th>Deadline</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data != null ? (
                                        props.data[0].intern_data.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.subject}</td>
                                                <td>{row.suggestion}</td>
                                                <td>{row.remark}</td>
                                                <td>{formatDate(row.deadline)}</td>
                                            </tr>
                                        ))
                                    ) : (<div className="my-24">
                                        <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                    </div>)}
                                </tbody>
                            </table>
                        </div>

                    </Timeline>
                </Col>
            </Row>
        </>

    )
}

const ProfileBuilding = (props) => {
    //console.log(props);
    const [modalVisible, setIsModalVisible] = useState(false);
    const [studentOutput, setStudentOutputData] = useState({ output: "" })
    const [studentOutputIndex, setStudentOutputIndex] = useState("")
    const handleChange = (name, value) => {
        setStudentOutputData(value);
    };

    const openOutputModal = (key, value) => {
        setStudentOutputIndex(key);
        setStudentOutputData(value);
        setIsModalVisible(true);
    }

    const handleSubmit = () => {

        axios.post(api_url + 'update_student_pbs_output', {
            pbs_id: props.pbs_id,
            output: studentOutput,
            index: studentOutputIndex,
        })
            .then(result => {
                if (result.data.status) {

                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => { // eslint-disable-line
                        setIsModalVisible(false);
                        window.location.reload(true);
                    }, 3000);

                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Something went wrong'
                    });
                }
            })
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <Row>
                <Col md={24} className="form-space">
                    <Timeline mode="left" >

                        <div className="bg-[#fff]" style={{ borderRadius: 15 }}>
                            <table className="target">
                                <thead>
                                    <tr>
                                        <th>Suggestion</th>
                                        <th>Follow Up Date</th>
                                        <th>Student Output</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data != null ? (
                                        props.data[0].suggestion_data.map((row, index) => (
                                            <tr key={index}>
                                                <td>{row.suggestion}</td>
                                                <td>{formatDate(row.deadline)}</td>
                                                <td>{row.output}</td>
                                                <td>
                                                    <Button className="bg-[#0F4275] text-white px-7" style={{ borderRadius: 20, border: '1px solid #0F4275' }} onClick={() => openOutputModal(index, row.output)}>
                                                        Enter your Feedback
                                                    </Button>
                                                    {modalVisible && <Modal title="PROFILE BUILDING SUGGESTIONS(ACADEMIC & NON - ACADEMIC )"
                                                        width={800}
                                                        open={modalVisible}
                                                        onOk={handleSubmit}
                                                        onCancel={() => setIsModalVisible(false)}
                                                        okText='Update'
                                                        okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 20, padding: '5px 40px 35px', fontSize: 18 } }}
                                                        cancelButtonProps={{ style: { background: '#717070', borderRadius: 20, padding: '5px 40px 35px', fontSize: 18, color: '#fff' } }}
                                                    >

                                                        <h3 className="text-[#0F4275] text-2xl mb-5">Student Out Message</h3>
                                                        <Form
                                                            name="normal_login"
                                                            className="my-7 mx-5"
                                                        >
                                                            <Form.Item
                                                                name="output"
                                                                label="Output"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                initialValue={studentOutput}
                                                            >
                                                                <Input.TextArea type='address'
                                                                    onChange={(e) => handleChange('output', e.target.value)}
                                                                />
                                                            </Form.Item>
                                                        </Form>
                                                    </Modal>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (<div className="my-24">
                                        <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                    </div>)}
                                </tbody>
                            </table>
                        </div>
                    </Timeline>
                </Col>
            </Row>
        </>

    )
}


export default RecordTimeline