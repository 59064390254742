import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Input, Layout, Select, } from 'antd';
import '../styles/dashboard.css';
import axios from 'axios';
import DashboardLayout from '../components/dashboard-layout';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url } from '../components/constants';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const { Content } = Layout;
const { TextArea } = Input;

const SchoolRecord = () => {
    const [class_9, setClass_9] = useState({ percentage_9: '', date_9: new Date(), city_9: '', subject_9: '', file_9: '', board_9: '', school_9: '' });
    const [class_10, setClass_10] = useState({ percentage_10: '', date_10: new Date(), city_10: '', subject_10: '', file_10: '', board_10: '', school_10: '' });
    const [class_11, setClass_11] = useState({ percentage_11: '', date_11: new Date(), city_11: '', subject_11: '', file_11: '', board_11: '', school_11: '' });
    const [class_12mid, setClass_12mid] = useState({ percentage_12mid: '', date_12mid: new Date(), city_12mid: '', subject_12mid: '', file_12mid: '', board_12mid: '', school_12mid: '' });
    const [class_12, setClass_12] = useState({ percentage_12: '', date_12: new Date(), city_12: '', subject_12: '', file_12: '', board_12: '', school_12: '' });
    const [streamData, setStreamData] = useState([]);
    const [file9, setFile9] = useState(null);
    const [file10, setFile10] = useState(null);
    const [file11, setFile11] = useState(null);
    const [file12mid, setFile12mid] = useState(null);
    const [file12, setFile12] = useState(null);
    const [startDate_9, setStartDate_9] = useState(new Date());
    const [startDate_10, setStartDate_10] = useState(new Date());
    const [startDate_11, setStartDate_11] = useState(new Date());
    const [startDate_12mid, setStartDate_12mid] = useState(new Date());
    const [startDate_12, setStartDate_12] = useState(new Date());
    const [about, setAbout] = useState(null);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountries] = useState([]);
    const [selectedStream, setSelectedStream] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingCountry, setCountryLoading] = useState(true); // eslint-disable-line
    const [loadingStream, setStreamLoading] = useState(true); // eslint-disable-line

    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const handle9Change = (name, value) => {
        setClass_9({ ...class_9, [name]: value });
    }

    const handle10Change = (name, value) => {
        setClass_10({ ...class_10, [name]: value });
    }

    const handle11Change = (name, value) => {
        setClass_11({ ...class_11, [name]: value });
    }

    const handle12midChange = (name, value) => {
        setClass_12mid({ ...class_12mid, [name]: value });
    }

    const handle12Change = (name, value) => {
        setClass_12({ ...class_12, [name]: value });
    }

    const handleAboutChange = (name, value) => {
        setAbout(value)
    }

    const onFinish = (values) => {

        var event_9 = new Date(class_9.date_9);
        let date_9 = JSON.stringify(event_9)
        date_9 = date_9.slice(1, 11);

        var event_10 = new Date(class_10.date_10);
        let date_10 = JSON.stringify(event_10)
        date_10 = date_10.slice(1, 11)


        var event_11 = new Date(class_11.date_11);
        let date_11 = JSON.stringify(event_11)
        date_11 = date_11.slice(1, 11)

        var event_12mid = new Date(class_12mid.date_12mid);
        let date_12mid = JSON.stringify(event_12mid)
        date_12mid = date_12mid.slice(1, 11)

        var event_12 = new Date(class_12.date_12);
        let date_12 = JSON.stringify(event_12)
        date_12 = date_12.slice(1, 11)

        const formData = new FormData();

        if (file9 === null) {
            formData.append('file_9', class_9.file_9);
        } else {
            formData.append('file_9', file9);
        }

        if (file10 === null) {
            formData.append('file_10', class_10.file_10);
        } else {
            formData.append('file_10', file10);
        }

        if (file11 === null) {
            formData.append('file_11', class_11.file_11);
        } else {
            formData.append('file_11', file11);
        }

        if (file12mid === null) {
            formData.append('file_12_mid', class_12mid.file_12mid);
        } else {
            formData.append('file_12_mid', file12mid);
        }

        if (file12 === null) {
            formData.append('file_12', class_12.file_12);
        } else {
            formData.append('file_12', file12);
        }

        formData.append('percentage_9', class_9.percentage_9);
        formData.append('date_9', date_9);
        formData.append('city_9', class_9.city_9);
        formData.append('subject_9', class_9.subject_9);
        formData.append('school_9', class_9.school_9);
        formData.append('board_9', class_9.board_9);
        formData.append('user_id', user_data.id);
        formData.append('percentage_10', class_10.percentage_10);
        formData.append('date_10', date_10);
        formData.append('city_10', class_10.city_10);
        formData.append('school_10', class_10.school_10);
        formData.append('board_10', class_10.board_10);
        formData.append('subject_10', class_10.subject_10);
        formData.append('percentage_11', class_11.percentage_11);
        formData.append('date_11', date_11);
        formData.append('city_11', class_11.city_11);
        formData.append('subject_11', class_11.subject_11);
        formData.append('school_11', class_11.school_11);
        formData.append('board_11', class_11.board_11);
        formData.append('percentage_12mid', class_12mid.percentage_12mid);
        formData.append('date_12mid', date_12mid);
        formData.append('city_12mid', class_12mid.city_12mid);
        formData.append('subject_12mid', class_12mid.subject_12mid);
        formData.append('school_12mid', class_12mid.school_12mid);
        formData.append('board_12mid', class_12mid.board_12mid);
        formData.append('percentage_12', class_12.percentage_12);
        formData.append('date_12', date_12);
        formData.append('city_12', class_12.city_12);
        formData.append('subject_12', class_12.subject_12);
        formData.append('school_12', class_12.school_12);
        formData.append('board_12', class_12.board_12);

        let ckc_country = findValueInArray('value', values.country);
        let ckc_stream = findValueInArray('value', values.stream);
        let country_data = [];
        let stream_data = [];
        if (ckc_country) {
            //const num2x = values.country.map((n) => country_data.push(n.value));
        } else {
            //const num2x = values.country.map((n) => country_data.push(n));
        }

        if (ckc_stream) {
            // const num2x = values.stream.map((n) => stream_data.push(n.value));
        } else {
            //const num2x = values.stream.map((n) => stream_data.push(n));
        }

        formData.append('about', about);
        formData.append('country', country_data);
        formData.append('stream', stream_data);

        axios.post(api_url + 'application/update_checklist_records', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {

            if (response.data.status) {

                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                const timer = setTimeout(() => { // eslint-disable-line
                    window.location.reload();
                }, 3000);

            } else {
                toast.error(response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

            }
        }).catch(error => {
            console.error(error);
        });
    };

    function findValueInArray(value, arr) {
        var result = false;

        for (var i = 0; i < arr.length; i++) {
            if (arr[i].value) {
                result = true;
                break;
            }
        }
        return result;
    }

    const fetchCountryList = async () => {

        await axios.get(api_url + 'get_country_list', {

        }).then((response) => {
            const countries = response.data.country_list.map(country => ({ value: country.id, label: country.name }));
            setCountries(countries);
            setCountryLoading(false);
        })
    }
    const fetchStreamList = async () => {

        await axios.get(api_url + 'get_stream_list', {
        }).then((response) => {
            const streams = response.data.stream_list.map(stream => ({ value: stream.id, label: stream.name }));
            setStreamData(streams);
            setStreamLoading(false);
        })
    }

    const fetchCheckList = async () => {

        await axios.post(api_url + 'application/get_checklist_data', {
            user_id: user_data.id,
        }).then((response) => {

            if (response.data.checklist) {
                setClass_9(JSON.parse(response.data.checklist.class_9));
                setClass_10(JSON.parse(response.data.checklist.class_10));
                setClass_11(JSON.parse(response.data.checklist.class_11));
                setClass_12mid(JSON.parse(response.data.checklist.class_12_mid));
                setClass_12(JSON.parse(response.data.checklist.class_12_final));
                setSelectedCountries(response.data.selected_country);
                setSelectedStream(response.data.selected_stream);
                setAbout(response.data.checklist.unique_about_you);
            }
            setLoading(false);
        })
    }

    useEffect(() => {
        fetchCountryList()
        fetchStreamList()
        fetchCheckList()
    }, []); // eslint-disable-line

    return (

        <DashboardLayout>
            <Content
                className="site-layout-background lg:p-7 md:p-5"
                style={{
                    margin: '20px 16px 24px',
                    background: '#fff',
                    width: '100%'
                }}
            >
                {loading ? ( // show loader when data is still loading
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (

                    <Row gutter={30}>
                        <Col md={24}>
                            <div className="form-layout form-space">
                                <div className="flex justify-between bg-[#0F4275] py-2.5 px-7">
                                    <h1 className="text-[#fff] text-2xl uppercase text-center">School Record</h1>
                                </div>
                                <div className="bg-[#F5F5F5]">
                                    <Form
                                        name="normal_login"
                                        className="mx-2.5 p-5"
                                        onFinish={onFinish}
                                    >
                                        <h2 className="text-[#0C3762] text-base mb-5 font-medium">Academic Records : -</h2>
                                        <div className="p-2.5 my-5" style={{ border: '1px solid #EEE6E6' }}>
                                            <Row gutter={30} style={{ paddingBottom: 15 }}>
                                                <Col md={3}>
                                                    <h4 className="text-[#0C3762] text-base">Class</h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-[#0C3762] text-base">Percentage</h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-[#0C3762] text-base">Date of Exam Given/giving</h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-[#0C3762] text-base">City</h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-[#0C3762] text-base">Subject</h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-[#0C3762] text-base">School</h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-[#0C3762] text-base">Board</h4>
                                                </Col>
                                                <Col md={3}>
                                                    <h4 className="text-[#0C3762] text-base">Upload</h4>
                                                </Col>
                                            </Row>
                                            <Row gutter={20}>
                                                <Col md={3}>
                                                    <h5 className="text-[#A7A7A7] text-sm">Class 9th Final</h5>
                                                </Col>

                                                <Col md={3}>
                                                    <Form.Item
                                                        name="percentage_9"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_9.percentage_9}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter percentage',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle9Change('percentage_9', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col md={3}>
                                                    <Form.Item
                                                        name="date_9"
                                                        wrapperCol={{ span: 20 }}
                                                    >


                                                        <DatePicker
                                                            dateFormat="yyyy-MM-dd"
                                                            selected={class_9.date_9 ? new Date(class_9.date_9) : startDate_9}
                                                            onChange={(date) => {
                                                                setStartDate_9(date);
                                                                setClass_9({ ...class_9, date_9: (date) });
                                                            }}
                                                        />

                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="city_9"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_9.city_9}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter city!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle9Change('city_9', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="subject_9"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_9.subject_9}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter subject!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle9Change('subject_9', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="board_9"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_9.board_9}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter board!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle9Change('board_9', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="school_9"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_9.school_9}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter school!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle9Change('school_9', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="file_9"
                                                        wrapperCol={{ span: 12 }}
                                                    >
                                                        <input type="file" onChange={(e) => setFile9(e.target.files[0])} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={20}>
                                                <Col md={3}>
                                                    <h5 className="text-[#A7A7A7] text-sm">Class 10th Final</h5>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="percentage_10"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_10.percentage_10}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter percentage !',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle10Change('percentage_10', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="date_10"
                                                        wrapperCol={{ span: 20 }}
                                                    >
                                                        <DatePicker
                                                            dateFormat="yyyy-MM-dd"
                                                            selected={class_10.date_10 ? new Date(class_10.date_10) : startDate_10}
                                                            onChange={(date) => {
                                                                //console.log(date);
                                                                setStartDate_10(date);
                                                                setClass_10({ ...class_10, date_10: date });
                                                            }}
                                                        />

                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="city_10"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_10.city_10}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter city!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle10Change('city_10', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="subject_10"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_10.subject_10}

                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please select subject',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle10Change('subject_10', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="board_10"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_10.board_10}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter board!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle10Change('board_10', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="school_10"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_10.school_10}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter school!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle10Change('school_10', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="file_10"
                                                        wrapperCol={{ span: 12 }}
                                                    >
                                                        <input type="file" onChange={(e) => setFile10(e.target.files[0])} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={20}>
                                                <Col md={3}>
                                                    <h5 className="text-[#A7A7A7] text-sm">Class 11th Final</h5>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="percentage_11"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_11.percentage_11}

                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter percentage !',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle11Change('percentage_11', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="date_11"
                                                        wrapperCol={{ span: 20 }}

                                                    >
                                                        <DatePicker
                                                            dateFormat="yyyy-MM-dd"
                                                            selected={class_11.date_11 ? new Date(class_11.date_11) : startDate_11}
                                                            onChange={(date) => {
                                                                setStartDate_11(date);
                                                                setClass_11({ ...class_11, date_11: date });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="city_11"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_11.city_11}
                                                        //initialValue={studentData.form_number}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter city !',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle11Change('city_11', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="subject_11"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_11.subject_11}
                                                        //initialValue={studentData.form_number}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter subject !',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle11Change('subject_11', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="board_11"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_11.board_11}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle11Change('board_11', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="school_11"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_11.school_11}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle11Change('school_11', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="file_11"
                                                        wrapperCol={{ span: 12 }}
                                                    >
                                                        <input type="file" onChange={(e) => setFile11(e.target.files[0])} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={20}>
                                                <Col md={3}>
                                                    <h5 className="text-[#A7A7A7] text-sm">Class 12th mid term</h5>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="percentage_12mid"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12mid.percentage_12mid}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12midChange('percentage_12mid', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="date_12mid"
                                                        wrapperCol={{ span: 20 }}

                                                    >
                                                        <DatePicker
                                                            dateFormat="yyyy-MM-dd"
                                                            selected={class_12mid.date_12mid ? new Date(class_12mid.date_12mid) : startDate_12mid}
                                                            onChange={(date) => {
                                                                setStartDate_12mid(date);
                                                                setClass_12mid({ ...class_12mid, date_12mid: date });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="city_12mid"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12mid.city_12mid}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12midChange('city_12mid', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="subject_12mid"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12mid.subject_12mid}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12midChange('subject_12mid', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="board_12mid"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12mid.board_12mid}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12midChange('board_12mid', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="school_12mid"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12mid.school_12mid}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12midChange('school_12mid', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="file_12mid"
                                                        wrapperCol={{ span: 12 }}
                                                    >
                                                        <input type="file" onChange={(e) => setFile12mid(e.target.files[0])} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={20}>
                                                <Col md={3}>
                                                    <h5 className="text-[#A7A7A7] text-sm">Class 12th Predicted/final</h5>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="percentage_12"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12.percentage_12}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12Change('percentage_12', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="date_12"
                                                        wrapperCol={{ span: 20 }}

                                                    >
                                                        <DatePicker
                                                            dateFormat="yyyy-MM-dd"
                                                            selected={class_12.date_12 ? new Date(class_12.date_12) : startDate_12}
                                                            onChange={(date) => {
                                                                setStartDate_12(date);
                                                                setClass_12({ ...class_12, date_12: date });
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="city_12"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12.city_12}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12Change('city_12', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="subject_12"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12.subject_12}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12Change('subject_12', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="board_12"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12.board_12}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12Change('board_12', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="school_12"
                                                        wrapperCol={{ span: 12 }}
                                                        initialValue={class_12.school_12}
                                                    >
                                                        <Input
                                                            onChange={(e) => handle12Change('school_12', e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Item
                                                        name="file_12"
                                                        wrapperCol={{ span: 12 }}
                                                    >
                                                        <input type="file" onChange={(e) => setFile12(e.target.files[0])} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                        </div>
                                        <h2 className="text-[#0C3762] text-base mb-2.5 font-medium">Stream For Application</h2>
                                        <Row>
                                            <Col md={24}>
                                                <Form.Item
                                                    name="stream"
                                                    label='Select Stream'
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={selectedStream}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Select Stream',
                                                        },
                                                    ]}
                                                >

                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        allowClear
                                                        placeholder="Please select"
                                                        options={streamData}

                                                    />

                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <h2 className="text-[#0C3762] text-base mb-2.5 font-medium">Destination Country :-</h2>
                                        <Row>
                                            <Col md={24}>
                                                <Form.Item
                                                    name="country"
                                                    label='Select Country'
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={selectedCountry}
                                                    onChange={value => setSelectedCountries(value)}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Select Country',
                                                        },
                                                    ]}
                                                >

                                                    <Select
                                                        mode="multiple"
                                                        showSearch
                                                        allowClear
                                                        placeholder="Please select"
                                                        options={countries}

                                                    />

                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <h2 className="text-[#0C3762] text-base mb-2.5 font-medium">What is Unique about you</h2>
                                        <Row>
                                            <Col md={24}>
                                                <Form.Item
                                                    name="about"
                                                    wrapperCol={{ span: 24 }}
                                                    initialValue={about}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input about you',
                                                        },
                                                    ]}

                                                >
                                                    <TextArea onChange={(e) => handleAboutChange('about', e.target.value)} rows={4} placeholder='Write your about us . . . .' />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Button type="primary"

                                                htmlType="submit"
                                                className="text-lg text-white bg-[#2E6798] rounded-lg py-1.5 px-5 flex justify-center"
                                                style={{ height: 'auto' }}
                                            >
                                                Submit
                                            </Button>
                                        </Col>

                                    </Form>

                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </Content>
        </DashboardLayout>
    )
}

export default SchoolRecord