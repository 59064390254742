import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { decrypt, encriptionKey } from '../components/utils';
import { api_url } from '../components/constants';
const { Search } = Input;


const UpcomingSession = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [sessionList, setSessionList] = useState([]);

    useEffect(() => {

        const fetchPendingSession = async () => {

            await axios.post(api_url + 'counsellor-upcoming-session', {
                counsellor_id: user_data.id

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const st_list = res.data.users;
                const k = st_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'name': decrypt(item.name, encriptionKey),
                    'class': decrypt(item.class, encriptionKey),
                    'course': decrypt(item.course, encriptionKey),
                    'center': item.center,
                    'date': item.date + ' ' + item.time,
                    'status': item.status,
                })
                );

                setSessionList(k);
                setLoading(false)

            })
        }
        fetchPendingSession()
    }, [user_data.id])

    const onDeleteStudent = (record) => {
        //console.log(record);
    };

    const [tableParams, setTableParams] = useState({ // eslint-disable-line
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
            align: 'center'
        },

        {
            key: "2",
            title: "Name",
            dataIndex: "name",
            align: 'center'
        },
        {
            key: "3",
            title: "Class",
            dataIndex: "class",
            align: 'center'
        },
        {
            key: "4",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "5",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "6",
            title: "Date & Time",
            dataIndex: "date",
            align: 'center'
        },
        {
            key: "7",
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            key: "8",
            title: "Actions",
            align: 'center',
            render: (record) => {
                return (
                    <>
                        <Space>
                            <Button onClick={() => {
                                onDeleteStudent(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Enter Feedback</Button>

                        </Space>

                    </>
                );
            },
        },
    ];
    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (

                    <div className="mx-16 mt-10">
                        <Row>
                            <Col md={24}>
                                <h1 className="text-[#061522] text-2xl mb-10">Upcoming PBS Session</h1>
                            </Col>
                        </Row>

                        <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                            <Row>
                                <Col>
                                    <h2 className="text-[#061522] text-2xl mb-2.5">Student Up Coming Session List</h2>
                                    <span className="text-[#9B9B9B] text-lg">This is your most recent records.</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                    <Search placeholder="Search...." />
                                </Col>
                                <Col md={24}>
                                    <Table
                                        className="record"
                                        bordered
                                        columns={columns}
                                        //rowKey={(record) => record.login.uuid}
                                        dataSource={sessionList}
                                        pagination={tableParams.pagination}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </CounsellorLayout>
        </>
    )
}

export default UpcomingSession