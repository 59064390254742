import React, { useState, useEffect } from "react";
import '../styles/dashboard.css';
import '../styles/responsive.css';
import { Col, Row, Collapse, Layout } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import DashboardLayout from '../components/dashboard-layout';
import axios from 'axios';
import { api_url } from '../components/constants';
const { Content } = Layout;
const { Panel } = Collapse

const DiagnosticResult = () => {
    const [question, setQuestion] = useState();;
    const [attempt, setAttempt] = useState('0');
    const [total, setTotal] = useState('0');
    const [correct, setCorrect] = useState('0');
    const [incorrect, setIncorrect] = useState('0');
    const [records, setNoRecord] = useState('');
    const [loading, setLoading] = useState(true);
    const [marks, setMarks] = useState('0');

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const panelStyle = {
        marginBottom: 24,
        border: 'none',
        background: '#E3E3E3',
        boxShadow: '0px 3px 3px #00000029',
        borderRadius: 5,
        color: '#fff'
    };


    useEffect(() => {
        const fetchResult = async () => {
            await axios.post(api_url + 'get_user_test_report', {
                user_id: user_data.id,

            }).then((response) => {
                if (response.data.status) {
                    const k = response.data.test_report.map((item, index) =>
                    ({
                        'sno': index + 1,
                        'question': item.question,
                        'option': item.option,
                        'solution': item.solution,
                        'correct_answer': item.correct_answer,
                        'user_answer': item.user_answer,
                    })
                    );
                    setAttempt(response.data.attempt);
                    setTotal(response.data.total);
                    setCorrect(response.data.correct);
                    setIncorrect(response.data.incorrect);
                    setMarks(response.data.marks);
                    setQuestion(k);
                    setNoRecord('1');

                } else {
                    setNoRecord('0');
                }
                setLoading(false)

            })
        }
        fetchResult()

    }, [user_data.id]);

    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background lg:p-7 md:p-5"
                        style={{
                            margin: '20px 6px 24px',
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mb-16 mx-10">
                            <Row gutter={30} className="dashboard-data-show">
                                <Col lg={6} md={12} xs={24}>
                                    <div className="data-info flex justify-between bg-[#F2EEEB] p-5 items-center my-2.5 lg:my-0" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 5 }}>
                                        <div>
                                            <h4 className="text-[#707070] text-base xl:text-sm mb-2.5">Total Score</h4>
                                            <span className="text-[#E0680E] xl:text-xl lg:text-base md:text-base">{marks} out of 50 marks</span>
                                        </div>
                                        <img className="question-img" src='/images/total.png' alt='total' />
                                    </div>
                                </Col>
                                <Col lg={6} md={12} xs={24}>
                                    <div className="data-info  flex justify-between bg-[#EFFEFB] p-5 items-center my-2.5 lg:my-0" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 5 }}>
                                        <div>
                                            <h4 className="text-[#707070] text-base xl:text-sm mb-2.5">Correct Answer</h4>
                                            <span className="text-[#4CBD50] text-xl">{correct}  Correct</span>
                                        </div>
                                        <img className="question-img" src='/images/correct.png' alt='correct' />
                                    </div>
                                </Col>
                                <Col lg={6} md={12} xs={24}>
                                    <div className="data-info  flex justify-between bg-[#FDF9EF] p-5 items-center my-2.5 lg:my-0" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 5 }}>
                                        <div>
                                            <h4 className="text-[#707070] text-base xl:text-sm mb-2.5">Incorrect Answer</h4>
                                            <span className="text-[#E2A51F] text-xl">{incorrect} Incorrect</span>
                                        </div>
                                        <img className="question-img" src='/images/incorrect.png' alt='incorrect' />
                                    </div>
                                </Col>
                                <Col lg={6} md={12} xs={24}>
                                    <div className="data-info  flex justify-between bg-[#F9F8FF] p-5 items-center my-2.5 lg:my-0" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 5 }}>
                                        <div>
                                            <h4 className="text-[#707070] text-base xl:text-sm mb-2.5">Skipped Question</h4>
                                            <span className="text-[#735EED] text-xl">{total - attempt} Skipped</span>
                                        </div>
                                        <img className="question-img" src='/images/skipped.png' alt='skipped' />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <Row gutter={30}>
                            <Col md={24}>
                                <div className="form-layout form-space">
                                    <div className=" bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-2xl uppercase">Diagnostic Test Record</h1>
                                    </div>
                                    {loading ? (
                                        <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                                    ) : (

                                        records === '0' ? (
                                            <div className="my-24">
                                                <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                            </div>

                                        ) : (
                                            <>
                                                <Row>

                                                    <Col md={24} className='lg:px-10 px-5'>
                                                        <Collapse defaultActiveKey={['1']}
                                                            bordered={false}
                                                            expandIconPosition='end'
                                                            style={{ marginTop: "20px", }}
                                                            expandIcon={({ isActive }) => (
                                                                <DownOutlined
                                                                    style={{ color: '#A2A1A1', paddingTop: 10, }}
                                                                    rotate={isActive ? 180 : 0}
                                                                />
                                                            )}
                                                        >

                                                            {question.map((item, index) => (
                                                                <Panel header={`Q${index + 1}: ${item.question}`} key={item.sno} style={panelStyle}>
                                                                    <div className="py-5 px-2.5">
                                                                        {item.correct_answer === "" ? (
                                                                            <p className="text-[#707070] text-base"><strong className="pr-2.5">Answer-</strong>{item.user_answer}</p>
                                                                        ) : (
                                                                            <>
                                                                                <ul className="pl-2.5 que-option li">
                                                                                    {item.option && item.option.map((val, key) => (
                                                                                        <li>
                                                                                            <strong className={
                                                                                                item.correct_answer === String.fromCharCode(65 + key)
                                                                                                    ? "text-green-500"
                                                                                                    : item.user_answer === String.fromCharCode(65 + key)
                                                                                                        ? "text-red-500"
                                                                                                        : ""}>
                                                                                                {String.fromCharCode(65 + key)}. </strong>
                                                                                            <span
                                                                                                className={
                                                                                                    // set class based on condition
                                                                                                    item.correct_answer === String.fromCharCode(65 + key)
                                                                                                        ? "text-green-500"
                                                                                                        : item.user_answer === String.fromCharCode(65 + key)
                                                                                                            ? "text-red-500"
                                                                                                            : ""
                                                                                                }
                                                                                            >
                                                                                                {val}
                                                                                            </span>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>
                                                                                <p className={`text-${item.user_answer === item.correct_answer ? 'green' : 'red'}-500 text-base correct-ans py-2.5`}>
                                                                                    <strong className="text-[#707070] pr-2.5">Correct Ans-</strong>{item.correct_answer}
                                                                                </p>
                                                                                <p className="text-[#707070] text-base"><strong className="pr-2.5">Solution-</strong>{item.solution}</p>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </Panel>
                                                            ))}
                                                        </Collapse>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Content>
                )
                }
            </DashboardLayout >
        </>
    )
}

export default DiagnosticResult