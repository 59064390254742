import React, { useEffect, useState } from 'react';
import '../styles/counsellor.css';
import { Col, Row, Layout, Button, Select, Spin, Form } from 'antd';
import CounsellorLayout from "./counsellor-layout";
import axios from 'axios';
import { api_url, } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from "react-router-dom";
const { Content } = Layout;

const ApplicationStatus = () => {
    const { id } = useParams();
    const [selectedButton, setSelectedButton] = useState(null);
    const [myCountry, setMyCountry] = useState(null);
    const [loading, setLoading] = useState(true);
    const [appStatus, setAppStatus] = useState(false);
    const [showCommonApp, setCommonApp] = useState(false);
    const [showPrincipalLor, setPrincipalLor] = useState(false);
    const [showSubjectLor, setSubjectLor] = useState(false);
    const [showEnglishLor, setEnglishLor] = useState(false);
    const [showSop, setSop] = useState(false);
    const [appComponent, setAppComponent] = useState(false);

    const [countryMapping, setCountryMapping] = useState(null);
    const [allData, setAllData] = useState(null);
    const [mentor, setMentor] = useState(null);
    const [history, setHistory] = useState([]);
    const [applicationStatus, setApplicationStatus] = useState({ status: '' });
    const [countryName, setCountryName] = useState(null);


    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    console.log(user_data.counsellor_type)
    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    const getCountryList = () => {
        axios.post(api_url + 'ajax/get_user_application_status', {
            user_id: id,
        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const st_list = res.data.country_data;

            setMyCountry(st_list);

            setLoading(false)
        })
    }

    useEffect(() => {
        getCountryList()
    }, [])

    const handleChange = (country_id) => {
        setAppComponent(false);
        setAppStatus(false);
        setAllData(null);
        axios.post(api_url + 'ajax/get_user_application_status', {
            user_id: id,
            country_id: country_id,

        }).then((res) => {
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }


            setAllData(res.data);
            const st_list = res.data.country_data[0];

            setApplicationStatus(res.data.application_status);
            setMentor(res.data.mentor_list);
            setHistory(res.data.history_list)
            console.log(st_list);


            setCountryMapping(st_list);

            setCommonApp(false);
            setSop(false);
            setEnglishLor(false);
            setPrincipalLor(false);
            setSubjectLor(false);

            if (st_list.common_essay == 1) {
                setCommonApp(true);
            }

            if (st_list.sop == 1) {
                setSop(true);
            }

            if (st_list.english_lor == 1) {
                setEnglishLor(true);
            }

            if (st_list.principle_lor == 1) {
                setPrincipalLor(true);
            }
            if (st_list.subject_lor == 1) {
                setSubjectLor(true);
            }

            setCountryName(res.data.country_data[0].country);
            setAppStatus(true);
            setAppComponent(true);
            setLoading(false)
        })

    };


    return (
        <>
            <CounsellorLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        margin: '20px 16px 24px',
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mb-16 mx-10">
                        <Row>
                            <Col md={24}>
                                <div className="form-layout form-space bg-[#F6F6F6]">
                                    <div className=" bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-base">Application Status</h1>
                                    </div>
                                    <div className="bg-[#F5F5F5] p-2.5">
                                        <Row gutter={20}>
                                            <Col md={8}>
                                                <div className="mx-2.5 bg-white" style={{ border: '1px solid #FFEFCC' }}>
                                                    <div className='bg-[#FFEFCC]'>
                                                        <h2 className='text-base text-[#707070] p-2.5'>Application Status</h2>
                                                    </div>
                                                    <div className='mx-5 mt-5'>
                                                        {loading ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (
                                                            myCountry.map((row, index) => (
                                                                <Col md={24} key={index}>
                                                                    <div className='flex justify-center items-center my-7'>
                                                                        <Button
                                                                            className={`custom-button bg-[#FFEFCC] text-[#0C3762] text-base rounded-lg flex justify-center items-center py-5  ${selectedButton === 'United States' ? 'selected' : ''
                                                                                }`}
                                                                            style={{ border: '1px solid #F2B11F', width: '80%' }}
                                                                            onClick={() => {
                                                                                handleButtonClick(row.country)
                                                                                handleChange(row.country_id)
                                                                            }}
                                                                        >
                                                                            {row.country}
                                                                        </Button>

                                                                    </div>
                                                                </Col>
                                                            ))
                                                        )}

                                                    </div>

                                                </div>
                                            </Col>

                                            <Col md={16}>
                                                {/* {appStatus && <Status data = {allData} user_id={id} counsellor_id={user_data.id}/>}

                                                {showCommonApp && <CommonApp data = {allData} user_id={id} counsellor_id={user_data.id} />}

                                                {showPrincipalLor && <PrincipalLor data = {allData} user_id={id}  counsellor_id={user_data.id}/>}

                                                {showSubjectLor && <SubjectLor data = {allData} user_id={id}  counsellor_id={user_data.id}  />}

                                                {showEnglishLor && <EnglishLor data = {allData} user_id={id}  counsellor_id={user_data.id}  />}

                                                {showSop && <Sop data = {allData} user_id={id}  counsellor_id={user_data.id}  />} */}


                                                {appComponent && <AppComponent usertype={user_data.counsellor_type} data={allData} user_id={id} counsellor_id={user_data.id} />}

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
            </CounsellorLayout>
        </>
    )
}

const AppComponent = (props) => {
    //console.log(props)
    const [loading, setLoading] = useState(true);

    const [commonApp, setCom] = useState(false);
    const [showCommonApp, setCommonApp] = useState(false);
    const [showPrincipalLor, setPrincipalLor] = useState(false);
    const [showSubjectLor, setSubjectLor] = useState(false);
    const [showEnglishLor, setEnglishLor] = useState(false);
    const [showSop, setSop] = useState(false);

    const [appStatus, setAppStatus] = useState({ name: '', value: '' });
    const [selectedStatus, setSelectedAppStatus] = useState(null);
    const [countryName, setCountry] = useState(null);

    const [mentorCommonList, setMentorCommonList] = useState(null)
    const [historyCommonList, setHistoryCommonList] = useState([{ name: '', date: '', status: '' }]);
    const [showTableCommon, setShowTableCommon] = useState(false);

    const [mentorSOPList, setMentorSOPList] = useState(null)
    const [historySOPList, setHistorySOPList] = useState([{ name: '', date: '', status: '' }]);
    const [showTableSOP, setShowTableSOP] = useState(false);

    const [mentorSubjectList, setMentorSubjectList] = useState(null)
    const [historySubjectList, setHistorySubjectList] = useState([{ name: '', date: '', status: '' }]);
    const [showTableSubject, setShowTableSubject] = useState(false);

    const [mentorEnglishList, setMentorEnglishList] = useState(null)
    const [historyEnglishList, setHistoryEnglishList] = useState([{ name: '', date: '', status: '' }]);
    const [showTableEnglish, setShowTableEnglish] = useState(false);

    const [mentorPrincipleList, setMentorPrincipleList] = useState(null)
    const [historyPrincipleList, setHistoryPrincipleList] = useState([{ name: '', date: '', status: '' }]);
    const [showTablePrinciple, setShowTablePrinciple] = useState(false);


    const onFinish = (values) => {


        axios.post(api_url + 'application/update_application_status', {
            country_id: props.data.country_data[0].country_id,
            status: appStatus.val,
            user_id: props.user_id,
            counsellor_id: props.counsellor_id,
            type: appStatus.key,
        })

            .then((response) => {
                //console.log(response)

                if (response.data.status) {

                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }


    const handleChange = (value, name) => {
        const newSelectValues = { key: name, val: value };
        setAppStatus(newSelectValues);
    };

    useEffect(() => {

        setSelectedAppStatus(props.data.application_status);
        setCountry(props.data.country_data[0].country)
        if (props.data.country_data[0].common_essay === "1") {
            const common_list = props.data.mentor_list.common;
            const common_his = props.data.history_list.common;
            setMentorCommonList(common_list);
            setHistoryCommonList(common_his);
            setCommonApp(true);
        }

        if (props.data.country_data[0].sop === "1") {
            const sop_list = props.data.mentor_list.sop;
            const sop_his = props.data.history_list.sop;
            setMentorSOPList(sop_list);
            setHistorySOPList(sop_his);
            setCommonApp(true);
        }
        if (props.data.country_data[0].english_lor === "1") {
            const eng_list = props.data.mentor_list.english;
            const eng_his = props.data.history_list.english;
            setMentorEnglishList(eng_list);
            setHistoryEnglishList(eng_his);
            setEnglishLor(true);
        }

        if (props.data.country_data[0].subject_lor === "1") {
            const subject_list = props.data.mentor_list.subject;
            const subject_his = props.data.history_list.subject;
            setMentorSubjectList(subject_list);
            setHistorySubjectList(subject_his);
            setSubjectLor(true);
        }

        if (props.data.country_data[0].principle_lor === "1") {
            const principle_list = props.data.mentor_list.principle;
            const principle_his = props.data.history_list.principle;
            setMentorPrincipleList(principle_list);
            setHistoryPrincipleList(principle_his);
            setPrincipalLor(true);
        }
        setLoading(false);
    }, [props]);


    const toggleTable = (name) => {
        if (name === "common") {
            setShowTableCommon(!showTableCommon);
        } else if (name === "sop") {
            setShowTableSOP(!showTableSOP);
        } else if (name === "english") {
            setShowTableEnglish(!showTableEnglish);
        }
        else if (name === "principle") {
            setShowTablePrinciple(!showTablePrinciple);
        }
        else if (name === "subject") {
            setShowTableSubject(!showTableSubject);
        }

    };






    return (
        <>
            <Form onFinish={onFinish}>
                <div className='bg-[#FFEFCC]  px-5 rounded-lg flex justify-between items-center'>
                    <h3 className='text-sm text-[#707070]'>{countryName} Application Status : {props.usertype === "Country Counsellor Head" || props.usertype === "Country Counsellor" ? ('') : (selectedStatus)}</h3>
                    {props.usertype === "Country Counsellor Head" || props.usertype === "Country Counsellor" ? (<> {loading ? (<div className="loaders"><img src='/images/loader.gif' /></div>) : (
                        <Form.Item name="status" style={{ width: '40%' }} className='py-2.5'>
                            <Select
                                className='app-status'
                                placeholder="Select"
                                defaultValue={selectedStatus}
                                name="status"
                                style={{ width: '100%' }}
                                onChange={(value) => handleChange(value, 'application')}

                            >
                                <Select.Option value="Connected with Student">Connected with Student</Select.Option>
                                <Select.Option value="Process Started">Process Started</Select.Option>
                                <Select.Option value="Uni List Given">Uni List Given</Select.Option>
                                <Select.Option value="Applied">Applied</Select.Option>
                            </Select>
                        </Form.Item>
                    )}
                        <Button htmlType='submit' className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>
                            Save
                        </Button></>) : ('')}

                </div>
            </Form>


            {showCommonApp &&
                <div className=" mt-2.5 bg-white" style={{ border: '1px solid #FFEFCC' }}>
                    <div className='bg-[#FFEFCC]'>
                        <h2 className='text-base text-[#707070] p-2.5'>Common App Mentor</h2>
                    </div>
                    {loading ? (<Spin className='flex justify-center items-center' />) : (
                        <div className=' pb-5 pt-2.5'>

                            <div style={{ borderBottom: '1px solid #F7F7F7' }}>
                                <div className='flex justify-between mx-5 my-2.5'>
                                    <div className='flex'>
                                        <h4 className='text-[#0C3762] text-sm flex justify-center items-center'>
                                            Mentors List
                                        </h4>


                                        {mentorCommonList && mentorCommonList.map((res, index) => (
                                            <span key={index} className='text-[#707070] text-sm bg-[#FFEFCC40] p-2.5 mx-2.5' >
                                                {res}
                                            </span>
                                        ))}



                                    </div>
                                    <span
                                        className='text-[#0C3762] text-sm flex items-center cursor-pointer'
                                        onClick={(e) => toggleTable('common')}
                                    >
                                        {showTableCommon ? (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>-</span>
                                            </>
                                        ) : (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>+</span>
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>

                            {showTableCommon && (
                                <div className='mx-5 mt-7'>
                                    <table className='status'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name of the mentor</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (<Spin className='flex justify-center items-center' />) : (

                                                historyCommonList && historyCommonList.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.name}</td>
                                                        <td>{row.status}</td>
                                                        <td>{row.date}</td>
                                                    </tr>
                                                ))

                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <Form onFinish={onFinish}>
                                <div className='flex mx-16 mt-10'>

                                    <h5 className='text-[#707070] text-base pr-5'>Status: </h5>

                                    <Form.Item name="status" style={{ width: '40%' }}>
                                        <Select
                                            name='status'
                                            className='app-status'
                                            placeholder="Select"

                                            style={{ width: '100%' }}
                                            onChange={(value) => handleChange(value, 'common')}
                                        >
                                            <Select.Option value="Student Inputs Given-1">Student Inputs Given-1</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-1">AGSD Inputs Given-1</Select.Option>
                                            <Select.Option value="Student Inputs Given-2">Student Inputs Given-2</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-2">AGSD Inputs Given-2</Select.Option>
                                            <Select.Option value="Student Inputs Given-3">Student Inputs Given-3</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-3">AGSD Inputs Given-3</Select.Option>
                                            <Select.Option value="Final">Final</Select.Option>
                                            <Select.Option value="Student hasn't given inputs">Student hasn't given inputs</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <div className='ml-32'>
                                        <Button htmlType="submit" className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>
                                            Save
                                        </Button>
                                    </div>

                                </div>
                            </Form>

                        </div>
                    )}

                </div>
            }

            {showSop &&
                <div className=" mt-2.5 bg-white" style={{ border: '1px solid #FFEFCC' }}>
                    <div className='bg-[#FFEFCC]'>
                        <h2 className='text-base text-[#707070] p-2.5'>SOP App Mentor</h2>
                    </div>
                    {loading ? (<Spin className='flex justify-center items-center' />) : (
                        <div className=' pb-5 pt-2.5'>

                            <div style={{ borderBottom: '1px solid #F7F7F7' }}>
                                <div className='flex justify-between mx-5 my-2.5'>
                                    <div className='flex'>
                                        <h4 className='text-[#0C3762] text-sm flex justify-center items-center'>
                                            Mentors List
                                        </h4>


                                        {mentorSOPList && mentorSOPList.map((res, index) => (
                                            <span key={index} className='text-[#707070] text-sm bg-[#FFEFCC40] p-2.5 mx-2.5' >
                                                {res}
                                            </span>
                                        ))}



                                    </div>
                                    <span
                                        className='text-[#0C3762] text-sm flex items-center cursor-pointer'
                                        onClick={(e) => toggleTable('sop')}
                                    >
                                        {showTableSOP ? (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>-</span>
                                            </>
                                        ) : (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>+</span>
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>

                            {showTableSOP && (
                                <div className='mx-5 mt-7'>
                                    <table className='status'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name of the mentor</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (<Spin className='flex justify-center items-center' />) : (

                                                historySOPList && historySOPList.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.name}</td>
                                                        <td>{row.status}</td>
                                                        <td>{row.date}</td>
                                                    </tr>
                                                ))

                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <Form onFinish={onFinish}>
                                <div className='flex mx-16 mt-10'>

                                    <h5 className='text-[#707070] text-base pr-5'>Status: </h5>

                                    <Form.Item name="status" style={{ width: '40%' }}>
                                        <Select
                                            name='status'
                                            className='app-status'
                                            placeholder="Select"

                                            style={{ width: '100%' }}
                                            onChange={(value) => handleChange(value, 'sop')}
                                        >
                                            <Select.Option value="Student Inputs Given-1">Student Inputs Given-1</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-1">AGSD Inputs Given-1</Select.Option>
                                            <Select.Option value="Student Inputs Given-2">Student Inputs Given-2</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-2">AGSD Inputs Given-2</Select.Option>
                                            <Select.Option value="Student Inputs Given-3">Student Inputs Given-3</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-3">AGSD Inputs Given-3</Select.Option>
                                            <Select.Option value="Final">Final</Select.Option>
                                            <Select.Option value="Student hasn't given inputs">Student hasn't given inputs</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <div className='ml-32'>
                                        <Button htmlType="submit" className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>
                                            Save
                                        </Button>
                                    </div>

                                </div>
                            </Form>

                        </div>
                    )}

                </div>
            }

            {showEnglishLor &&
                <div className=" mt-2.5 bg-white" style={{ border: '1px solid #FFEFCC' }}>
                    <div className='bg-[#FFEFCC]'>
                        <h2 className='text-base text-[#707070] p-2.5'>English LOR Input</h2>
                    </div>


                    {loading ? (<Spin className='flex justify-center items-center' />) : (
                        <div className=' pb-5 pt-2.5'>
                            <div style={{ borderBottom: '1px solid #F7F7F7' }}>
                                <div className='flex justify-between mx-5 my-2.5'>
                                    <div className='flex'>
                                        <h4 className='text-[#0C3762] text-sm flex justify-center items-center'>
                                            Mentors List
                                        </h4>
                                        {

                                            mentorEnglishList && mentorEnglishList.map((res, index) => (
                                                <span key={index} className='text-[#707070] text-sm bg-[#FFEFCC40] p-2.5 mx-2.5' >
                                                    {res}
                                                </span>
                                            ))

                                        }
                                    </div>
                                    <span
                                        className='text-[#0C3762] text-sm flex items-center cursor-pointer'
                                        onClick={(e) => toggleTable('english')}
                                    >
                                        {showTableEnglish ? (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>-</span>
                                            </>
                                        ) : (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>+</span>
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>

                            {showTableEnglish && (
                                <div className='mx-5 mt-7'>
                                    <table className='status'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name of the mentor</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (<Spin className='flex justify-center items-center' />) : (

                                                historyEnglishList && historyEnglishList.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.name}</td>
                                                        <td>{row.status}</td>
                                                        <td>{row.date}</td>
                                                    </tr>
                                                ))

                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <Form onFinish={onFinish}>
                                <div className='flex mx-16 mt-10'>

                                    <h5 className='text-[#707070] text-base pr-5'>Status: </h5>

                                    <Form.Item name="status" style={{ width: '40%' }}>
                                        <Select
                                            name='status'
                                            className='app-status'
                                            placeholder="Select"
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleChange(value, 'english')}
                                        >
                                            <Select.Option value="Student Inputs Given-1">Student Inputs Given-1</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-1">AGSD Inputs Given-1</Select.Option>
                                            <Select.Option value="Student Inputs Given-2">Student Inputs Given-2</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-2">AGSD Inputs Given-2</Select.Option>
                                            <Select.Option value="Student Inputs Given-3">Student Inputs Given-3</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-3">AGSD Inputs Given-3</Select.Option>
                                            <Select.Option value="Final">Final</Select.Option>
                                            <Select.Option value="Student hasn't given inputs">Student hasn't given inputs</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <div className='ml-32'>
                                        <Button htmlType="submit" className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>
                                            Save
                                        </Button>
                                    </div>

                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            }

            {showSubjectLor &&
                <div className=" mt-2.5 bg-white" style={{ border: '1px solid #FFEFCC' }}>
                    <div className='bg-[#FFEFCC]'>
                        <h2 className='text-base text-[#707070] p-2.5'>Subject LOR Input</h2>
                    </div>


                    {loading ? (<Spin className='flex justify-center items-center' />) : (
                        <div className=' pb-5 pt-2.5'>
                            <div style={{ borderBottom: '1px solid #F7F7F7' }}>
                                <div className='flex justify-between mx-5 my-2.5'>
                                    <div className='flex'>
                                        <h4 className='text-[#0C3762] text-sm flex justify-center items-center'>
                                            Mentors List
                                        </h4>
                                        {

                                            mentorSubjectList && mentorSubjectList.map((res, index) => (
                                                <span key={index} className='text-[#707070] text-sm bg-[#FFEFCC40] p-2.5 mx-2.5' >
                                                    {res}
                                                </span>
                                            ))

                                        }
                                    </div>
                                    <span
                                        className='text-[#0C3762] text-sm flex items-center cursor-pointer'
                                        onClick={(e) => toggleTable('subject')}
                                    >
                                        {showTableSubject ? (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>-</span>
                                            </>
                                        ) : (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>+</span>
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>

                            {showTableSubject && (
                                <div className='mx-5 mt-7'>
                                    <table className='status'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name of the mentor</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (<Spin className='flex justify-center items-center' />) : (

                                                historySubjectList && historySubjectList.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.name}</td>
                                                        <td>{row.status}</td>
                                                        <td>{row.date}</td>
                                                    </tr>
                                                ))

                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <Form onFinish={onFinish}>
                                <div className='flex mx-16 mt-10'>

                                    <h5 className='text-[#707070] text-base pr-5'>Status: </h5>

                                    <Form.Item name="status" style={{ width: '40%' }}>
                                        <Select
                                            name='status'
                                            className='app-status'
                                            placeholder="Select"
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleChange(value, 'subject')}
                                        >
                                            <Select.Option value="Student Inputs Given-1">Student Inputs Given-1</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-1">AGSD Inputs Given-1</Select.Option>
                                            <Select.Option value="Student Inputs Given-2">Student Inputs Given-2</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-2">AGSD Inputs Given-2</Select.Option>
                                            <Select.Option value="Student Inputs Given-3">Student Inputs Given-3</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-3">AGSD Inputs Given-3</Select.Option>
                                            <Select.Option value="Final">Final</Select.Option>
                                            <Select.Option value="Student hasn't given inputs">Student hasn't given inputs</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <div className='ml-32'>
                                        <Button htmlType="submit" className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>
                                            Save
                                        </Button>
                                    </div>

                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            }

            {showPrincipalLor &&
                <div className=" mt-2.5 bg-white" style={{ border: '1px solid #FFEFCC' }}>
                    <div className='bg-[#FFEFCC]'>
                        <h2 className='text-base text-[#707070] p-2.5'>Principle LOR Input</h2>
                    </div>


                    {loading ? (<Spin className='flex justify-center items-center' />) : (
                        <div className=' pb-5 pt-2.5'>
                            <div style={{ borderBottom: '1px solid #F7F7F7' }}>
                                <div className='flex justify-between mx-5 my-2.5'>
                                    <div className='flex'>
                                        <h4 className='text-[#0C3762] text-sm flex justify-center items-center'>
                                            Mentors List
                                        </h4>
                                        {

                                            mentorPrincipleList && mentorPrincipleList.map((res, index) => (
                                                <span key={index} className='text-[#707070] text-sm bg-[#FFEFCC40] p-2.5 mx-2.5' >
                                                    {res}
                                                </span>
                                            ))

                                        }
                                    </div>
                                    <span
                                        className='text-[#0C3762] text-sm flex items-center cursor-pointer'
                                        onClick={(e) => toggleTable('principle')}
                                    >
                                        {showTablePrinciple ? (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>-</span>
                                            </>
                                        ) : (
                                            <>
                                                View History <span className='pl-2.5 cursor-pointer'>+</span>
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>

                            {showTablePrinciple && (
                                <div className='mx-5 mt-7'>
                                    <table className='status'>
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name of the mentor</th>
                                                <th>Status</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading ? (<Spin className='flex justify-center items-center' />) : (

                                                historyPrincipleList && historyPrincipleList.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{row.name}</td>
                                                        <td>{row.status}</td>
                                                        <td>{row.date}</td>
                                                    </tr>
                                                ))

                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            )}
                            <Form onFinish={onFinish}>
                                <div className='flex mx-16 mt-10'>

                                    <h5 className='text-[#707070] text-base pr-5'>Status: </h5>

                                    <Form.Item name="status" style={{ width: '40%' }}>
                                        <Select
                                            name='status'
                                            className='app-status'
                                            placeholder="Select"
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleChange(value, 'principle')}
                                        >
                                            <Select.Option value="Student Inputs Given-1">Student Inputs Given-1</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-1">AGSD Inputs Given-1</Select.Option>
                                            <Select.Option value="Student Inputs Given-2">Student Inputs Given-2</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-2">AGSD Inputs Given-2</Select.Option>
                                            <Select.Option value="Student Inputs Given-3">Student Inputs Given-3</Select.Option>
                                            <Select.Option value="AGSD Inputs Given-3">AGSD Inputs Given-3</Select.Option>
                                            <Select.Option value="Final">Final</Select.Option>
                                            <Select.Option value="Student hasn't given inputs">Student hasn't given inputs</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <div className='ml-32'>
                                        <Button htmlType="submit" className='bg-[#0C3762] text-sm text-white rounded-full font-light flex justify-center items-center px-7' style={{ border: '1px solid #A6CAEE' }}>
                                            Save
                                        </Button>
                                    </div>

                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            }

        </>
    )
}




export default ApplicationStatus