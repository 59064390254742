import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Layout } from "antd";
import DashboardLayout from "../components/dashboard-layout";
import { useParams } from "react-router-dom";
import { api_url } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
const { Content } = Layout;


const ViewClass = () => {
    const { meetingid } = useParams();
    const [recordUri, setUrl] = useState(null);

    useEffect(() => {

        axios.post(api_url + 'ajax/get_recording', {
            meeting_id: meetingid

        }).then((res) => {
            if (res.data.status) {
                const recordingUrl = decrypt(res.data.uri, encriptionKey);
                setUrl(recordingUrl);

            } else {
                toast.error("Reording not avaliable this time please try after sometime !", {
                    position: toast.POSITION.TOP_CENTER
                });
            }

        })

    }, []) // eslint-disable-line

    const playerConfig = {
        file: {
            attributes: {
                controlsList: 'nodownload', // Prevents the download button
            },
        },
    };

    return (
        <DashboardLayout>
            <Content
                className="site-layout-background lg:p-7 md:p-5"
                style={{
                    margin: '20px 16px 24px',
                    background: '#fff',
                    width: '100%'
                }}
            >
                <div className="video-container">
                    <ReactPlayer
                        crossorigin
                        url={`${recordUri}`}
                        width="90%"
                        height="75%"
                        config={playerConfig}
                        controls={true}
                    />
                </div>
            </Content>
        </DashboardLayout>
    );
};


export default ViewClass