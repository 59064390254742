import React, { useState, useEffect } from "react";
import { Col, Row, Space, Button, Radio, Form, Skeleton, Input, Modal } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { setAuthToken } from '../helpers/setAuthToken';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { decrypt, encriptionKey } from '../components/utils';
import { api_url, base_url } from '../components/constants';
import '../styles/responsive.css';

const DiagnosticQuiz = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [questions, setQuestions] = useState([]);
    const [timeRemaining, setTimeRemaining] = useState(3600000);
    const [timeSubmit, setSubmitTime] = useState(0); // eslint-disable-line
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [responses, setResponses] = useState({});
    const [responsesOption, setSubmitResponses] = useState({});
    const [selectedValue, setSelectedValue] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [tymodalVisible, setTyModalVisible] = useState(false);
    const path = window.location.pathname;

    let isProfileVisible = "";
    if (path.includes('student-dashboard')) {
        isProfileVisible = 'student-dashboard';
    }

    let isCounsellorVisible = "";
    if (path.includes('counsellor-dashboard')) {
        isCounsellorVisible = 'counsellor-dashboard';
    }

    const access_token = JSON.parse(localStorage.getItem('user'));
    if (access_token) {
        setAuthToken(access_token.token);
    }

    const login_user = JSON.parse(localStorage.getItem('user'));
    const user_data = login_user.user;

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(prevTime => prevTime - 1000);
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (timeRemaining <= 0 && !submitted) {
            handleQuizSubmit();
            setSubmitted(true);
        }
    }, [timeRemaining, submitted]); // eslint-disable-line

    const formatTime = () => {
        const totalSeconds = Math.round(timeRemaining / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };


    const handleQuizSubmit = () => {

        setSubmitTime(timeRemaining);
        setTyModalVisible(true);
        axios.post(api_url + 'save_user_response', {
            user_id: user_data.id,
            user_reponse: responses,
            submit_response: responsesOption,
            user_time: timeRemaining,
        })
            .then(result => {
                // console.log(result.data)
                // return false;
                if (result.data.status) {
                    // const timer = setTimeout(() => {
                    //     navigate('/student-portal/general-enquiry');
                    // }, 3000);

                } else {
                    navigate('/student-portal/general-enquiry');
                }
            })

    };

    const fetchQuestions = async () => {
        await axios.post(api_url + 'get_question_list', {
            class: decrypt(user_data.class, encriptionKey),
        }).then((response) => {

            if (response.data.question_list) {
                const ques = JSON.parse(response.data.question_list);

                const parsedQuestions = ques.map(q => ({
                    ...q,
                    options: JSON.parse(q.options)
                }));
                setQuestions(parsedQuestions);
                setLoading(false)
            }
        })
    }
    useEffect(() => {
        fetchQuestions()
    }, []); // eslint-disable-line

    const handleOptionClick = (index, option, qid) => {
        let newSetData = responses;
        let newSetOptionData = responsesOption;
        newSetData[qid] = option;
        newSetOptionData[qid] = index;
        setResponses(newSetData);
        setSelectedValue(option);
        setSubmitResponses(newSetOptionData);
    };

    const handleNextQuestion = () => {
        let questionNumber = currentQuestion + 1;
        setCurrentQuestion(questionNumber);
        setSelectedValue(responses[questions[questionNumber].id])
    };

    const handlePreviousQuestion = () => {
        let questionNumber = currentQuestion - 1;
        setCurrentQuestion(questionNumber);
        setSelectedValue(responses[questions[questionNumber].id])
    };


    return (
        <>
            <div className="header bg-[#EEEEEE]">
                <Row className="md:mx-10 mx-2.5 lg:mx-32 flex justify-between items-center py-5">
                    <Col md={6}>
                        {isProfileVisible === "student-dashboard" ? (
                            <Link type="primary" to={'/student-dashboard/dashboard'} htmltype="submit" > <img src={base_url + '/uploads/allen-global-logo.png'} style={{ width: 130 }} alt="ALLEN global" /></Link>
                        ) : (isCounsellorVisible === "counsellor-dashboard" ? (
                            <Link type="primary" to={'/counsellor-dashboard/dashboard-home'} htmltype="submit" > <img src={base_url + '/uploads/allen-global-logo.png'} style={{ width: 130 }} alt="ALLEN global" /></Link>
                        ) : (<Link type="primary" to={'/student-portal/general-enquiry'} htmltype="submit" > <img src={base_url + '/uploads/allen-global-logo.png'} style={{ width: 130 }} alt="ALLEN global" /></Link>))

                        }

                    </Col>
                    <Col md={6} className='flex justify-end'>
                        <div className="flex">
                            <img alt="avatar" src={base_url + '/uploads/avatar.png'} className="user-img flex justify-start items-center" />
                            <Space style={{ paddingLeft: 5, paddingTop: 10 }}>
                                Hi,{decrypt(user_data.name, encriptionKey)}
                            </Space>
                        </div>

                    </Col>
                </Row>
            </div>
            {loading ? (
                <Skeleton
                    avatar
                    paragraph={{
                        rows: 28,
                    }}
                />
            ) : (
                <>

                    <div className="form-layout form-space md:mx-28 mx-5 my-10" style={{ border: '1px solid #ECECEC' }}>
                        <Row gutter={30}>
                            <Col md={24}>
                                <div className="flex justify-between bg-[#F0AA0C] py-2.5 px-7">
                                    <h1 className="text-[#1E1D1D] md:text-2xl uppercase text-center">Diagnostic Test</h1>
                                    <div className="flex">
                                        <ClockCircleOutlined className='text-[#1E1D1D] flex justify-center items-center md:text-lg text-base' />

                                        <span className="text-[#FCFCFC] md:text-base text-sm pl-2.5 flex justify-center items-center">{formatTime()} mintues</span>
                                    </div>
                                </div>
                            </Col>
                            <Col md={24}>


                                <div className='px-7 pt-7'>
                                    <Form>
                                        <Form.Item className="quiz">
                                            <h3 className="text-[#1E1D1D] text-lg">Question No. {currentQuestion + 1} / 42</h3>
                                            {questions[currentQuestion]?.heading ? (
                                                <div className="notice-info">
                                                    <p className="text-[#9C9C9C] text-base notice"><strong>Note :</strong> {questions[currentQuestion]?.heading}</p>
                                                </div>
                                            ) : ('')}


                                            <div className="py-5">
                                                <p className="text-[#9C9C9C] text-base">{questions[currentQuestion]?.question}</p>
                                            </div>
                                            {questions[currentQuestion].options == null ? (
                                                <Form.Item name={`question-${questions[currentQuestion].id}`} rules={[{ required: true }]}>
                                                    <Input.TextArea onBlur={(e) => handleOptionClick(e.target.id, e.target.value, questions[currentQuestion].id)} rows={4} placeholder="Type your answer here" />
                                                </Form.Item>
                                            ) : (

                                                <Radio.Group onChange={(e) => handleOptionClick(e.target.id, e.target.value, questions[currentQuestion].id)} value={selectedValue}>
                                                    {questions[currentQuestion].options.map((option, index) => (

                                                        <div className="p-2.5 my-5" style={{ border: '1px solid #ECECEC', borderRadius: 4 }}>
                                                            <Radio key={responses}
                                                                //onChange={() => handleOptionClick(index, questions[currentQuestion].id)}
                                                                value={option}
                                                                id={index}
                                                                //checked={selectedValue === option}
                                                                className='text-[#9C9C9C] text-base'>
                                                                <div dangerouslySetInnerHTML={{ __html: option }} />
                                                            </Radio>
                                                        </div>

                                                    ))}

                                                </Radio.Group>
                                            )}

                                            {/* {questions[currentQuestion].type === 'subjective' && (
                                                <Form.Item name={`question-${questions[currentQuestion].id}`} rules={[{ required: true }]}>
                                                    <Input.TextArea rows={4} placeholder="Type your answer here" />
                                                </Form.Item>
                                                )} */}
                                        </Form.Item>
                                        <div className="flex justify-end pt-16 pb-7">

                                            <div style={{ display: 'flex' }}>
                                                {currentQuestion > 0 && (
                                                    <Button onClick={() => {
                                                        handlePreviousQuestion();
                                                        //handleBackClick(questions[currentQuestion].id)
                                                    }} className='bg-[#2A6496]' type="primary" style={{ marginRight: 10 }}>
                                                        Previous
                                                    </Button>
                                                )}

                                                {currentQuestion < questions.length - 1 && (
                                                    <Button onClick={handleNextQuestion} className='bg-[#2A6496]' type="primary">
                                                        Next
                                                    </Button>
                                                )}
                                                {currentQuestion === questions.length - 1 && (
                                                    <Button onClick={() => {
                                                        handleQuizSubmit()
                                                        setTyModalVisible(true)
                                                    }} className='bg-[#2A6496]' type="primary">Submit</Button>

                                                )}
                                                {tymodalVisible && (
                                                    <Modal title={<img alt="mail" src='/images/send-mail.png' />} open={tymodalVisible}
                                                        onOk={() => setTyModalVisible(false)}
                                                        onCancel={() => setTyModalVisible(false)}
                                                        okButtonProps={{ style: { background: '#0F4275', color: '#fff', borderRadius: 7 } }}
                                                        cancelButtonProps={{ style: { display: 'none' } }}
                                                    >
                                                        <h3 className="text-[#FFC040] text-2xl text-center">Successfully Submiited</h3>
                                                        <p className="text-[#5B5B5B] text-base text-center">Thank You !!</p>
                                                    </Modal>
                                                )}
                                            </div>

                                        </div>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </>
            )
            }
        </>
    )
}

export default DiagnosticQuiz