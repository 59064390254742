import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Layout, Form } from "antd";
import TeacherLayout from "../components/teacher-layout";
import { useParams } from 'react-router-dom'
import { api_url } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/custom.css';

const { Content } = Layout;


const Feedback = () => {
  const login_user = JSON.parse(localStorage.getItem('user'));
  const { classId } = useParams();

  if (!login_user) {
    window.location.href = "/";
  }
  const user_data = login_user.user;

  const [loading, setLoading] = useState(true);
  const [studentList, setStudentList] = useState([{ user_id: "", name: "", center: "", attendance: "", video: "", cpi: "", cs: "", dp: "", css: "", asr: "", pgd: "" }]);
  const [form] = Form.useForm();

  const fetchClassStudentList = async () => {

    await axios.post(api_url + 'faculty/get-classwise-student', {
      class_id: classId

    }).then((res) => {

      const st_list = res.data.user_list;

      const k = st_list.map((item, index) =>
      ({
        'user_id': item.user_id,
        'name': item.name,
        'attendance': (item.attendance) ? item.attendance : '',
        'center': item.center,
        'video': (item.video) ? item.video : '',
        'cpi': (item.cpi) ? item.cpi : '',
        'cs': (item.cs) ? item.cs : '',
        'dp': (item.dp) ? item.dp : '',
        'css': (item.css) ? item.css : '',
        'asr': (item.asr) ? item.asr : '',
        'pgd': (item.pgd) ? item.pgd : '',
      })
      );
      setStudentList(k);
      setLoading(false)
    })
  }


  useEffect(() => {
    fetchClassStudentList()

  }, []) // eslint-disable-line

  const handleChange = (e, index) => {
    const newData = [...studentList];
    newData[index][e.target.name] = e.target.value;
    setStudentList(newData);
  };


  const onFinish = () => {
    axios.post(api_url + 'faculty/submit-class-feedback', {
      class_id: classId, studentList: studentList, faculty_id: user_data.id,
    }).then(result => {

      if (result.data.status) {
        toast.success(result.data.message, {
          position: toast.POSITION.TOP_CENTER
        });

        const timer = setTimeout(() => { // eslint-disable-line
          window.location.reload();
        }, 3000);

      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER
        });
      }


    }).catch(error => {
      console.error(error);
    });


  };


  return (
    <>

      <TeacherLayout>
        {loading ? (
          <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
        ) : (
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              background: '#fff',
              width: '100%'
            }}
          >
            <div className="mx-0 mt-0">
              <Row>
                <Col md={24} xs={24}>
                  <div className="bg-[#114D89] px-5 py-2.5">
                    <h1 className="text-white text-2xl ">STUDENT FEEDBACK FORM</h1>
                  </div>
                </Col>
              </Row>
              <div className="py-5 px-5" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                <Row style={{ display: 'block' }}>
                  <Form
                    name="normal_login"
                    className="my-7 mx-5"
                    onFinish={onFinish}
                    form={form}
                  >

                    <Col md={24} className="responsive-table">
                      <div className="test-result">

                        <table>
                          <thead>
                            <tr>
                              <th>SR. NO.</th>
                              <th>STUDENT</th>
                              <th>CENTRE</th>
                              <th>ATTENDANCE</th>
                              <th>VIDEO</th>
                              <th>CLASS PARTICIPATION AND INTERACTION</th>
                              <th>COMMUNICATION SKILLS</th>
                              <th>DEGREE OF PREPAREDNESS</th>
                              <th>COMPREHENSION SKILLS</th>
                              <th>ANSWER UPON CALL - WAS THE STUDENT RESPONSIVE?</th>
                              <th>PARTICIPATION IN GROUP DISCUSSION</th>
                            </tr>
                          </thead>
                          <tbody>
                            {studentList.map((row, index) => (
                              <tr key={index} style={{ marginTop: '10px' }}>
                                <td>{index + 1}</td>
                                <td>{decrypt(row.name, encriptionKey)}<input type="hidden" name="user_id" value={row.user_id} /></td>
                                <td>{row.center}</td>
                                <td>
                                  <select style={{ width: '100%' }}
                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                    name="attendance" onChange={(e) => handleChange(e, index)} value={row.attendance == null ? '' : row.attendance} >
                                    <option value="">Select</option>
                                    <option value="Present" >Present</option>
                                    <option value="Absent" >Absent</option>
                                    <option value="Present in the first half only" >Present in the first half only</option>
                                    <option value="Present in the second half only" >Present in the second half only</option>

                                  </select>
                                </td>
                                <td>
                                  <select style={{ width: '100%' }}
                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                    name="video" onChange={(e) => handleChange(e, index)} value={row.video == null ? '' : row.video}>
                                    <option value="">Select</option>
                                    <option value="On throughout the Class">On throughout the Class</option>
                                    <option value="Off throughout the Class">Off throughout the Class</option>
                                    <option value="On and Off">On and Off</option>
                                  </select>
                                </td>
                                <td>
                                  <select style={{ width: '100%' }}
                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                    name="cpi" onChange={(e) => handleChange(e, index)} value={row.cpi == null ? '' : row.cpi}>
                                    <option value="">Select</option>
                                    <option value="Constantly presents clear ideas with demonstartion of listening, summarising, and clarifictaion skills. (Outstanding)">Constantly presents clear ideas with demonstartion of listening, summarising, and clarifictaion skills. (Outstanding)</option>
                                    <option value="Often presents ideas clearly by regularly asking questions (Good)">Often presents ideas clearly by regularly asking questions (Good)</option>
                                    <option value="Occasionaly participates in the class (Satisfactory)">Occasionaly participates in the class (Satisfactory)</option>
                                    <option value="Responds only when asked. (Marginal)">Responds only when asked. (Marginal)</option>
                                    <option value="Does not respond. (Poor)">Does not respond. (Poor)</option>
                                  </select>
                                </td>
                                <td>
                                  <select style={{ width: '100%' }}
                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                    name="cs" onChange={(e) => handleChange(e, index)} value={row.cs == null ? '' : row.cs}>
                                    <option value="">Select</option>
                                    <option value="Excellent Proficiency in communicating ideas using appropriate words.">Excellent Proficiency in communicating ideas using appropriate words.</option>
                                    <option value="Good Proficiency - Clear and Concise">Good Proficiency - Clear and Concise</option>
                                    <option value="Moderate Proficiency - Vocabulary is adequate for discussing familiar topics.">Moderate Proficiency - Vocabulary is adequate for discussing familiar topics.</option>
                                    <option value="Basic Proficiency - Communicates basic information with some effort.">Basic Proficiency - Communicates basic information with some effort.</option>
                                    <option value="Limited Proficiency - Rarely participates in class activities and requires significant support to express ideas verbally.">Limited Proficiency - Rarely participates in class activities and requires significant support to express ideas verbally.</option>
                                  </select>
                                </td>


                                <td>
                                  <select style={{ width: '100%' }}
                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                    name="dp" onChange={(e) => handleChange(e, index)} value={row.dp == null ? '' : row.dp}>
                                    <option value="">Select</option>
                                    <option value="Excellent Preparedness -Well Prepared for the lecture. The student has done basic research.">Excellent Preparedness -Well Prepared for the lecture. The student has done basic research.</option>
                                    <option value="Above Average Preparedness - Has done some basic research and is ready with his viewpoints.">Above Average Preparedness - Has done some basic research and is ready with his viewpoints. </option>
                                    <option value="Average Preparedness - Lacks depth or thorough preparation.">Average Preparedness - Lacks depth or thorough preparation.</option>
                                    <option value="Below Average Preparedness - Demonstrates minimal understanding of the topic or lesson being discussed.">Below Average Preparedness - Demonstrates minimal understanding of the topic or lesson being discussed.</option>
                                    <option value="Unprepared - comprehension and preparation are lacking.">Unprepared - comprehension and preparation are lacking.</option>

                                  </select>
                                </td>
                                <td>
                                  <select style={{ width: '100%' }}
                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                    name="css" onChange={(e) => handleChange(e, index)} value={row.css == null ? '' : row.css}>
                                    <option value="">Select</option>
                                    <option value="Excellent Comprehension - Makes significant contributions that enrich the group discussion, offering unique perspectives and enhancing overall comprehension.">Excellent Comprehension - Makes significant contributions that enrich the group discussion, offering unique perspectives and enhancing overall comprehension.</option>
                                    <option value="Good Comprehension - Provides thoughtful contributions that enhance the group's understanding and analysis of the material.">Good Comprehension - Provides thoughtful contributions that enhance the group's understanding and analysis of the material.</option>
                                    <option value="Moderate Comprehension - Demonstrates a solid understanding of the discussion topic or text, grasping main ideas and key details.">Moderate Comprehension - Demonstrates a solid understanding of the discussion topic or text, grasping main ideas and key details.</option>
                                    <option value="Basic Comprehension - Shows some understanding of the main ideas and key points discussed.">Basic Comprehension - Shows some understanding of the main ideas and key points discussed.</option>
                                    <option value="Limited Comprehension - Demonstrates poor understanding of the discussion topic or text being analyzed.">Limited Comprehension - Demonstrates poor understanding of the discussion topic or text being analyzed.</option>
                                  </select>
                                </td>
                                <td>
                                  <select style={{ width: '100%' }}
                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                    name="asr" onChange={(e) => handleChange(e, index)} value={row.asr == null ? '' : row.asr}>
                                    <option value="">Select</option>
                                    <option value="Highly Responsive - Requires minimal prompting or intervention from the teacher, consistently demonstrating attentiveness and engagement.">Highly Responsive - Requires minimal prompting or intervention from the teacher, consistently demonstrating attentiveness and engagement.</option>
                                    <option value="Moderately Responsive - Engages in class discussions and activities with some level of enthusiasm, contributing relevant responses.">Moderately Responsive - Engages in class discussions and activities with some level of enthusiasm, contributing relevant responses.</option>
                                    <option value="Non-responsive - The student does not respond at all when called upon, remaining silent or unengaged.">Non-responsive - The student does not respond at all when called upon, remaining silent or unengaged.</option>
                                  </select>
                                </td>
                                <td>
                                  <select style={{ width: '100%' }}
                                    className="ant-input css-dev-only-do-not-override-k83k30"
                                    name="pgd" onChange={(e) => handleChange(e, index)} value={row.pgd == null ? '' : row.pgd}>
                                    <option value="">Select</option>
                                    <option value="Active Participation - Actively engages in the group discussion, contributing thoughtfully and constructively to the conversation.">Active Participation - Actively engages in the group discussion, contributing thoughtfully and constructively to the conversation.</option>
                                    <option value="Moderate Participation - Engages with peers by listening attentively, building on others' ideas, and sharing personal perspectives.">Moderate Participation - Engages with peers by listening attentively, building on others' ideas, and sharing personal perspectives.</option>
                                    <option value="Limited Participation - Rarely contributes to the group discussion, remaining mostly silent throughout">Limited Participation - Rarely contributes to the group discussion, remaining mostly silent throughout.</option>

                                  </select>
                                </td>

                              </tr>
                            ))}
                          </tbody>
                        </table>

                      </div>



                    </Col>
                    <button
                      type="submit" // Change the type to submit for form submission
                      className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5"
                      style={{ marginTop: '10px', float: 'right' }}
                    >
                      Submit
                    </button>

                  </Form>
                </Row>
              </div>
            </div>

          </Content>
        )
        }
      </TeacherLayout></>
  )
}

export default Feedback