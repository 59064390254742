import React, { useState, useEffect } from "react";
import { Col, Row, Layout } from 'antd';
import '../styles/dashboard.css';
import '../styles/responsive.css';
import TeacherLayout from "../components/teacher-layout";
import { ClockCircleOutlined } from '@ant-design/icons'
import axios from 'axios';
import { api_url } from '../components/constants';
const { Content } = Layout;

const TeacherDashboard = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([
        { id: "", date: "", time: "", topic: "" },
    ]);

    useEffect(() => {
        const fetchUpcomingClass = async () => {
            await axios.post(api_url + 'faculty/get-upcoming-classess', {
                faculty_id: user_data.id

            }).then((res) => {
                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }
                const pbs_data = res.data.live_class;

                setEvents(pbs_data);
                setLoading(false)

            })
        }
        fetchUpcomingClass()

    }, [user_data.id])

    return (
        <>
            <TeacherLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <Row gutter={30}>

                            <Col md={24} xs={24}>

                                <Row>
                                    <Col md={8} xs={24}>
                                        <div className="flex py-2.5 px-5" style={{ boxShadow: '0px 1px 8px #00000029', borderRadius: 10 }}>
                                            <img src='/images/calendar-icon.png' alt='calendar-icon' />
                                            {/* <h1 className="text-[#8A8A8A] text-base pl-5">{ date +'-'+month+'-'+year }</h1> */}
                                            <h1 className="text-[#8A8A8A] text-base pl-5">All Notifications</h1>
                                        </div>
                                    </Col>
                                </Row>


                                <div className="bg-[#F6F6F6] mt-7 px-7 py-7">
                                    {events.length === 0 ? (
                                        <div className="flex justify-center items-center">
                                            <div className="flex py-5 px-32 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                                                <span className="text-[#F8A30C] text-xl text-center">No Notification found.</span>
                                            </div>
                                        </div>
                                    ) : (
                                        events.map((value, k) => (
                                            <div className="flex justify-between p-5 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                                                <div className="flex">
                                                    <div className="bg-[#FFEFCC] p-2.5" style={{ borderRadius: 2 }}>
                                                        <img alt="counselling" src='/images/counselling.png' />
                                                    </div>
                                                    <div className="pl-2.5">
                                                        <h3 className="text-[#8A8A8A] text-base">Upcoming Class</h3>
                                                        <span className="text-[#F8A30C] text-sm">Your have Schedule a class on {value.date}</span>
                                                    </div>
                                                </div>
                                                <h4 className="text-[#0F4275] text-sm flex items-center"><ClockCircleOutlined className="pr-2.5" />Time : {value.time}</h4>
                                            </div>
                                        ))
                                    )}
                                </div>


                            </Col>
                        </Row>

                    </Content>
                )}
            </TeacherLayout>
        </>
    )
}

export default TeacherDashboard