import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Layout } from "antd";
import TeacherLayout from "../components/teacher-layout";
import { useParams, useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';
const { Search } = Input;
const { Content } = Layout;

const PerformanceRecord = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }

    const [loading, setLoading] = useState(true);
    const [performanceList, setPerformanceList] = useState([]);
    const [userName, setUserName] = useState(null);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });


    useEffect(() => {
        const fetchPerformanceList = async () => {

            await axios.post(api_url + 'faculty/performance-report-list', {
                user_id: id

            }).then((res) => {
                setUserName(decrypt(res.data.user_data.name, encriptionKey));

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }


                const st_list = res.data.data;

                const k = st_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'id': item.id,
                    'user_id': item.user_id,
                    'month': item.month,
                    'class': decrypt(item.class, encriptionKey),
                    'course': decrypt(item.course, encriptionKey),
                })
                );

                setPerformanceList(k);
                setTableParams();
                setLoading(false)
            })
        }
        fetchPerformanceList()
    }, [id])

    const onEditRequest = (record) => {
        navigate("/teacher-dashboard/update-performance/" + record.id)
    };

    const onCreateReport = () => {
        console.log(id)
        navigate('/teacher-dashboard/performance-report/' + id)
    }

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",
            align: 'center'
        },

        {
            key: "2",
            title: "Month",
            dataIndex: "month",
            align: 'center'
        },

        {
            key: "3",
            title: "Class",
            dataIndex: "class",
            align: 'center'
        },
        {
            key: "4",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "5",
            title: "Action",
            align: 'center',
            render: (record) => {
                return (
                    <>
                        <Button onClick={() => { onEditRequest(record); }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Update Report</Button>
                    </>
                );
            },
        },
    ];

    return (
        <TeacherLayout>
            {loading ? (
                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
            ) : (
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mx-16 mt-10">
                        <Row>
                            <Col md={24}>
                                <div className="flex justify-between bg-[#0F4275] py-2.5 px-7">
                                    <h1 className="text-white text-2xl ">Performance Report List : {userName}</h1>
                                    <button type='button' className="text-right text-white text-xl bg-transparent border-none p-0 m-0 cursor-pointer" onClick={() => onCreateReport()}>Create Report</button>
                                </div>
                            </Col>
                        </Row>
                        <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                            <Row>

                                <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }} className='mt-7 mb-5'>

                                    <Search placeholder="Search...." />
                                </Col>

                                <Col md={24}>
                                    <Table
                                        className="record"
                                        bordered
                                        columns={columns}
                                        pagination={tableParams.pagination}
                                        dataSource={performanceList}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Content>
            )}
        </TeacherLayout>
    )
}

export default PerformanceRecord