import React, { useState, useEffect } from 'react';
import { Modal, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_url } from '../components/constants';

const CurricularRecords = ({ show, hide }) => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([{ year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", learning: "" }]);

    const handleAddRow = () => {
        setTableData([...tableData, { year: "", class: "", activity: "", type: "", role: "", level: "", marks: "", learning: "" }]);
    };

    const handleChange = (e, index) => {
        const newData = [...tableData];
        newData[index][e.target.name] = e.target.value;
        setTableData(newData);

    };

    const handleSubmit = () => {

        const rows = [];
        let error = "";
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].year !== "" && tableData[i].year !== null && tableData[i].class !== "" && tableData[i].class !== null
                && tableData[i].activity !== "" && tableData[i].activity !== null && tableData[i].type !== "" && tableData[i].type !== null
                && tableData[i].level !== null) {
                rows.push(tableData[i]);
            } else {
                error = "1";
            }

        }

        if (error) {
            toast.error("Please fill your curricular details !", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }


        axios.post(api_url + 'user/update_curricular_records', {
            user_id: user_data.id,
            data: rows,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    const timer = setTimeout(() => { // eslint-disable-line
                        window.location.reload();
                    }, 3000);
                } else {
                    toast.error("Please fill your curricular details !", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    };

    const fetchUserData = async () => {

        //  pushPage('student-profile-report');

        await axios.post(api_url + 'user/get_curricular_data', {
            user_id: user_data.id,

        }).then((res) => {
            let data = res.data.curricular_data;

            if (data) {
                let act_records = JSON.parse(data);

                setTableData(act_records);
                //console.log(act_records);
            }

            setLoading(false)

        })
    }

    useEffect(() => {
        fetchUserData()

    }, []) // eslint-disable-line

    const handleRemove = (index) => {
        const newTable = [...tableData]
        newTable.splice(index, 1)
        setTableData(newTable)
    }

    return (
        <>
            <ToastContainer />
            {loading ? (
                <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
            ) : (
                <Modal
                    title="Co-Curricular"
                    open={show}
                    onCancel={hide}
                    onOk={() => {
                        handleSubmit();
                    }
                    }
                    okText='Save'
                    width={1200}
                    cancelText='Cancel'
                    okButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                    cancelButtonProps={{ style: { padding: '0px 20px 5px', background: '#2A6496', color: '#fff', fontSize: 16, fontWeight: 300 } }}
                >
                    <form >
                        <table className='curricular-data'>
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Class</th>
                                    <th>Activity</th>
                                    <th>Type</th>
                                    <th>Your Role</th>
                                    <th>Level</th>
                                    <th>Rank</th>
                                    <th>Learning</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <select value={row.year}
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                name="year"
                                                onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="2015">2015</option>
                                                <option value="2016">2016</option>
                                                <option value="2017">2017</option>
                                                <option value="2018">2018</option>
                                                <option value="2019">2019</option>
                                                <option value="2020">2020</option>
                                                <option value="2021">2021</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.class}
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                name="class"
                                                onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="activity"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.activity}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>
                                        <td>
                                            <select value={row.type} name="type" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="Achievement">Achievement</option>
                                                <option value="Participant">Participant</option>
                                                <option value="Honour">Honour</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.role} name="role" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="Participant">Participant</option>
                                                <option value="Leader">Leader</option>
                                                <option value="Not Applicable">Not Applicable</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select value={row.level} name="level" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select' onChange={(e) => handleChange(e, index)}  >
                                                <option value="">Select</option>
                                                <option value="School">School</option>
                                                <option value="Interschool">Interschool</option>
                                                <option value="District">District</option>
                                                <option value="State">State</option>
                                                <option value="National">National</option>
                                                <option value="International">International</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                name="marks"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.marks == null ? '' : row.marks}
                                                onChange={(e) => handleChange(e, index)}
                                                step="0.01" // Allows input of numbers with up to 2 decimal places
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                name="learning"
                                                className="ant-input css-dev-only-do-not-override-k83k30"
                                                value={row.learning}
                                                onChange={(e) => handleChange(e, index)}
                                            />
                                        </td>

                                        <td>{
                                            (index > 0) ? <Popconfirm title="Sure to delete?" onConfirm={() => handleRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                <button className='bg-transparent border-none p-0 m-0 cursor-pointer'>
                                                    <DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' />
                                                </button>
                                            </Popconfirm> : ""}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <button type="button" className="ant-btn css-dev-only-do-not-override-k83k30 ant-btn-primary text-base text-white bg-[#FCC222] rounded-lg pb-1.5 px-5" onClick={handleAddRow} style={{ float: 'right' }}>
                            Add Row
                        </button>
                    </form>

                </Modal>
            )}
        </>

    )

}

export default CurricularRecords
