import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout } from "antd";
import DashboardLayout from '../components/dashboard-layout';
import { useNavigate } from 'react-router-dom'
import { api_url } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';
const { Search } = Input;
const { Content } = Layout;


const PtmSession = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [classList, setClassList] = useState([]);


    useEffect(() => {

        const fetchClassData = async () => {

            await axios.post(api_url + 'user/get_ptm_session', {
                user_id: user_data.id

            }).then((res) => {

                const st_list = res.data.class_list;

                if (st_list) {
                    const k = st_list.map((item, index) =>
                    ({
                        'sno': index + 1,
                        'session': item.class_name,
                        //'center': item.center,
                        'date': item.date + ' ' + item.start_time,
                        'duration': item.duration,
                        // 'time': item.time_slot,
                        'status': item.status,
                        'faculty': decrypt(item.faculty, encriptionKey),
                        //'desc': item.description,
                        'link': item.class_link,
                        'class_id': item.id,
                        'meeting_id': item.meeting_id,
                        'study_material': item.study_material,
                        'class_status': item.class_status,
                    })
                    );

                    setClassList(k);
                    setLoading(false)
                }
            })
        }
        fetchClassData()
    }, [user_data.id])

    const joinZoomMeeting = (record) => {
        navigate("/student-dashboard/join-class/" + record.meeting_id);
    };


    const columns = [
        {
            key: '1',
            title: 'Sno',
            dataIndex: 'sno',
        },
        {
            key: '2',
            title: 'Session',
            dataIndex: 'session',
            align: 'center',
        },
        {
            key: '3',
            title: 'Faculty',
            dataIndex: 'faculty',
            align: 'center',
        },

        {
            key: '4',
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
        },

        {
            key: '5',
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
        },
        {
            key: '6',
            title: 'Action',
            render: (record) => {
                return (
                    <Space>
                        {record.meeting_id != null && record.status !== 'Complete' ? (
                            record.class_status === "1" && record.status === 'Pending' ? (
                                <Button
                                    className="bg-[#F0AA0C] text-white"
                                    style={{ borderRadius: 11 }}
                                    onClick={() => joinZoomMeeting(record)}
                                >
                                    Join Class
                                </Button>
                            ) : (record.status === "Cancelled" ? ('-') : (<Button
                                className="bg-[#F0AA0C] text-white"
                                disabled
                                style={{ borderRadius: 11 }}
                            >
                                Join Class
                            </Button>)

                            )
                        ) : (
                            ''
                        )}

                    </Space>
                );
            },
        },
    ];

    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        <div className="mx-0 mt-0">
                            <Row>
                                <Col md={24} xs={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5">
                                        <h1 className="text-white text-2xl ">PTM Session</h1>
                                    </div>
                                </Col>
                            </Row>
                            <div className="py-5 px-5" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <Row>

                                    <Col md={6} xs={24} style={{ display: 'flex', justifyContent: 'space-between' }} className='mb-5'>

                                        <Search placeholder="Search...." />
                                    </Col>
                                    <Col md={24} className="responsive-table">
                                        <Table
                                            className="record"
                                            bordered
                                            columns={columns}
                                            dataSource={classList}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </Content>
                )
                }
            </DashboardLayout>
        </>
    )
}

export default PtmSession