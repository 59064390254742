import React, { useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import CounsellorLayout from "./counsellor-layout";
import { api_url } from '../components/constants';
import Swal from 'sweetalert2';

const Application = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    useEffect(() => {
        const fetchApplicationData = async () => {
            await axios.post(api_url + 'application_login', {
                user_id: user_data.id,
            }).then((res) => {
                if (res.data.status) {
                    const newWindow = window.open(res.data.url, '_blank');
                    if (newWindow) {
                        newWindow.focus();
                    }
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: "You are not authorized.",
                    }).then(() => {
                        // Redirect to another page after closing the error alert
                        window.location.href = '/counsellor-dashboard/dashboard-home';
                    });
                }

            });
        }
        fetchApplicationData()
    }, [user_data.id])

    return (
        <CounsellorLayout>

        </CounsellorLayout>
    )
}

export default Application