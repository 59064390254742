import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import { Button, Col, Row, Layout, Modal, Input, Form, Select, } from 'antd';
import DashboardLayout from '../components/dashboard-layout';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { decrypt, encriptionKey } from '../components/utils';
const { Content } = Layout;


const DoubtSection = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [doubtLoading, setDoubtLoading] = useState(true);
    const [facultyList, setFaculty] = useState({ label: '', value: '' });
    const [doubtData, setDoubtData] = useState([{ id: "", user_id: "", query: "", created_at: "", faculty_id: "" }]);
    const [modalVisible, setModalVisible] = useState(false);
    const [file, setFile] = useState(null);
    const fetchFacultyList = async () => {

        await axios.post(api_url + 'batch-wise-faculty', {
            user_id: user_data.id
        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            let c_options = [];
            res.data.faculty.forEach((element, index) => {
                if (element.faculty) {
                    c_options.push({
                        label: decrypt(element.faculty, encriptionKey),
                        value: element.faculty_id,
                    });
                }

            })

            setFaculty(c_options);
            setLoading(false)

        })
    }
    const fetchDoubtList = async () => {
        await axios.post(api_url + 'ajax/get_doubt_list', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            if (res.data.doubt) {
                const doubt_data = res.data.doubt;
                setDoubtData(doubt_data);
            }

            setDoubtLoading(false)

        })
    }
    useEffect(() => {
        fetchFacultyList()
        fetchDoubtList()
    }, []) // eslint-disable-line

    const onFinish = (values) => {

        const formData = new FormData();

        if (file) {
            formData.append('files', file[0]);
        }
        formData.append('doubt', values.doubt);
        formData.append('faculty_id', values.faculty);
        formData.append('user_id', user_data.id);

        axios.post(api_url + 'ajax/create_doubt', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(result => {

            if (result.data.status) {
                toast.success(result.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

                const timer = setTimeout(() => { // eslint-disable-line
                    window.location.reload();
                }, 3000);

            } else {
                toast.error("Something went wrong", {
                    position: toast.POSITION.TOP_CENTER
                });
            }


        }).catch(error => {
            console.error(error);
        });
    };

    const viewConversation = (id) => {
        navigate('/student-dashboard/doubt-area/' + id)
    }

    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background lg:p-7 md:p-5"
                        style={{
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mb-0 mx-0">
                            <Row>
                                <Col md={24}>
                                    <div className="form-layout form-space">
                                        <div className=" bg-[#0F4275] py-2.5 px-7 md:flex justify-between">
                                            <h1 className="text-[#fff] text-2xl uppercase">Doubt Section</h1>
                                            <button type='button' className="text-right text-white text-xl bg-transparent border-none p-0 m-0 cursor-pointer" onClick={() => setModalVisible(true)}>Create Doubt</button>
                                            <Modal title="Ask Your Query"
                                                open={modalVisible}
                                                onCancel={() => setModalVisible(false)}
                                                footer={null}
                                            >
                                                <Form
                                                    name="normal_login"
                                                    className="my-7"
                                                    onFinish={onFinish}
                                                >
                                                    <Row>
                                                        <Col md={24}>
                                                            <Form.Item
                                                                name='doubt'
                                                                label="Query"
                                                                wrapperCol={{ span: 24 }}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please Enter Doubt !',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input.TextArea rows={4} placeholder="Enter Your Doubt Question Here ! . . . . . . . . . . . . . . ." type="textarea" />
                                                            </Form.Item>
                                                            <Col md={24}>
                                                                <Form.Item name='upload'
                                                                    label="Upload File"
                                                                    wrapperCol={{ span: 24 }}>
                                                                    <input type="file" onChange={(e) => setFile(e.target.files)} />
                                                                    {/* <UploadOutlined className=' text-3xl text-[#BFBFBF] font-bold'

                                                                    /> */}

                                                                </Form.Item>
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                    {loading ? (
                                                        <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
                                                    ) : (
                                                        <Row>
                                                            <Col md={24}>
                                                                <Form.Item
                                                                    name="faculty"
                                                                    label="Faculty"
                                                                    wrapperCol={{ span: 24 }}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please Select Faculty !',
                                                                        },
                                                                    ]}
                                                                >

                                                                    <Select
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        placeholder="Select Faculty"
                                                                        options={facultyList}

                                                                    />
                                                                </Form.Item>

                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {/* <Row>
                                                    <Col md={24}>
                                                        <Form.Item label="Upload" name="file" >
                                                            <input type="file" onChange={(e) => setFile(e.target.files)} multiple />
                                                        </Form.Item>
                                                    </Col>
                                                </Row> */}
                                                    <Row>
                                                        <Col md={24}>
                                                            <Button className='bg-[#F0AA0C] text-white' style={{ float: 'right' }} htmlType="submit">
                                                                Submit
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Modal>
                                        </div>
                                        <div className='px-2 py-3'>

                                            {doubtLoading ? (
                                                <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
                                            ) : (
                                                doubtData.length > 0 ? (doubtData.map((row, index) => (
                                                    <Row key={index}>
                                                        <Col md={24}>
                                                            <div className='md:flex justify-between bg-[#F2EEEB] px-5 py-5 m-1.5' style={{ borderRadius: 10 }}>
                                                                <div>
                                                                    <h4 className='text-[#F1AC12] text-base mb-2.5'>Date : {row.created_at}</h4>
                                                                    <p className='text-[#707070] text-base'>{row.query}</p>
                                                                </div>
                                                                <div className='flex justify-center items-center'>
                                                                    {row.cc > 1 ?
                                                                        (<Button onClick={() => { viewConversation(row.id) }} className='bg-[#2E6798] text-white rounded-full'>View Conversation</Button>) :
                                                                        (<Button className='bg-[#EC9655] text-white rounded-full'>Request pending</Button>)
                                                                    }


                                                                </div>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                ))) : (<div className="my-24">
                                                    <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                                </div>)
                                            )}
                                        </div>


                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Content>
                )
                }
            </DashboardLayout>
        </>
    )
}

export default DoubtSection