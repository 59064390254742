import React, { useState, useEffect } from "react";
import '../styles/dashboard.css';
import { Button, Col, Row, Collapse, Layout, Modal, Form, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import TeacherLayout from "../components/teacher-layout";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { api_url, } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Content } = Layout;
const { Panel } = Collapse

const DiagnosticTeacherResult = () => {
    const { id } = useParams();
    const [question, setQuestion] = useState();;
    const [attempt, setAttempt] = useState('0');
    const [total, setTotal] = useState('0');
    const [marks, setMarks] = useState('0');
    const [subjective, setSubjectiveMarks] = useState('0');
    const [class_Name, setClass] = useState();
    const [correct, setCorrect] = useState('0');
    const [incorrect, setIncorrect] = useState('0');
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const panelStyle = {
        marginBottom: 24,
        border: 'none',
        background: '#E3E3E3',
        boxShadow: '0px 3px 3px #00000029',
        borderRadius: 5,
        color: '#fff'
    };


    useEffect(() => {
        const fetchResult = async () => {
            await axios.post(api_url + 'get_user_test_report', {
                user_id: id,

            }).then((response) => {
                console.log(response.data)
                // return false;
                if (response.data.status) {
                    const k = response.data.test_report.map((item, index) =>
                    ({
                        'sno': index + 1,
                        'question': item.question,
                        'option': item.option,
                        'solution': item.solution,
                        'correct_answer': item.correct_answer,
                        'user_answer': item.user_answer,
                    })
                    );
                    setAttempt(response.data.attempt);
                    setTotal(response.data.total);
                    setCorrect(response.data.correct);
                    setIncorrect(response.data.incorrect);
                    setMarks(response.data.marks);
                    setClass(response.data.class);
                    setSubjectiveMarks(response.data.subjective);
                    setQuestion(k);
                    setLoading(false)
                }
            })
        }
        fetchResult()

    }, [id]);

    const onFinish = (values) => {

        axios.post(api_url + 'faculty/submit_user_level', {
            user_id: id,
            faculty_id: user_data.id,
            level: (values.level ? values.level : ""),
            subjective_score: values.subjective_score,
            objective_score: correct,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => {  // eslint-disable-line
                        window.location.reload();
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
    };
    return (
        <>
            <TeacherLayout>
                <Content
                    className="site-layout-background lg:p-7 md:p-5"
                    style={{
                        margin: '20px 16px 24px',
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div className="mb-16 mx-10">
                        <Row gutter={30}>
                            <Col md={6}>
                                <div className="flex justify-between bg-[#F2EEEB] p-5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 5 }}>
                                    <div>
                                        <h4 className="text-[#707070] text-base mb-2.5">Total Score</h4>
                                        <span className="text-[#E0680E] text-xl">{marks} out of 50 marks</span>
                                    </div>
                                    <img className="question-img" src='/images/total.png' alt='total' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="flex justify-between bg-[#EFFEFB] p-5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 5 }}>
                                    <div>
                                        <h4 className="text-[#707070] text-base mb-2.5">Correct Answer</h4>
                                        <span className="text-[#4CBD50] text-xl">{correct} Question Correct</span>
                                    </div>
                                    <img className="question-img" src='/images/correct.png' alt='correct' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="flex justify-between bg-[#FDF9EF] p-5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 5 }}>
                                    <div>
                                        <h4 className="text-[#707070] text-base mb-2.5">Incorrect Answer</h4>
                                        <span className="text-[#E2A51F] text-xl">{incorrect} Question Incorrect</span>
                                    </div>
                                    <img className="question-img" src='/images/incorrect.png' alt='incorrect' />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="flex justify-between bg-[#F9F8FF] p-5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 5 }}>
                                    <div>
                                        <h4 className="text-[#707070] text-base mb-2.5">Skipped Question</h4>
                                        <span className="text-[#735EED] text-xl">{total - attempt} Question Skipped</span>
                                    </div>
                                    <img className="question-img" src='/images/skipped.png' alt='skipped' />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row gutter={30}>

                        <Col md={24}>
                            <div className="form-layout form-space">
                                <div className=" bg-[#0F4275] py-2.5 px-7">
                                    <h1 className="text-[#fff] text-2xl uppercase">Diagnostic Test Record</h1>
                                </div>


                                {loading ? (
                                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                                ) : (

                                    <Row>

                                        <Col md={24} className='lg:px-10 px-5'>
                                            <Collapse defaultActiveKey={['1']}
                                                bordered={false}
                                                expandIconPosition='end'
                                                style={{ marginTop: "20px", }}
                                                expandIcon={({ isActive }) => (
                                                    <DownOutlined
                                                        style={{ color: '#A2A1A1', paddingTop: 10, }}
                                                        rotate={isActive ? 180 : 0}
                                                    />
                                                )}
                                            >

                                                {question.map((item, index) => (
                                                    <Panel header={`Q${index + 1}: ${item.question}`} key={item.sno} style={panelStyle}>
                                                        <div className="py-5 px-2.5">
                                                            {item.correct_answer === "" ? (
                                                                <p className="text-[#707070] text-base"><strong className="pr-2.5">Answer-</strong>{item.user_answer}</p>
                                                            ) : (
                                                                <>
                                                                    <ul className="pl-2.5">
                                                                        {item.option && item.option.map((val, key) => (
                                                                            <li>
                                                                                <strong className={
                                                                                    item.correct_answer === String.fromCharCode(65 + key)
                                                                                        ? "text-green-500"
                                                                                        : item.user_answer === String.fromCharCode(65 + key)
                                                                                            ? "text-red-500"
                                                                                            : ""}>
                                                                                    {String.fromCharCode(65 + key)}. </strong>
                                                                                <span
                                                                                    className={
                                                                                        // set class based on condition
                                                                                        item.correct_answer === String.fromCharCode(65 + key)
                                                                                            ? "text-green-500"
                                                                                            : item.user_answer === String.fromCharCode(65 + key)
                                                                                                ? "text-red-500"
                                                                                                : ""
                                                                                    }
                                                                                >
                                                                                    {val}
                                                                                </span>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                    <p className={`text-${item.user_answer === item.correct_answer ? 'green' : 'red'}-500 text-base correct-ans pb-5`}>
                                                                        <strong className="text-[#707070] pr-2.5">Correct Ans-</strong>{item.correct_answer}
                                                                    </p>
                                                                    <p className="text-[#707070] text-base"><strong className="pr-2.5">Solution-</strong>{item.solution}</p>
                                                                </>


                                                            )}


                                                        </div>
                                                    </Panel>
                                                ))}
                                            </Collapse>
                                        </Col>
                                    </Row>
                                )}
                                <Row >
                                    <Col md={24} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
                                        {subjective === "" || subjective === 0 || subjective === "0" ? (
                                            <Button onClick={() => setModalVisible(true)} className='bg-[#2A6496] text-white' type="submit">
                                                Submit
                                            </Button>) : ('')}
                                        {/* <Modal title="Level of Student" open={modalVisible} onOk={() => setModalVisible(false)} onCancel={() => setModalVisible(false)}>
                                            <select name="level" width={100} className="ant-input css-dev-only-do-not-override-k83k30" placeholder='Select'>
                                                <option value="">Select</option>
                                                <option value="Foundation">Foundation</option>
                                                <option value="Advance">Advance</option>
                                            </select>
                                        </Modal> */}
                                        <Modal title="Level of Student"
                                            open={modalVisible}
                                            onOk={() => setModalVisible(false)}
                                            onCancel={() => setModalVisible(false)}
                                            footer={null}>
                                            <Form onFinish={onFinish}>
                                                <Form.Item label="Objective Score" name="objective_score">
                                                    <p className="text-[#F0AA0C] text-base">{correct}<span className="text-[#0F4275] pl-2.5">out of 40</span></p>
                                                </Form.Item>

                                                <Form.Item label="Subjective Score" name="subjective_score" rules={[
                                                    { required: true, message: "please enter score", },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (value <= 10) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(new Error('Score cannot be greater than 10'));
                                                        },
                                                    }),
                                                ]} >
                                                    <div className="flex">
                                                        <Input className="score-input" type="number" /><span className="text-[#0F4275] text-base pl-2.5">out of 10</span>
                                                    </div>
                                                </Form.Item>
                                                {class_Name === 9 || class_Name === "9" ? (<Form.Item label="Level of Student" name="level" rules={[{ message: "please enter level", required: true }]}>
                                                    <select
                                                        className="ant-input css-dev-only-do-not-override-k83k30 score-input">
                                                        <option value="">Select</option>
                                                        <option value="1">Foundation</option>
                                                        <option value="2">Advance</option>
                                                    </select>
                                                </Form.Item>) : ('')}


                                                <Form.Item wrapperCol={{ offset: 6 }} className="flex justify-center">
                                                    <Button type="primary" htmlType="submit" className='bg-[#F0AA0C] '>
                                                        Submit
                                                    </Button>
                                                </Form.Item>

                                            </Form>
                                        </Modal>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>

                </Content>
            </TeacherLayout >
        </>
    )
}

export default DiagnosticTeacherResult