import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Form, Input, Button, Collapse, Popconfirm, Layout } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from "react-router-dom";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from "react-datepicker";
import { api_url } from '../components/constants';
import "react-datepicker/dist/react-datepicker.css";
import { decrypt, encriptionKey } from '../components/utils';

const { TextArea } = Input;
const { Panel } = Collapse;
const { Content } = Layout;

const StudentTargets = () => {
    const cur_date = moment(new Date());
    const today_date = cur_date.format("YYYY-MM-DD");

    const { id } = useParams();
    const navigate = useNavigate();
    const [startInternDate, setStartInternDate] = useState(new Date());
    const [loading, setLoading] = useState(true)
    const [pbsData, setPBSData] = useState([{ student_name: "", id: "", date: "", time: "", status: "", mentor: "", link: "", test_records: "", academic_records: "", internship_records: "", suggestion_records: "" }]);
    const [testTargetData, setTestTargetData] = useState({ sat: "", act: "", toefl: "", ielts: "", sat_ap: "" });
    const [testAssignData, setTestAssignData] = useState({ sat: "", act: "", toefl: "", ielts: "", sat_ap: "" });
    const [academicTargetData, setAcademicAchiveData] = useState({ grade_9: "", grade_10: "", grade_11: "", grade_12: "" });
    const [academicAssignData, setAcademicAssignData] = useState({ grade_9: "", grade_10: "", grade_11: "", grade_12: "" });
    const [internshipData, setInternshipData] = useState([{ suggestion: "", subject: "", remark: "", deadline: today_date }])
    const [profileData, setProfileData] = useState([{ suggestion: '', deadline: null, output: '', type: "" }]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        const fetchPBSData = async () => {
            await axios.post(api_url + 'get_counsellor_pbs_session', {
                pbs_id: id,

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const pbsData = res.data.pbs_data;

                setPBSData(pbsData);
                const st_achive_test_data = res.data.st_achive_test_data;
                if (st_achive_test_data) {
                    setTestTargetData(JSON.parse(st_achive_test_data));
                }

                const st_assign_test_data = res.data.st_assign_test_data;

                if (st_assign_test_data) {
                    //console.log(st_assign_test_data);
                    setTestAssignData(JSON.parse(st_assign_test_data));
                }

                const st_achive_data = res.data.st_achive_data;
                if (st_achive_data) {
                    //console.log(st_achive_data);
                    setAcademicAchiveData(JSON.parse(st_achive_data));

                }
                const st_assign_data = res.data.st_assign_data;

                if (st_assign_data) {
                    setAcademicAssignData(JSON.parse(st_assign_data));
                }
                const st_intern_data = res.data.st_intern_data;

                if (st_intern_data) {
                    setInternshipData(JSON.parse(st_intern_data));
                }
                const st_suggestion_data = res.data.st_suggestion_data;

                if (st_suggestion_data) {
                    setProfileData(JSON.parse(st_suggestion_data));
                }
                setLoading(false)

            })
        }
        fetchPBSData()
    }, [id])

    const panelStyle = {
        marginBottom: 24,
        border: 'none',
        background: '#0F4275',
        boxShadow: '0px 3px 3px #00000029',
    };

    const handleChange = (name, value) => {
        setAcademicAssignData({ ...academicAssignData, [name]: value });

    };
    const handleTestChange = (name, value) => {
        setTestAssignData({ ...testAssignData, [name]: value });
    };

    const handleSubmit = () => {

        axios.post(api_url + 'update_counsellor_academic_feedback', {
            pbs_id: id,
            data: academicAssignData,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => { // eslint-disable-line
                        navigate("/counsellor-dashboard/targets/" + id)
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
    }

    const handleTestSubmit = () => {

        axios.post(api_url + 'update_counsellor_test_feedback', {
            pbs_id: id,
            data: testAssignData,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => { // eslint-disable-line
                        navigate("/counsellor-dashboard/targets/" + id)
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }

            })
    }

    const handleInternAddRow = () => {
        setInternshipData([...internshipData, { suggestion: "", subject: "", remark: "", deadline: today_date }]);
    };

    const handleInternChange = (e, index) => {
        const newData = [...internshipData];
        if (e.target.name === "deadline") {
            setStartInternDate(new Date(e.target.value));
            const momentObj = moment(e.target.value);
            newData[index][e.target.name] = momentObj.format("YYYY-MM-DD");

        } else {
            newData[index][e.target.name] = e.target.value;
        }
        setInternshipData(newData);

    };

    const handleInternSubmitChange = () => {
        axios.post(api_url + 'update_counsellor_intern_feedback', {
            pbs_id: id,
            data: internshipData,
        })
            .then(result => {

                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    const timer = setTimeout(() => { // eslint-disable-line
                        navigate("/counsellor-dashboard/targets/" + id)
                    }, 3000);

                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    };

    const handleInternRemove = (index) => {
        const newTable = [...internshipData]
        newTable.splice(index, 1)
        setInternshipData(newTable)
    }

    const handleProfileAddRow = () => {
        setProfileData([...profileData, { suggestion: "", deadline: null, output: "", type: "" }]);
    };

    const handleProfileChange = (e, index) => {
        const newData = [...profileData];
        const { name, value } = e.target;

        // Check if the input is a DatePicker (usually identified by its 'name' or 'type')
        if (name.startsWith('deadline')) {
            // Handle date input
            newData[index][name] = value ? value.toISOString() : null;
        } else {
            // Handle other types of input (text, textarea, etc.)
            newData[index][name] = value;
        }
        setProfileData(newData);
    };


    const handleProfileRemove = (index) => {
        const newTable = [...profileData];
        newTable.splice(index, 1);
        setProfileData(newTable);
    };

    const handleProfileSubmitChange = () => {
        // Check for missing or invalid fields
        const missingFields = profileData.some(row =>
            !row.type || row.type.trim() === '' ||  // Check if 'type' is empty or only whitespace
            !row.suggestion || row.suggestion.trim() === '' ||  // Check if 'suggestion' is empty or only whitespace
            !row.deadline || row.deadline === ''  // Check if 'deadline' is missing or empty
        );

        if (missingFields) {
            toast.error('All fields are required', {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }

        axios.post(api_url + 'update_counsellor_suggestion_feedback', {
            pbs_id: id,
            data: profileData,
        })
            .then(result => {
                if (result.data.status) {
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    setTimeout(() => {
                        navigate('/counsellor-dashboard/targets/' + id);
                    }, 3000);
                } else {
                    toast.error('Something went wrong', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                console.error('Error submitting form:', error);
                toast.error('An error occurred while submitting the form', {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    };


    return (
        <>
            <ToastContainer />

            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mx-16 mt-10">
                            <Row>
                                <Col md={24}>
                                    <h1 className="text-[#061522] text-xl mb-5">PBS Session Student Targets</h1>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={24}>
                                    <div className="mx-7 my-7 p-5 bg-[#FBFBFB]" style={{ borderRadius: 29 }}>
                                        <Row gutter={30}>
                                            <Col md={8}>
                                                <div className="flex pb-2.5">
                                                    <div className="bg-white p-3.5 rounded-full" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                        <img src="/images/calendar.png" alt='calendar-img' />
                                                    </div>
                                                    <span className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">{formatDate(pbsData.date)}</span>
                                                </div>
                                            </Col>
                                            <Col md={8}>
                                                <div className="flex pb-2.5">
                                                    <div className="bg-white p-3.5 rounded-full" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                                        <img src="/images/clock.png" alt='clock-img' />
                                                    </div>
                                                    <span className="text-xl text-[#0C3762] flex justify-center items-center pl-7 capitalize">{pbsData.time}</span>
                                                </div>
                                            </Col>
                                            <Col md={8}>

                                                <div className="flex pb-2.5 items-center">
                                                    <div className="bg-white p-3.5 rounded-full flex-shrink-0" style={{ boxShadow: '0px 3px 6px #00000029', height: '64px', width: '64px' }}>
                                                        <img src="/images/presentation.png" alt='presentation-img' className="h-full w-full object-contain" />
                                                    </div>
                                                    <p className="text-xl text-[#0C3762] pl-7 capitalize">{decrypt(pbsData.student_name, encriptionKey)}</p>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={24}>
                                    <Collapse defaultActiveKey={['1']}
                                        className='targets'
                                        expandIconPosition='end'
                                        bordered={false}
                                        style={{ marginTop: "-1vh", color: '#fff' }}
                                        expandIcon={({ isActive }) => (
                                            <DownOutlined
                                                style={{ marginTop: "-2vh", color: '#fff', paddingTop: 25 }}
                                                rotate={isActive ? 180 : 0}
                                            />
                                        )}
                                    >

                                        <Panel
                                            style={panelStyle}
                                            header="STUDENT TEST TARGETS"
                                            key="1">
                                            <Form
                                                name="normal_login"
                                                className="my-7 mx-5"
                                                onFinish={handleTestSubmit}
                                            >
                                                <div>
                                                    <h3 className="text-lg text-[#0C3762] my-5 font-medium">Your Achievement</h3>
                                                </div>

                                                <Row gutter={30}>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="st_sat"
                                                            label="SAT"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testTargetData.sat}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="st_toefl"
                                                            label="TOEFL"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testTargetData.toefl}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="st_ielts"
                                                            label="IELTS"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testTargetData.ielts}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="st_ap"
                                                            label="AP"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testTargetData.sat_ap}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="st_act"
                                                            label="ACT"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testTargetData.act}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>


                                                </Row>
                                                <div>
                                                    <h3 className="text-lg text-[#0C3762] my-5 font-medium">Your Test Targets</h3>
                                                </div>
                                                <Row gutter={30}>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="sat"
                                                            label="SAT"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testAssignData.sat}
                                                        >
                                                            <Input onChange={(e) => handleTestChange('sat', e.target.value)} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="toefl"
                                                            label="TOEFL"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testAssignData.toefl}
                                                        >
                                                            <Input onChange={(e) => handleTestChange('toefl', e.target.value)} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="ielts"
                                                            label="IELTS"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testAssignData.ielts}
                                                        >
                                                            <Input onChange={(e) => handleTestChange('ielts', e.target.value)} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="sat_ap"
                                                            label="AP"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testAssignData.sat_ap}
                                                        >
                                                            <Input onChange={(e) => handleTestChange('sat_ap', e.target.value)} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={4}>
                                                        <Form.Item
                                                            name="act"
                                                            label="ACT"
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={testAssignData.act}
                                                        >
                                                            <Input onChange={(e) => handleTestChange('act', e.target.value)} />
                                                        </Form.Item>
                                                    </Col>


                                                </Row>
                                                <Row>
                                                    <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button htmlType="submit" className="bg-[#205584] px-10 pb-2.5 text-white" style={{ borderRadius: 8 }}>Update</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Panel>
                                        <Panel
                                            style={panelStyle}
                                            header="STUDENT SCHOOL ACADEMICS TARGETS" key="2">
                                            <Form className="my-7 mx-5" onFinish={handleSubmit} >
                                                <div>
                                                    <h3 className="text-lg text-[#0C3762] my-5 font-medium">Your Achievement</h3>
                                                </div>
                                                <Row gutter={30}>


                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="st_grade_9"
                                                            label="9th Std."
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={academicTargetData.grade_9}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="st_rade_10"
                                                            label="10th Std."
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={academicTargetData.grade_10}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="st_grade_11"
                                                            label="11th Std."
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={academicTargetData.grade_11}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="st_grade_12"
                                                            label="12th Std."
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={academicTargetData.grade_12}
                                                        >
                                                            <Input disabled={true}
                                                            //onChange={(e) => handleChange('act', e.target.value)}
                                                            />
                                                        </Form.Item>
                                                    </Col>


                                                </Row>
                                                <div>
                                                    <h3 className="text-lg text-[#0C3762] my-5 font-medium">Your Targets</h3>
                                                </div>

                                                <Row gutter={30}>

                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="grade_9"
                                                            label="9th Std."
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={academicAssignData.grade_9}
                                                        >
                                                            {academicTargetData.grade_9 !== "" ? (<Input disabled={true} />) : (<Input onChange={(e) => handleChange('grade_9', e.target.value)}
                                                            />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="grade_10"
                                                            label="10th Std."
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={academicAssignData.grade_10}
                                                        >
                                                            {academicTargetData.grade_10 !== "" ? (<Input disabled={true} />) : (<Input onChange={(e) => handleChange('grade_10', e.target.value)}
                                                            />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="grade_11"
                                                            label="11th Std."
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={academicAssignData.grade_11}
                                                        >
                                                            {academicTargetData.grade_11 !== "" ? (<Input disabled={true} />) : (<Input onChange={(e) => handleChange('grade_11', e.target.value)}
                                                            />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Item
                                                            name="grade_12"
                                                            label="12th Std."
                                                            wrapperCol={{ span: 24 }}
                                                            labelCol={{ span: 24 }}
                                                            initialValue={academicAssignData.grade_12}
                                                        >
                                                            {academicTargetData.grade_12 !== "" ? (<Input disabled={true} />) : (<Input onChange={(e) => handleChange('grade_12', e.target.value)}
                                                            />)}
                                                        </Form.Item>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button htmlType="submit" className="bg-[#205584] px-10 pb-2.5 text-white" style={{ borderRadius: 8 }}>Update</Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Panel>
                                        <Panel
                                            style={panelStyle}
                                            header="INTERNSHIP ASSISTANCE" key="3">
                                            <Form
                                                name="normal_login"
                                                className="my-7 mx-5"
                                                onFinish={handleInternSubmitChange}
                                            >
                                                <table className='curricular-data'>
                                                    <thead>
                                                        <tr>
                                                            <th>Suggestions</th>
                                                            <th>Subject</th>
                                                            <th>Remarks</th>
                                                            <th>Deadline</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {internshipData.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <TextArea
                                                                        style={{ width: '95%' }}
                                                                        type="text"
                                                                        name="suggestion"
                                                                        value={row.suggestion}
                                                                        onChange={(e) => handleInternChange(e, index)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        style={{ width: '95%' }}
                                                                        type="text"
                                                                        name="subject"
                                                                        value={row.subject}
                                                                        onChange={(e) => handleInternChange(e, index)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <TextArea
                                                                        style={{ width: '95%' }}
                                                                        type="text"
                                                                        name="remark"
                                                                        value={row.remark}
                                                                        onChange={(e) => handleInternChange(e, index)}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    {row.deadline === null || row.deadline === "" ? (
                                                                        <DatePicker dateFormat="dd-MM-yyyy" selected={startInternDate} onChange={(date) => handleInternChange({ target: { name: 'deadline', value: date } }, index)} />
                                                                    ) : (
                                                                        <DatePicker dateFormat="dd-MM-yyyy" selected={new Date(row.deadline)} onChange={(date) => handleInternChange({ target: { name: 'deadline', value: date } }, index)} />
                                                                    )}
                                                                </td>

                                                                <td>
                                                                    <Popconfirm title="Sure to delete?" onConfirm={() => handleInternRemove(index)} okButtonProps={{ style: { background: '#2b6494' } }}>
                                                                        <button className="bg-transparent border-none p-0 m-0 cursor-pointer">
                                                                            <DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' />
                                                                        </button>
                                                                    </Popconfirm>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="flex justify-end">
                                                    <Button type="button" className="text-base text-white bg-[#FCC222] rounded-lg pb-2.5 px-5" onClick={handleInternAddRow}>
                                                        Add Row
                                                    </Button>
                                                </div>
                                                <div className="flex justify-center">
                                                    <Button htmlType="submit" className="text-base text-white bg-[#0F4275] rounded-lg pb-7 px-5">
                                                        Save
                                                    </Button>
                                                </div>

                                            </Form>
                                        </Panel>
                                        <Panel
                                            style={panelStyle}
                                            header="PROFILE BUILDING SUGGESTIONS(ACADEMIC & NON - ACADEMIC )" key="4">
                                            <Form
                                                name="normal_login"
                                                className="my-7 mx-5"
                                                onFinish={handleProfileSubmitChange}
                                            >
                                                <table className='curricular-data'>
                                                    <thead>
                                                        <tr>
                                                            <th>Suggestion Type</th>
                                                            <th>Suggestion</th>
                                                            <th>Student Output</th>
                                                            <th>Follow Up Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {profileData.map((row, index) => (
                                                            <tr key={index}>

                                                                <td style={{ width: '15%' }}>
                                                                    <Form.Item
                                                                        rules={[{ required: true }]}
                                                                    >
                                                                        <select style={{ width: '95%' }} value={row.type} name="type" width={100} className="ant-input css-dev-only-do-not-override-k83k30" onChange={(e) => handleProfileChange(e, index)}  >
                                                                            <option value="">Select</option>
                                                                            <option value="Academic">Academic</option>
                                                                            <option value="Non-Academic">Non-Academic</option>
                                                                        </select>
                                                                    </Form.Item>
                                                                </td>

                                                                <td style={{ width: '45%' }}>
                                                                    <Form.Item
                                                                        rules={[{ required: true }]}
                                                                    >
                                                                        <TextArea
                                                                            style={{ width: '95%' }}
                                                                            type="text"
                                                                            name="suggestion"
                                                                            value={row.suggestion}
                                                                            onChange={(e) => handleProfileChange(e, index)}
                                                                        />
                                                                    </Form.Item>
                                                                </td>
                                                                <td style={{ width: '20%' }}>
                                                                    <Form.Item
                                                                        rules={[{ required: true }]}
                                                                    >
                                                                        <TextArea disabled={true}
                                                                            style={{ width: '95%' }}
                                                                            type="text"
                                                                            name="output"
                                                                            value={row.output}
                                                                            onChange={(e) => handleProfileChange(e, index)}
                                                                        />
                                                                    </Form.Item>
                                                                </td>


                                                                <td>

                                                                    <Form.Item
                                                                        // name={`deadline_${index}`}
                                                                        rules={[{ required: true }]}
                                                                    >
                                                                        <DatePicker
                                                                            dateFormat="dd-MM-yyyy"
                                                                            selected={row.deadline ? new Date(row.deadline) : null}

                                                                            placeholderText="Select Date"
                                                                            onChange={(date) => handleProfileChange({ target: { name: `deadline`, value: date } }, index)}
                                                                        />
                                                                    </Form.Item>
                                                                </td>

                                                                <td>
                                                                    {row.output == null || row.output === '' ? (
                                                                        <Popconfirm
                                                                            title="Sure to delete?"
                                                                            onConfirm={() => handleProfileRemove(index)}
                                                                            okText="Yes"
                                                                            cancelText="No"
                                                                            okButtonProps={{ style: { background: '#2b6494' } }}
                                                                        >
                                                                            <button className="bg-transparent border-none p-0 m-0 cursor-pointer">
                                                                                <DeleteOutlined className='text-2xl flex justify-center text-center text-[#eb0123] font-extralight' />
                                                                            </button>
                                                                        </Popconfirm>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <div className="flex justify-end">
                                                    <Button type="button" className="text-base text-white bg-[#FCC222] rounded-lg pb-7 px-5" onClick={handleProfileAddRow}>
                                                        Add Row
                                                    </Button>
                                                </div>
                                                <div className="flex justify-center">
                                                    <Button htmlType="submit" className="text-base text-white bg-[#0F4275] rounded-lg pb-7 px-5">
                                                        Save
                                                    </Button>
                                                </div>
                                            </Form>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>

                        </div>
                    </Content>
                )}
            </CounsellorLayout>

        </>
    )
}


export default StudentTargets