import React, { useEffect, useState } from 'react';
import '../styles/faculty.css';
import axios from 'axios';
import { Layout, Form, Col, Row, } from 'antd';
import { BackwardOutlined } from '@ant-design/icons';
import DashboardLayout from '../components/dashboard-layout';
import { useParams, useNavigate } from "react-router-dom";
import { api_url, } from '../components/constants';
import 'react-toastify/dist/ReactToastify.css';
import { decrypt, encriptionKey } from '../components/utils';
const { Content } = Layout;

const ViewAssessmentReport = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }

    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({ username: "", centername: "", classname: "", coursename: "" });
    const [reportColumn, setReportColumn] = useState({ id: "", user_id: "", month: "", report_data: "", created_by: "" }); // eslint-disable-line
    const [reportData, setReportData] = useState({
        overall_feedback: '', performance: "", grammar_score: "", grammar_feedback: "", vocabulary_score: "",
        vocabulary_feedback: "", listening_score: '', listening_feedback: '', speaking_score: '', speaking_feedback: '', reading_score: '', reading_feedback: '', writing_score: '', writing_feedback: ''
    });


    useEffect(() => {
        const fetchAssessmentDetails = async () => {

            await axios.post(api_url + 'faculty/get_assessment_data', {
                id: id

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const report_list = res.data.report_data;
                setReportColumn(report_list);
                const obj = JSON.parse(report_list.report_data);
                const st_list = res.data.user_data;
                setReportData(obj);
                setUserData(st_list);
                setLoading(false)
            })
        }

        fetchAssessmentDetails()
    }, [id])


    const onBackRequest = () => {
        navigate('/student-dashboard/assessment-report')
    }

    return (
        <DashboardLayout>
            <Content
                className="site-layout-background"
                style={{
                    padding: 24,
                    background: '#fff',
                    width: '100%'
                }}
            >
                {loading ? (
                    <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
                ) : (
                    <div className="" style={{ border: '1px solid #E5E5E5' }}>
                        <Row>

                            <Col md={24} xs={24}>
                                <div className='flex bg-[#114D89] px-5 py-2.5 justify-between '>
                                    <div className="">
                                        <h1 className="text-white text-xl text-center">ASSESSMENT REPORT</h1>
                                        <span className='text-sm text-white text-center'>(2022-2023)</span>
                                    </div>
                                    <BackwardOutlined onClick={onBackRequest} className='text-white text-3xl flex items-center justify-center' />
                                </div>
                            </Col>
                        </Row>

                        <div className='bg-[#F1F3F6]' style={{ padding: '20px 30px' }}>
                            <Row gutter={30} >
                                <Col md={12}>
                                    <div className='flex py-1.5'>
                                        <h3 className='text-base text-[#0C3762] font-medium'>Name</h3>
                                        <span className='text-base text-[#787878] font-medium pl-16'>{decrypt(userData.username, encriptionKey)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className='flex py-1.5'>
                                        <h3 className='text-base text-[#0C3762] font-medium'>Class</h3>
                                        <span className='text-base text-[#787878] font-medium pl-20'>{decrypt(userData.classname, encriptionKey)}</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className='flex py-1.5'>
                                        <h3 className='text-base text-[#0C3762] font-medium'>Centre</h3>
                                        <span className='text-base text-[#787878] font-medium pl-16'>{userData.centername}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row style={{ marginTop: 10 }}>
                            <Col md={24}>
                                <div className='bg-[#F1F3F6] px-5'>
                                    <Form className='evaluation py-5'>
                                        <Row>
                                            <Col md={24}>
                                                <div className="test-result-outer">
                                                    <table className='test-result'>
                                                        <thead>
                                                            <tr>
                                                                <th>MODULE</th>
                                                                <th>SCORE</th>
                                                                <th>FEEDBACK</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td className='topic'>Grammar</td>
                                                                <td>{reportData.grammar_score}</td>
                                                                <td>{reportData.grammar_feedback}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='topic'>Vocabulary</td>
                                                                <td>{reportData.vocabulary_score}</td>
                                                                <td>{reportData.vocabulary_feedback}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='topic'>Listening</td>
                                                                <td>{reportData.listening_score}</td>
                                                                <td>{reportData.listening_feedback}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='topic'>Speaking</td>
                                                                <td>{reportData.speaking_score}</td>
                                                                <td>{reportData.speaking_speaking}</td>
                                                            </tr>

                                                            <tr>
                                                                <td className='topic'>Reading</td>
                                                                <td>{reportData.reading_score}</td>
                                                                <td>{reportData.reading_feedback}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='topic'>Writing</td>
                                                                <td>{reportData.writing_score}</td>
                                                                <td>{reportData.writing_feedback}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className='mt-2 bg-[#F1F3F6] pt-4'>
                                            <Row>
                                                <Col md={24}>
                                                    <h2 className='text-[#0C3762] text-lg font-bold mb-2'>Overall Feedback</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={24}>
                                                    <p>{reportData.overall_feedback}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='mt-2 mb-5 bg-[#F1F3F6] pt-4'>
                                            <Row>
                                                <Col md={24}>
                                                    <h2 className='text-[#0C3762] text-lg font-bold mb-2'>Overall Performence</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={24}>
                                                    <p>{reportData.performance}</p>
                                                </Col>
                                            </Row>
                                        </div>

                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Content>

        </DashboardLayout>
    )
}

export default ViewAssessmentReport