import React from "react";
import { Button, } from 'antd';
import Header from '.././components/header';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import { encriptionKey, decrypt } from "../components/utils";
import { Link } from 'react-router-dom';

const PyschometricReport = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const className = decrypt(user_data.class, encriptionKey);

    return (
        <>
            <Header />
            <div className="pyscho-report">
                <div className="page-wrapper">
                    <div className="page-corners">
                        <div className="report-frame">
                            <TopBanner />
                            <div>
                                <h1 className="text-[#0F4275] text-2xl flex justify-center text-center mb-16">Student Pyschometric Report</h1>
                            </div>
                            <div className="container" style={{ border: '2px dashed #F0AA0C' }}>
                                <div className="md:flex justify-between py-5 md:px-10 px-2.5">
                                    <div className="md:text-lg text-sm text-[#646464] leading-10">
                                        <p>Student Name : {decrypt(user_data.name, encriptionKey)}</p>
                                        <p>Form Number : {decrypt(user_data.form_number, encriptionKey)}</p>
                                        <p>Class : {decrypt(user_data.class, encriptionKey)}</p>
                                        <p>Report Name : Pyschometric Report</p>
                                    </div>
                                    <div>
                                        <div className="flex">
                                            <img src='/images/pdf-icon.png' alt="pdf" />
                                        </div>
                                        {user_data.psychrometric_report != null ? (
                                            <div className="flex justify-center mt-5">
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    className="text-lg text-[#fff] bg-[#0D3761] rounded-lg pb-1.5 px-5 flex justify-center mr-2.5"
                                                    style={{ height: 'auto' }}
                                                >
                                                    View
                                                </Button>
                                                <Button type="primary"
                                                    htmlType="submit"
                                                    className="text-lg text-[#0D3761] bg-[#FCC222] rounded-lg pb-1.5 px-5 flex justify-center"
                                                    style={{ height: 'auto' }}
                                                >
                                                    Download
                                                </Button>
                                            </div>
                                        ) : (
                                            <div className="flex justify-center mt-5">
                                                <a href='http://pftest.allen.ac.in/' rel="noreferrer" target="_blank" className="text-lg text-[#fff] bg-[#0D3761] rounded-lg pb-1.5 px-5 flex justify-center mr-2.5"
                                                    style={{ height: 'auto' }}
                                                >Start Test</a>
                                                {className === 9 ? (
                                                    <a className="text-sm pt-2.5 font-light text-[#FCC222]" href='/student-dashboard/dashboard'>Skip</a>
                                                ) : (
                                                    <a className="text-sm pt-2.5 font-light text-[#FCC222]" href='/student-dashboard/dashboard'>Skip</a>

                                                )}

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center mt-10 mb-24">
                                <Link type="primary"
                                    // onClick={popPage('student-profile-report')}
                                    to={'/student-portal/student-profile-report'}
                                    htmlType="submit"
                                    className="text-lg text-[#fff] bg-[#0D3761] rounded-lg py-1.5 px-5 flex justify-center mr-2.5"
                                    style={{ height: 'auto' }}
                                >
                                    Back
                                </Link>

                                <Link type="primary"
                                    to={'/student-dashboard/dashboard'}
                                    htmlType="submit"
                                    className="text-lg text-[#0D3761] bg-[#FCC222] rounded-lg py-1.5 px-5 flex justify-center"
                                    style={{ height: 'auto' }}
                                >
                                    Continue
                                </Link>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )

}

export default PyschometricReport