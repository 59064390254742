import React from 'react';




const ReadingAnalysis = () => {

    return (
        <>
            <h1>Palak</h1>
        </>
    )
}


export default ReadingAnalysis