import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/dashboard.css';
import '../styles/responsive.css';
import { Button, Col, Row, Collapse, Layout } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import SatRecords from '../modal/sat-records';
import ActRecords from '../modal/act-records';
import IELTSRecords from '../modal/IELTS-records';
import TOEFLRecords from '../modal/TOEFL-records';
import DulingoRecords from '../modal/Dulingo-records';
import PTERecords from '../modal/PTE-records';
import CambrigeRecord from "../modal/cambrige-records";
import { api_url } from '../components/constants';
import DashboardLayout from '../components/dashboard-layout';
const { Content } = Layout;
const { Panel } = Collapse

const TestRecord = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [satModalVisible, setSATModalVisible] = useState(false);
    const [satActModalVisible, setACTModalVisible] = useState(false);
    const [satIELTSModalVisible, setIELTSModalVisible] = useState(false);
    const [satTOEFLModalVisible, setTOEFLModalVisible] = useState(false);
    const [satDulingoModalVisible, setDulingoModalVisible] = useState(false);
    const [satPTEModalVisible, setPTEModalVisible] = useState(false);
    const [satCAMBRIGEModalVisible, setCAMBRIGEModalVisible] = useState(false);
    const [satData, setSatData] = useState([{ exam_date: null, attempt: "", score: "", maths: "", english: "" }]);
    const [actData, setActData] = useState([{ exam_date: null, attempt: "", score: "", maths: "", english: "", science: "", reading: "", writing: "" }]);
    const [IeltsData, setIeltsData] = useState([{ exam_date: null, over_all_score: "", speaking_score: "", listening_score: "", reading: "", writing: "" }]);
    const [ToeflData, setToeflData] = useState([{ exam_date: null, over_all_score: "", listening_score: "", reading: "", writing: "", speaking: "" }])
    const [DulingoData, setDulingoData] = useState([{ exam_date: null, over_all_score: "", conversation: "", comprehension: "", literacy: "", production: "" }])
    const [PteData, setPteData] = useState([{ exam_date: null, over_all_score: "", listening_score: "", reading: "", writing: "", speaking: "" }])
    const [CambrigeData, setCambrigeData] = useState([{ exam_date: null, over_all_score: "", use_english: "", reading: "", writing: "" }])


    useEffect(() => {
        const fetchTestRecordsData = async () => {
            await axios.post(api_url + 'user/get_test_data', {
                user_id: user_data.id,

            }).then((res) => {

                let sat_data = res.data.sat_data;
                let act_data = res.data.act_data;
                let ielts_data = res.data.ielts_data;
                let toefl_data = res.data.toefl_data;
                let dulingo_data = res.data.dulingo_data;
                let pte_data = res.data.pte_data;
                let cambrige_data = res.data.cambrige_data;
                if (sat_data) {
                    //console.log(sat_data);
                    setSatData(JSON.parse(sat_data));
                }
                if (act_data) {
                    setActData(JSON.parse(act_data));
                }
                if (ielts_data) {
                    setIeltsData(JSON.parse(ielts_data));
                }
                if (cambrige_data) {
                    setCambrigeData(JSON.parse(cambrige_data));
                }
                if (toefl_data) {
                    setToeflData(JSON.parse(toefl_data));
                }
                if (dulingo_data) {
                    setDulingoData(JSON.parse(dulingo_data));
                }

                if (pte_data) {
                    setPteData(JSON.parse(pte_data));
                }
                setLoading(false)

            })
        }


        fetchTestRecordsData()

    }, [user_data.id])

    const panelStyle = {
        marginBottom: 24,
        border: 'none',
        background: '#E3E3E3',
        boxShadow: '0px 3px 3px #00000029',
        borderRadius: 5,
        color: '#fff'
    };

    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background lg:p-5 md:p-5"
                        style={{
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <Row gutter={30}>

                            <Col md={24}>
                                <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>
                                    <div className="flex justify-between bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-2xl uppercase text-center">Test Score Records</h1>
                                    </div>
                                    <Row style={{ marginTop: 30 }}>
                                        <Col md={24} className='lg:px-5 px-5 profile'>
                                            <Collapse defaultActiveKey={['1']}
                                                bordered={false}
                                                expandIconPosition='end'
                                                style={{ marginTop: "-1vh", }}
                                                expandIcon={({ isActive }) => (
                                                    <DownOutlined
                                                        style={{ color: '#A2A1A1', paddingTop: 10, }}
                                                        rotate={isActive ? 180 : 0}
                                                    />
                                                )}
                                            >
                                                {/* sat score collapse */}

                                                <Panel header="SAT Score" key="1" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Exam Date</th>
                                                                    <th>Attempt</th>
                                                                    <th>English Score</th>
                                                                    <th>Math Score</th>
                                                                    <th>Total Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {satData.map((row, index) => (
                                                                    row.exam_date !== "" ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Exam Date -">{row.exam_date}</td>
                                                                            <td data-label="Attempt -">{row.attempt}</td>
                                                                            <td data-label="English Score -">{row.english}</td>
                                                                            <td data-label="Math Score -">{row.maths}</td>
                                                                            <td data-label="Total Score -">{row.score}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={6} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setSATModalVisible(true)}
                                                        >
                                                            Manage Records
                                                        </Button>
                                                        {satModalVisible && (
                                                            <SatRecords
                                                                show={satModalVisible}
                                                                hide={() => setSATModalVisible(false)} />
                                                        )}

                                                    </div>
                                                </Panel>

                                                {/* act score collapse */}
                                                <Panel header="ACT Score" key="2" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Exam Date</th>
                                                                    <th>Attempt</th>
                                                                    <th>Math Score</th>
                                                                    <th>Science Score</th>
                                                                    <th>English Score</th>
                                                                    <th>Reading Score</th>
                                                                    <th>Composite Score</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {actData.map((row, index) => (
                                                                    row.exam_date !== "" ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Exam Date -">{row.exam_date}</td>
                                                                            <td data-label="Attempt -">{row.attempt}</td>

                                                                            <td data-label="Math Score -">{row.maths}</td>
                                                                            <td data-label="Science Score -">{row.science}</td>
                                                                            <td data-label="English Score -">{row.english}</td>
                                                                            <td data-label="Reading Score -">{row.reading}</td>
                                                                            <td data-label="Composite Score -">{row.score}</td>

                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={8} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setACTModalVisible(true)}
                                                        >
                                                            Manage Records
                                                        </Button>
                                                        {satActModalVisible && (
                                                            <ActRecords
                                                                show={satActModalVisible}
                                                                hide={() => setACTModalVisible(false)} />
                                                        )}

                                                    </div>

                                                </Panel>

                                                {/* IELTS score collapse */}
                                                <Panel header="IELTS Score" key="3" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Exam Date</th>
                                                                    <th>Speaking Score</th>
                                                                    <th>Listening Score</th>
                                                                    <th>Reading Score</th>
                                                                    <th>Writing Score</th>
                                                                    <th>Overall Band Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {IeltsData.map((row, index) => (
                                                                    row.exam_date !== "" ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Exam Date -">{row.exam_date}</td>
                                                                            <td data-label="Speking Score -">{row.speaking_score}</td>
                                                                            <td data-label="Listening Score -">{row.listening_score}</td>
                                                                            <td data-label="Reading Score -">{row.reading}</td>
                                                                            <td data-label="Writing Score -">{row.writing}</td>
                                                                            <td data-label="Overall Band Score -">{row.over_all_score}</td>
                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={6} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setIELTSModalVisible(true)}
                                                        >
                                                            Manage Records
                                                        </Button>
                                                        {satIELTSModalVisible && (
                                                            <IELTSRecords
                                                                show={satIELTSModalVisible}
                                                                hide={() => setIELTSModalVisible(false)} />
                                                        )}

                                                    </div>
                                                </Panel>

                                                {/* TOEFL score collapse */}
                                                <Panel header="TOEFL Score" key="4" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Exam Date</th>

                                                                    <th>Listening Score</th>
                                                                    <th>Reading Score</th>
                                                                    <th>Speaking Score</th>
                                                                    <th>Writing Score</th>
                                                                    <th>Total Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ToeflData.map((row, index) => (
                                                                    row.exam_date !== "" ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Exam Date -">{row.exam_date}</td>

                                                                            <td data-label="Listening Score -">{row.listening_score}</td>
                                                                            <td data-label="Reading Score -">{row.reading}</td>
                                                                            <td data-label="Speaking Score -">{row.speaking}</td>
                                                                            <td data-label="Writing Score -">{row.writing}</td>
                                                                            <td data-label="Total Score -">{row.over_all_score}</td>

                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={6} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setTOEFLModalVisible(true)}
                                                        >
                                                            Manage Records
                                                        </Button>
                                                        {satTOEFLModalVisible && (
                                                            <TOEFLRecords
                                                                show={satTOEFLModalVisible}
                                                                hide={() => setTOEFLModalVisible(false)} />
                                                        )}

                                                    </div>

                                                </Panel>

                                                {/* Dulingo score collapse */}
                                                <Panel header="Dulingo Score" key="5" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Exam Date</th>
                                                                    <th>Conversation</th>
                                                                    <th>Comprehension</th>
                                                                    <th>Literacy</th>
                                                                    <th>Production</th>
                                                                    <th>Overall Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {DulingoData.map((row, index) => (
                                                                    row.exam_date !== "" ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Exam Date -">{row.exam_date}</td>

                                                                            <td data-label="Conversation -">{row.conversation}</td>
                                                                            <td data-label="Comprehension -">{row.comprehension}</td>
                                                                            <td data-label="Literacy -">{row.literacy}</td>
                                                                            <td data-label="Production -">{row.production}</td>
                                                                            <td data-label="Overall Score -">{row.over_all_score}</td>

                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={6} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setDulingoModalVisible(true)}
                                                        >
                                                            Manage Records
                                                        </Button>
                                                        {satDulingoModalVisible && (
                                                            <DulingoRecords
                                                                show={satDulingoModalVisible}
                                                                hide={() => setDulingoModalVisible(false)} />
                                                        )}

                                                    </div>

                                                </Panel>

                                                {/* PTE score collapse */}
                                                <Panel header="PTE Score" key="6" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Exam Date</th>

                                                                    <th>Listening Score</th>
                                                                    <th>Reading Score</th>
                                                                    <th>Speaking Score</th>
                                                                    <th>Writing Score</th>
                                                                    <th>Overall Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {PteData.map((row, index) => (
                                                                    row.exam_date !== "" ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Exam Date -">{row.exam_date}</td>

                                                                            <td data-label="Listening Score -">{row.listening_score}</td>
                                                                            <td data-label="Reading Score -">{row.reading}</td>
                                                                            <td data-label="speaking Score -">{row.speaking}</td>
                                                                            <td data-label="Writing Score -">{row.writing}</td>
                                                                            <td data-label="Total Score -">{row.over_all_score}</td>

                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={6} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setPTEModalVisible(true)}
                                                        >
                                                            Manage Records
                                                        </Button>
                                                        {satPTEModalVisible && (
                                                            <PTERecords
                                                                show={satPTEModalVisible}
                                                                hide={() => setPTEModalVisible(false)} />
                                                        )}

                                                    </div>

                                                </Panel>

                                                {/* Cambridge score collapse */}
                                                <Panel header="Cambridge Score" key="7" style={panelStyle}>
                                                    <div>
                                                        <table className='curricular'>
                                                            <thead>
                                                                <tr>
                                                                    <th>S.no</th>
                                                                    <th>Exam Date</th>

                                                                    <th>Reading Score</th>
                                                                    <th>Use of English</th>
                                                                    <th>Writing Score</th>
                                                                    <th>Overall Score</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {CambrigeData.map((row, index) => (
                                                                    row.exam_date !== "" ? (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td data-label="Exam Date -">{row.exam_date}</td>

                                                                            <td data-label="Reading Score -">{row.reading}</td>
                                                                            <td data-label="Use of English -">{row.use_english}</td>
                                                                            <td data-label="Writing Score -">{row.writing}</td>
                                                                            <td data-label="Overall Score -">{row.over_all_score}</td>

                                                                        </tr>)
                                                                        : (<tr>
                                                                            <td colSpan={6} className="text-xl text-[#F0AA0C] text-center font-semibold">No Records Found</td>
                                                                        </tr>)

                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <div className="flex justify-end">
                                                        <Button type="primary"
                                                            className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center mr-2.5"
                                                            style={{ height: 'auto', borderRadius: 20 }}
                                                            onClick={() => setCAMBRIGEModalVisible(true)}
                                                        >
                                                            Manage Records
                                                        </Button>
                                                        {satCAMBRIGEModalVisible && (
                                                            <CambrigeRecord
                                                                show={satCAMBRIGEModalVisible}
                                                                hide={() => setCAMBRIGEModalVisible(false)} />
                                                        )}

                                                    </div>

                                                </Panel>

                                            </Collapse>

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>

                    </Content>
                )
                }
            </DashboardLayout>
        </>
    )
}

export default TestRecord