import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { encrypt, decrypt, encriptionKey } from '../components/utils';
import { useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import DatePicker from "react-datepicker";
const { Search } = Input;
const { Content } = Layout;


const CompletedSession = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [sessionList, setSessionList] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filterDate, setFilterDate] = useState(null);

    useEffect(() => {
        const fetchPendingSession = async () => {

            await axios.post(api_url + 'counsellor-complete-session', {
                counsellor_id: user_data.id

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    }
                }

                const st_list = res.data.users;

                const k = st_list.map((item, index) =>
                ({
                    'sno': index + 1,
                    'name': decrypt(item.name, encriptionKey),
                    'class': decrypt(item.class, encriptionKey),
                    'course': decrypt(item.course, encriptionKey),
                    'center': item.center,
                    'date': item.date + ' ' + item.time,
                    'status': item.status,
                    'pbs_id': item.id,
                    'user_id': item.user_id,
                    'counsellor_id': item.counsellor_id,
                })
                );

                setSessionList(k);
                setFilteredResults(st_list);
                setLoading(false)

            })
        }
        fetchPendingSession()
    }, [user_data.id])

    useEffect(() => {
        const applyFilters = () => {
            let filteredData = sessionList;

            if (searchInput) {
                filteredData = filteredData.filter(item =>
                    Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
                );
            }

            if (filterDate) {
                filteredData = filteredData.filter(item =>
                    new Date(item.date).toDateString() === new Date(filterDate).toDateString()
                );
            }

            setFilteredResults(filteredData);
        };

        applyFilters();
    }, [searchInput, filterDate, sessionList]);

    const onPBSFeedback = (record) => {
        const pbs_id = encrypt(record.pbs_id.toString(), encriptionKey);
        navigate("/counsellor-dashboard/targets/" + pbs_id)
    };

    const columns = [
        {
            key: "1",
            title: "Sno",
            dataIndex: "sno",

        },

        {
            key: "2",
            title: "Name",
            dataIndex: "name",
            align: 'center'
        },
        {
            key: "3",
            title: "Class",
            dataIndex: "class",
            align: 'center'
        },
        {
            key: "4",
            title: "Course",
            dataIndex: "course",
            align: 'center'
        },
        {
            key: "5",
            title: "Center",
            dataIndex: "center",
            align: 'center'
        },
        {
            key: "6",
            title: "Date & Time",
            dataIndex: "date",
            align: 'center'
        },
        {
            key: "7",
            title: "Status",
            dataIndex: "status",
            align: 'center'
        },
        {
            key: "8",
            title: "Actions",
            align: 'center',
            render: (record) => {
                return (
                    <>
                        <Space>
                            <Button onClick={() => {
                                onPBSFeedback(record);
                            }} className="bg-[#205584] text-white" style={{ borderRadius: 11 }}>Enter Feedback</Button>

                        </Space>

                    </>
                );
            },
        },
    ];


    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
        let filteredData = sessionList;

        if (searchInput) {
            filteredData = filteredData.filter((item) =>
                Object.values(item).join('').toLowerCase().includes(searchValue.toLowerCase())
            );
        }

        if (filterDate) {
            filteredData = filteredData.filter(item => new Date(item.date).toDateString() === new Date(filterDate).toDateString());
        }

        setFilteredResults(filteredData);
    };

    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mx-0 mt-0">
                            <Row>
                                <Col md={24} xs={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5">
                                        <h1 className="text-white text-2xl ">Completed PBS Session</h1>
                                    </div>
                                </Col>
                            </Row>

                            <div className="py-5 px-7" style={{ boxShadow: '0px 3px 6px #00000029' }}>

                                <Row gutter={16} className="mt-7">
                                    <Col md={6} style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <Search onChange={(e) => searchItems(e.target.value)} placeholder="Search...." />
                                    </Col>

                                    <Col md={6}>
                                        <DatePicker
                                            selected={filterDate}
                                            onChange={(date) => setFilterDate(date)}
                                            placeholderText="Filter by Date"
                                            dateFormat="yyyy-MM-dd"
                                            style={{ width: '100%' }}
                                            className="filter-date"
                                        />
                                    </Col>

                                </Row>

                                <Row className="mt-5">
                                    <Col md={24} className="responsive-table">
                                        {searchInput || filterDate ? (
                                            <Table
                                                className="record"
                                                bordered
                                                columns={columns}
                                                dataSource={filteredResults}
                                            />
                                        ) : (
                                            <Table
                                                className="record"
                                                bordered
                                                columns={columns}
                                                dataSource={sessionList}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Content>
                )}
            </CounsellorLayout>
        </>
    )
}

export default CompletedSession