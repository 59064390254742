import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Layout } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { ClockCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import { api_url } from '../components/constants';
import { useNavigate } from "react-router-dom";
const { Content } = Layout;

const CounsellorDashboard = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [counsellorDashboard, setCounsellorDashboard] = useState({ pending_session: "0", complete_session: '0', upcoming_session: '0', total_session: '0', cancelled_session: '0' }) // eslint-disable-line
    const [events, setEvents] = useState([ // eslint-disable-line
        { id: "", date: "", time: "", status: "", event: "", user: "", user_id: "" },
    ]);
    const fetchCounsellorDashboard = async () => {

        await axios.post(api_url + 'mentor-dashboard', {
            counsellor_id: user_data.id
        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const dashboard_info = res.data.data;
            setCounsellorDashboard(dashboard_info);
            setLoading(false)

        })
    }
    const fetchPBSData = async () => {
        await axios.post(api_url + 'user/get_event_data', {
            user_id: user_data.id,
            type: 'Mentor'

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.data;

            setEvents(pbs_data);
            setLoading(false)

        })
    }

    useEffect(() => {
        fetchPBSData()
        fetchCounsellorDashboard()
    }, []) // eslint-disable-line


    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '20px 16px 24px',
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        {user_data.counsellor_type === 'Country Counsellor Head' ? (<CountryHeadDashboard />) : (<MentorDashboard />)}
                    </Content>
                )}
            </CounsellorLayout>

        </>
    )
}

const CountryHeadDashboard = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [counsellorDashboard, setCounsellorDashboard] = useState({ total_student: "0", application_process: '0', application_pending: '0' })

    const fetchCounsellorDashboard = async () => {

        await axios.post(api_url + 'counsellor/counsellor-dashboard', {
            counsellor_id: user_data.id,
            type: user_data.counsellor_type,
        }).then((res) => {
            //console.log(res);return false;
            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            const dashboard_info = res.data.data;
            setCounsellorDashboard(dashboard_info);
            setLoading(false)

        })
    }

    useEffect(() => {
        fetchCounsellorDashboard()
    }, []) // eslint-disable-line


    return (
        <div className="mx-0 mt-0">
            <Row>
                <Col md={24}>
                    <h1 className="text-[#061522] text-2xl mb-16">Welcome to Dashboard</h1>
                </Col>
            </Row>
            <Row gutter={30}>

                <Col md={6}>
                    <div className="bg-[#FDCEDB] px-7 py-5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 28 }}>
                        <h3 className="text-xl text-[#061522] mb-2.5 font-medium">Total Student</h3>
                        <span className="text-2xl text-[#B8657C]">{counsellorDashboard.total_student}</span>
                    </div>
                </Col>

                <Col md={6}>
                    <div className="bg-[#FCEABF] px-7 py-5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 28 }}>
                        <h3 className="text-xl text-[#061522] mb-2.5 font-medium">Document Pending</h3>
                        <span className="text-2xl text-[#B5964C]">{counsellorDashboard.application_pending}</span>
                    </div>
                </Col>

                <Col md={6}>
                    <div className="bg-[#B9FBEA] px-7 py-5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 28 }}>
                        <h3 className="text-xl text-[#061522] mb-2.5 font-medium">Document Process</h3>
                        <span className="text-2xl text-[#45B598]">{counsellorDashboard.application_process}</span>
                    </div>
                </Col>


            </Row>
        </div>
    )
}

const MentorDashboard = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true); // eslint-disable-line
    const [counsellorDashboard, setCounsellorDashboard] = useState({ pending_session: "0", complete_session: '0', upcoming_session: '0', total_session: '0', cancelled_session: '0' })
    const [events, setEvents] = useState([
        { id: "", date: "", time: "", status: "", event: "", user: "", user_id: "" },
    ]);
    const fetchCounsellorDashboard = async () => {

        await axios.post(api_url + 'mentor-dashboard', {
            counsellor_id: user_data.id
        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const dashboard_info = res.data.data;
            setCounsellorDashboard(dashboard_info);
            setLoading(false)

        })
    }
    const fetchPBSData = async () => {
        await axios.post(api_url + 'user/get_event_data', {
            user_id: user_data.id,
            type: 'Mentor'

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.data;

            setEvents(pbs_data);
            setLoading(false)

        })
    }

    useEffect(() => {
        fetchPBSData()
        fetchCounsellorDashboard()
    }, []) // eslint-disable-line

    const onViewStudent = (id) => {
        window.location.href = '/counsellor-dashboard/student-info/' + id;
        navigate("/counsellor-dashboard/targets/" + id)
    };



    return (
        <div className="mx-0 mt-0">
            <Row>
                <Col md={24}>
                    <h1 className="text-[#061522] text-2xl mb-16">Welcome to Dashboard</h1>
                </Col>
            </Row>
            <Row gutter={30}>

                <Col md={8} xs={24} lg={6}>
                    <div className="bg-[#FDCEDB] px-7 py-5 mb-2.5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 28 }}>
                        <h3 className="text-xl text-[#061522] mb-2.5 font-medium">Total Session</h3>
                        <span className="text-2xl text-[#B8657C]">{counsellorDashboard.total_session}</span>
                    </div>
                </Col>

                <Col md={8} xs={24} lg={6}>
                    <div className="bg-[#FCEABF] px-7 py-5 mb-2.5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 28 }}>
                        <h3 className="text-xl text-[#061522] mb-2.5 font-medium">Pending Session</h3>
                        <span className="text-2xl text-[#B5964C]">{counsellorDashboard.pending_session}</span>
                    </div>
                </Col>

                <Col md={8} xs={24} lg={6}>
                    <div className="bg-[#B9FBEA] px-7 py-5 mb-2.5" style={{ boxShadow: '0px 3px 6px #00000029', borderRadius: 28 }}>
                        <h3 className="text-xl text-[#061522] mb-2.5 font-medium">Complete Session</h3>
                        <span className="text-2xl text-[#45B598]">{counsellorDashboard.complete_session}</span>
                    </div>
                </Col>


            </Row>
            <Row>
                <Col md={24}>
                    <div className="bg-[#F6F6F6] mt-7 px-7 py-7">
                        {events.length === 0 ? (
                            <div className="flex justify-center items-center">
                                <div className="flex py-5 px-32 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                                    <span className="text-[#F8A30C] text-xl text-center">No notification found.</span>
                                </div>
                            </div>
                        ) : (
                            events.map((value, k) => (
                                <div className="flex justify-between p-5 my-2.5 bg-white" style={{ borderRadius: 25 }}>
                                    <div className="flex">
                                        <div className="bg-[#FFEFCC] p-2.5" style={{ borderRadius: 2 }}>
                                            <img alt="counselling" src='/images/counselling.png' />
                                        </div>
                                        <div className="pl-2.5">
                                            {value.event === "Live Class" ? (
                                                <h3 className="text-[#8A8A8A] text-base">Classes</h3>
                                            ) : (
                                                <h3 className="text-[#8A8A8A] text-base">Student Name : <Link onClick={() => { onViewStudent(value.user_id); }} >{value.user}</Link></h3>
                                            )}

                                            {value.event === "Live Class" ? (
                                                <span className="text-[#F8A30C] text-sm">Your Class Schedule on {value.date}</span>
                                            ) : (
                                                <span className="text-[#F8A30C] text-sm">Your PBS Session Schedule on {value.date}</span>
                                            )}
                                        </div>
                                    </div>
                                    <h4 className="text-[#0F4275] text-sm flex items-center"><ClockCircleOutlined className="pr-2.5" />Time : {value.time}</h4>
                                </div>
                            ))
                        )}
                    </div>

                </Col>
            </Row>

        </div>
    )
}

export default CounsellorDashboard