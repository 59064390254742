import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Row, Col, Tabs, Timeline, Layout } from 'antd';
import { encrypt, encriptionKey } from '../components/utils';
import { useNavigate } from "react-router-dom";
import { api_url } from '../components/constants';
import DashboardLayout from '../components/dashboard-layout';
const { Content } = Layout;

const RecordTimeline = () => {
    const navigate = useNavigate();
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(true);
    const [pbsData, setPBSData] = useState(
        [{ id: "", date: "", time: "", status: "", mentor: "", link: "", test_records: "", academic_records: "", internship_records: "", suggestion_records: "" }]);
    const [testData, setTestData] = useState(null);
    const [academicData, setAcademicData] = useState(null);
    const [internData, setInternData] = useState(null);
    const [suggestioData, setSuggestionData] = useState(null);


    useEffect(() => {
        const pbsTimeline = () => {
            axios.post(api_url + 'student-pbs-timeline', {
                user_id: encrypt(user_data.id.toString(), encriptionKey)

            }).then((res) => {

                if (!res.data.status) {
                    if (res.data.access_token == null) {
                        localStorage.removeItem('user')
                        window.location.href = '/';
                    } else {
                        navigate('/counsellor-dashboard/dashboard');
                    }
                }

                if (res.data.pbs_data.length > 0) {

                    const st_list = res.data.pbs_data;
                    const test_target = res.data.test_target_array;
                    const academic_target = res.data.academic_target_array;
                    const st_intern_data = res.data.st_intern_array;
                    const st_suggestion_array = res.data.st_suggestion_array;
                    if (academic_target.length > 0) {
                        setAcademicData(JSON.parse(academic_target))
                    }
                    if (test_target.length > 0) {
                        setTestData(JSON.parse(test_target));
                    }
                    if (st_intern_data.length > 0) {
                        setInternData(JSON.parse(st_intern_data))
                    }
                    if (st_suggestion_array.length > 0) {
                        setSuggestionData(JSON.parse(st_suggestion_array))
                    }

                    setPBSData(st_list);
                }
                setLoading(false)

            })
        }
        pbsTimeline()
    }, []) // eslint-disable-line


    const items = [
        {
            key: '1',
            label: `STUDENT TARGETS`,
            children: <StudentTarget data={testData} />,
        },
        {
            key: '2',
            label: `ACADEMICS TARGETS`,
            children: <AcademicTarget data={academicData} />,
        },
        {
            key: '3',
            label: `INTERNSHIP ASSISTANCE`,
            children: <InternshipAssistance data={internData} />,
        },
        {
            key: '4',
            label: `PROFILE BUILDING SUGGESTIONS`,
            children: <ProfileBuilding data={suggestioData} />,
        },
    ];
    return (
        <>
            <DashboardLayout>
                <Content
                    className="site-layout-background timeline-banner"
                    style={{
                        margin: '20px 0px 24px',
                        padding: 18,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <Row gutter={30}>

                        <Col md={24}>
                            {loading ? (
                                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                            ) : (
                                <div className="form-layout form-space" style={{ background: '#F5F5F5' }}>
                                    <div className="flex justify-between bg-[#0F4275] py-2.5 px-7">
                                        <h1 className="text-[#fff] text-2xl uppercase text-center">PBS Timeline</h1>
                                    </div>
                                    <Tabs defaultActiveKey="1" pbsData={pbsData} items={items} style={{ padding: '20px 15px' }} />
                                </div>


                            )}

                        </Col>

                    </Row>
                </Content>

            </DashboardLayout>
        </>
    )
}

const StudentTarget = (props) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ? (
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={formatDate(row.date)}>
                                    <div className="bg-white p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Exam</th>
                                                        <th>Your Achivement</th>
                                                        <th>Assign Target</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.test_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.test}</td>
                                                            <td>{value.achive}</td>
                                                            <td>{value.assign}</td>

                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </Timeline.Item>
                            ))
                        ) : (<div className="my-24">
                            <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                        </div>)}


                    </Timeline>

                </Col>
            </Row>
        </>

    )
}

const AcademicTarget = (props) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ? (
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={formatDate(row.date)}>
                                    <div className="bg-white p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Class</th>
                                                        <th>Your Achivement</th>
                                                        <th>Assign Target</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.academic_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.grade}</td>
                                                            <td>{value.achive}</td>
                                                            <td>{value.assign}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            ))
                        ) : (<div className="my-24">
                            <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                        </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>
    )
}

const InternshipAssistance = (props) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>

                    <Timeline mode="left" >
                        {props.data != null ? (
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={formatDate(row.date)}>
                                    <div className="bg-white p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Suggestion</th>
                                                        <th style={{ width: '15%' }}>Subject</th>
                                                        <th style={{ width: '15%' }}>Deadline</th>
                                                        <th style={{ width: '30%' }}>Remark</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {row.intern_data.map((value, k) => (
                                                        <tr key={k}>
                                                            <td>{value.suggestion}</td>
                                                            <td>{value.subject}</td>
                                                            <td>{formatDate(value.deadline)}</td>
                                                            <td>{value.remark}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            ))
                        ) : (<div className="my-24">
                            <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                        </div>)}

                    </Timeline>
                </Col>
            </Row>
        </>

    )
}

const ProfileBuilding = (props) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    return (
        <>
            <Row>
                <Col md={24} className='form-space'>
                    <Timeline mode="left" >
                        {props.data != null ? (
                            props.data.map((row, index) => (
                                <Timeline.Item key={index} label={formatDate(row.date)}>
                                    <div className="bg-white p-5" style={{ borderRadius: 10 }}>
                                        <div className="flex mb-5 pbs-heading">
                                            <h3 className="text-[#707070] lg:text-lg text-base pt-2.5">Suggested By</h3>
                                            <span>{row.counsellor}</span>
                                        </div>
                                        <div className="bg-[#fff] responsive-table">
                                            <table className="target">
                                                <thead>
                                                    <tr>
                                                        <th>Suggestion</th>
                                                        <th>Follow Up Date</th>
                                                        <th>Student Output</th>
                                                        {/* <th>Action</th> */}

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {row.suggestion_data.map((value, k) => (


                                                        <tr key={k}>
                                                            <td>{value.suggestion}</td>
                                                            <td>{value.date}</td>
                                                            <td>{value.output}</td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </Timeline.Item>
                            ))
                        ) : (<div className="my-24">
                            <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                        </div>)}
                    </Timeline>
                </Col>
            </Row>
        </>

    )
}


export default RecordTimeline