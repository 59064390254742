import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Input, Modal, Checkbox } from 'antd';
import Header from '.././components/header';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import '../styles/form.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { decrypt, encriptionKey } from '../components/utils';
import { api_url, } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/responsive.css';


const EnquiryForm = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [showSuccess, setShowSuccess] = useState(false);
    const [studentData, setStudentData] = useState(
        {
            name: '', email: '', parent_email: '', form_number: '', city: '', school: '', father_name: '', mother_name: '', parent_mobile: '', mother_occupation: '', father_occupation: '', class: '',
            grade_8: '', grade_9: '', grade_10: '', grade_11: '', grade_12: '', sat: '', act: '', toefl: '', ielts: '', sat_ap: '', scholarships: '', hearAbout: '', allen_number: '', mobile: '', address: '',
            university: '', intended: '',
        }
    )
    const [showSatInput, setShowSatInput] = useState(false);
    const [showActInput, setShowActInput] = useState(false);
    const [showToeflInput, setShowToeflInput] = useState(false);
    const [showIeltsInput, setShowIeltsInput] = useState(false);
    const [showSapInput, setShowSapInput] = useState(false);
    const [loading, setLoading] = useState(true)


    const fetchUserData = async () => {
        pushPage('general-enquiry');
        await axios.post(api_url + 'get_gef_form_data', {
            user_id: user_data.id,

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }

            const gef_data = [res.data.data];

            const gefArray = gef_data.map(item => ({
                'user_id': item.user_id,
                'name': decrypt(item.name, encriptionKey),
                'email': decrypt(item.email, encriptionKey),
                'parent_email': item.parent_email,
                'form_number': decrypt(item.form_number, encriptionKey),
                'mobile': decrypt(item.mobile, encriptionKey),
                'mother_name': decrypt(item.mother_name, encriptionKey),
                'father_name': decrypt(item.father_name, encriptionKey),
                'dob': decrypt(item.dob, encriptionKey),
                'city': decrypt(item.city, encriptionKey),
                'grade_8': item.grade_8,
                'grade_9': item.grade_9,
                'grade_10': item.grade_10,
                'grade_11': item.grade_11,
                'grade_12': item.grade_12,
                'university': item.university,
                'intended': item.intended,
                'sat': item.sat,
                'act': item.act,
                'sat_ap': item.sat_ap,
                'scholarships': item.scholarships,
                'toefl': item.toefl,
                'ielts': item.ielts,
                'psychrometric_report': item.psychrometric_report,
                'hearAbout': item.hearAbout,
                'father_occupation': item.father_occupation,
                'mother_occupation': item.mother_occupation,
                'parent_mobile': item.parent_mobile,
                'diagnostic_report': item.diagnostic_report,
                'allen_number': item.allen_number,
                'admission_date': item.admission_date,
                'address': item.address,
                'class': decrypt(item.class, encriptionKey),
                'course': decrypt(item.course, encriptionKey),
                'school': item.school,

            }));

            setStudentData(gefArray[0])
            setLoading(false)
        })
    }

    useEffect(() => {
        // const timer = setTimeout(() => {
        fetchUserData()
        // }, 1000);
    }, []) // eslint-disable-line

    const handleChange = (name, value) => {
        setStudentData({ ...studentData, [name]: value });

    };

    const handleSatCheckboxChange = (e) => {

        setShowSatInput(e.target.checked);
        if (!e.target.checked) {
            handleChange('sat', null);
        }
    };

    const handleActCheckboxChange = (e) => {

        setShowActInput(e.target.checked);
        if (!e.target.checked) {
            handleChange('act', null);
        }
    };

    const handleToeflCheckboxChange = (e) => {
        setShowToeflInput(e.target.checked);
        if (!e.target.checked) {
            handleChange('toefl', null);
        }
    };

    const handleIeltsCheckboxChange = (e) => {
        setShowIeltsInput(e.target.checked);
        if (!e.target.checked) {
            handleChange('ielts', null);
        }
    };

    const handleSapCheckboxChange = (e) => {
        setShowSapInput(e.target.checked);
        if (!e.target.checked) {
            handleChange('sat_ap', null);
        }
    };

    const handleSubmit = () => {

        axios.post(api_url + 'user/update_gef_records', {
            user_id: user_data.id,
            type: 'GEF',
            records: studentData,
        })
            .then(result => {
                if (result.data.status) {

                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    // const timer = setTimeout(() => {
                    navigate("/student-portal/general-intelligence-quotient")
                    // }, 3000);


                } else {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    }

    const pushPage = (pgKey) => {
        //console.log('page', pgKey);
        let visitedPages = JSON.parse(sessionStorage.getItem('visitedPages'));
        if (!visitedPages.includes(pgKey)) {
            visitedPages.push(pgKey);
            sessionStorage.setItem('visitedPages', JSON.stringify(visitedPages));
        }
    }

    return (
        <>
            <ToastContainer />
            <Header />
            {loading ? (
                <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
            ) : (

                <div className="page-wrapper">
                    <div className="page-corners">
                        <TopBanner />

                        <div className="container flex justify-center items-center mb-10">

                            <div className="form-layout form-space">
                                <div className="p-5 mb-2.5">
                                    <h1 className="text-[#0F4275] text-2xl flex justify-center text-center">GENERAL ENQUIRY FORM (GEF)</h1>
                                </div>
                                <div className="flex justify-center items-center mx-10">
                                    <Form
                                        name="normal_login"
                                        className="my-7"
                                        form={form}
                                        onFinish={handleSubmit}

                                    >
                                        <Row gutter={30}>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="form_number"
                                                    label="Enquiry Form Number"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.form_number}

                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Please input your Enquiry Form Number!',
                                                //     },
                                                // ]}
                                                >
                                                    <Input disabled={true}
                                                        onChange={(e) => handleChange('form_number', e.target.value)}

                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="name"
                                                    label="Name of Student"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.name}
                                                // rules={[
                                                //     {
                                                //         required: true,
                                                //         message: 'Please input your Name!',
                                                //     },
                                                // ]}
                                                >
                                                    <Input
                                                        disabled={true}
                                                        //value={studentData.name}
                                                        type="name"
                                                        onChange={(e) => handleChange('name', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={24} xs={24}>
                                                <Form.Item
                                                    name="allen_number"
                                                    label="Allen Form Number Of Name Of Coaching Otherwise (If Availlable)"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.allen_number}
                                                >
                                                    <Input
                                                        onChange={(e) => handleChange('allen_number', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="class"
                                                    label="Current Class"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.class}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Current Class!',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        disabled={true}
                                                        onChange={(e) => handleChange('class', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="school"
                                                    label="Current School"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.school}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Current School!',
                                                        },
                                                    ]}
                                                >
                                                    <Input

                                                        type="name"
                                                        onChange={(e) => handleChange('school', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="city"
                                                    label="City of Residence"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.city}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your city!',
                                                        },
                                                    ]}
                                                >
                                                    <Input

                                                        //value={updatedData.city}
                                                        onChange={(e) => handleChange('city', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="mobile"
                                                    label="Student's Mobile Number"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.mobile}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Mobile Number!',
                                                        },
                                                        {
                                                            pattern: /^\d{10}$/,
                                                            message: 'Please enter a valid 10-digit phone number',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="name" disabled={true}
                                                        onChange={(e) => handleChange('mobile', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="father_name"
                                                    label="Father's Name"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.father_name}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Father Name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        onChange={(e) => handleChange('father_name', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="mother_name"
                                                    label="Mother's Name"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.mother_name}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Mother Name!',
                                                        },
                                                    ]}
                                                >
                                                    <Input type="name"
                                                        onChange={(e) => handleChange('mother_name', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="email"
                                                    label="Student's Email Address"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.email}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Email Address!',
                                                        },
                                                    ]}
                                                >
                                                    <Input type='email'
                                                        onChange={(e) => handleChange('email', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="parent_mobile"
                                                    label="Parent's Mobile Number"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.parent_mobile}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Parent Mobile Number!',
                                                        },
                                                        {
                                                            pattern: /^\d{10}$/,
                                                            message: 'Please enter a valid 10-digit phone number',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="name"
                                                        onChange={(e) => handleChange('parent_mobile', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="father_occupation"
                                                    label="Father's Occupation"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.father_occupation}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your Father's Occupation!",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        onChange={(e) => handleChange('father_occupation', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="mother_occupation"
                                                    label="Mother's Occupation"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.mother_occupation}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your Mother's Occupation!",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="name"
                                                        onChange={(e) => handleChange('mother_occupation', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="parent_email"
                                                    label="Parent's Email Address"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.parent_email}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Parent Email Address!',
                                                        },
                                                    ]}
                                                >
                                                    <Input type='parent_email'
                                                        onChange={(e) => handleChange('parent_email', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="address"
                                                    label="Complete Address"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.address}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your Completed Address!",
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea type='address'
                                                        onChange={(e) => handleChange('address', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24}>
                                                <Form.Item
                                                    name="hearAbout"
                                                    label="Where/How/When did you hear about AGSD ?"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.hearAbout}

                                                >
                                                    <Input.TextArea
                                                        //value={hear}
                                                        type="name"
                                                        onChange={(e) => handleChange('hearAbout', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={24} xs={24}>
                                                <Form.Item
                                                    name="university"
                                                    label="Target Universities"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.university}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your target universities",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        onChange={(e) => handleChange('university', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={24} xs={24}>
                                                <Form.Item
                                                    name="intended"
                                                    label="Intended Major (s)"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.intended}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input your Intended Major (s)",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        onChange={(e) => handleChange('intended', e.target.value)}
                                                    />
                                                </Form.Item>

                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            {studentData.class === "13" || studentData.class === "12" || studentData.class === "10" || studentData.class === "11" ? (
                                                <Col md={24}>
                                                    <div>
                                                        <h2 className="text-lg text-[#0D0C0C]">Marks Secured (% / GPA)</h2>
                                                    </div>
                                                </Col>
                                            ) : ('')}

                                            {studentData.class === "9" ? (
                                                ''
                                            ) : (
                                                studentData.class === "10" ? (
                                                    <>

                                                        <Col md={6} xs={12}>
                                                            <Form.Item
                                                                name="grade_9"
                                                                label="Grade 9"
                                                                wrapperCol={{ span: 24 }}
                                                                labelCol={{ span: 24 }}
                                                                initialValue={studentData.grade_9}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: "Please input your marks",
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    onChange={(e) => handleChange('grade_9', e.target.value)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </>

                                                ) :
                                                    (studentData.class === "11" ? (
                                                        <>

                                                            <Col md={6} xs={12}>
                                                                <Form.Item
                                                                    name="grade_9"
                                                                    label="Grade 9"
                                                                    wrapperCol={{ span: 24 }}
                                                                    labelCol={{ span: 24 }}
                                                                    initialValue={studentData.grade_9}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please input your marks",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        onChange={(e) => handleChange('grade_9', e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col md={6} xs={12}>
                                                                <Form.Item
                                                                    name="grade_10"
                                                                    label="Grade 10"
                                                                    wrapperCol={{ span: 24 }}
                                                                    labelCol={{ span: 24 }}
                                                                    initialValue={studentData.grade_10}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: "Please input your marks",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        onChange={(e) => handleChange('grade_10', e.target.value)}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </>

                                                    ) :
                                                        (studentData.class === "12" ? (
                                                            <>

                                                                <Col md={6} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_9"
                                                                        label="Grade 9"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_9}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your marks",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_9', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={6} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_10"
                                                                        label="Grade 10"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_10}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your marks",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_10', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={6} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_11"
                                                                        label="Grade 11"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_11}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your marks",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_11', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </>

                                                        ) : (
                                                            <>
                                                                <Col md={6} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_9"
                                                                        label="Grade 9"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_9}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your marks",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_9', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={6} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_10"
                                                                        label="Grade 10"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_10}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your marks",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_10', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={6} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_11"
                                                                        label="Grade 11"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_11}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your marks",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_11', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col md={6} xs={12}>
                                                                    <Form.Item
                                                                        name="grade_12"
                                                                        label="Grade 12"
                                                                        wrapperCol={{ span: 24 }}
                                                                        labelCol={{ span: 24 }}
                                                                        initialValue={studentData.grade_12}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "Please input your marks",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            onChange={(e) => handleChange('grade_12', e.target.value)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </>
                                                        )
                                                        )
                                                    )
                                            )
                                            }

                                        </Row>
                                        <Row gutter={30}>
                                            <Col md={24}>
                                                <div>
                                                    <h2 className="text-lg text-[#0D0C0C]">Have you taken any of these tests, if yes, mention your scores below :</h2>
                                                </div>
                                            </Col>

                                            <Col md={4} xs={12}>
                                                {/* <Form.Item
                                                    name="sat"
                                                    label="SAT"
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.sat}
                                                >
                                                    <Input
                                                        onChange={(e) => handleChange('sat', e.target.value)}
                                                    />
                                                </Form.Item> */}
                                                <Form.Item
                                                    name="sat"
                                                    label={
                                                        (studentData.sat > 0 ? (
                                                            <Checkbox disabled checked onChange={handleSatCheckboxChange}>SAT</Checkbox>
                                                        ) : (
                                                            <Checkbox onChange={handleSatCheckboxChange}>SAT</Checkbox>
                                                        )
                                                        )

                                                    }
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.sat}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && parseInt(value) > 1500) {
                                                                    return Promise.reject('Value must be less than or equal to 1500');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {showSatInput || studentData.sat ? (
                                                        <Input
                                                            value={studentData.sat}
                                                            onChange={(e) => handleChange('sat', e.target.value)}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            </Col>
                                            <Col md={4} xs={12}>
                                                <Form.Item
                                                    name="act"
                                                    label={
                                                        (studentData.act > 0 ? (
                                                            <Checkbox disabled checked onChange={handleActCheckboxChange}>ACT</Checkbox>
                                                        ) : (
                                                            <Checkbox onChange={handleActCheckboxChange}>ACT</Checkbox>
                                                        )
                                                        )

                                                    }
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.act}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && parseInt(value) > 35) {
                                                                    return Promise.reject('Value must be less than or equal to 35');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {showActInput || studentData.act ? (
                                                        <Input
                                                            onChange={(e) => handleChange('act', e.target.value)}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            </Col>
                                            <Col md={4} xs={12}>
                                                <Form.Item
                                                    name="toefl"
                                                    label={
                                                        (studentData.toefl > 0 ? (
                                                            <Checkbox disabled checked onChange={handleToeflCheckboxChange}>TOEFL</Checkbox>
                                                        ) : (
                                                            <Checkbox onChange={handleToeflCheckboxChange}>TOEFL</Checkbox>
                                                        )
                                                        )
                                                    }

                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.toefl}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && parseInt(value) > 110) {
                                                                    return Promise.reject('Value must be less than or equal to 110');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {showToeflInput || studentData.toefl ? (
                                                        <Input
                                                            onChange={(e) => handleChange('toefl', e.target.value)}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            </Col>
                                            <Col md={4} xs={12}>
                                                <Form.Item
                                                    name="ielts"
                                                    label={
                                                        (studentData.ielts > 0 ? (
                                                            <Checkbox disabled checked onChange={handleIeltsCheckboxChange}>IELTS</Checkbox>
                                                        ) : (
                                                            <Checkbox onChange={handleIeltsCheckboxChange}>IELTS</Checkbox>
                                                        )
                                                        )

                                                    }

                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.ielts}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && parseInt(value) > 8) {
                                                                    return Promise.reject('Value must be less than or equal to 8');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {showIeltsInput || studentData.ielts ? (
                                                        <Input
                                                            onChange={(e) => handleChange('ielts', e.target.value)}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            </Col>
                                            <Col md={4} xs={12}>
                                                <Form.Item
                                                    name="sat_ap"
                                                    label={
                                                        (studentData.sat_ap > 0 ? (
                                                            <Checkbox disabled checked onChange={handleSapCheckboxChange}>Subject SAT/AP</Checkbox>
                                                        ) : (
                                                            <Checkbox onChange={handleSapCheckboxChange}>Subject SAT/AP</Checkbox>
                                                        )
                                                        )

                                                    }

                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.sat_ap}
                                                    rules={[
                                                        {
                                                            validator: (_, value) => {
                                                                if (value && parseInt(value) > 5) {
                                                                    return Promise.reject('Value must be less than or equal to 5');
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    {showSapInput || studentData.sat_ap ? (
                                                        <Input
                                                            onChange={(e) => handleChange('sat_ap', e.target.value)}
                                                        />
                                                    ) : null}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md={24} xs={24}>
                                                <Form.Item
                                                    name="scholarships"
                                                    label='Will you be able to afford your studies abroad if scholarships are not provided ?'
                                                    wrapperCol={{ span: 24 }}
                                                    labelCol={{ span: 24 }}
                                                    initialValue={studentData.scholarships}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Please input you will be able to afford your studies",
                                                        },
                                                    ]}
                                                >
                                                    <Input.TextArea
                                                        onChange={(e) => handleChange('scholarships', e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Col md={24} className='flex justify-end'>
                                            <Form.Item>

                                                <Button type="primary"

                                                    htmlType="submit"
                                                    className="text-lg text-[#0D3761] bg-[#FCC222] rounded-lg py-1.5 px-5 flex justify-center"
                                                    style={{ height: 'auto' }}
                                                >
                                                    Next
                                                </Button>
                                                <Modal
                                                    open={showSuccess}

                                                    onCancel={() => setShowSuccess(false)}
                                                >
                                                    <p>Form Submitted Successfully!</p>
                                                </Modal>
                                            </Form.Item>
                                        </Col>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }

            <Footer />
        </>
    )

}

export default EnquiryForm